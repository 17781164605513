/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const CATEGORY_TABS_CONTENT_TYPE = 'scandiweb_categorytabs';

export const CATEGORY_TABS_SKELETON = [{
    name: 'CategoryTabs',
    type: 'div'
}];
