/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { CATEGORY_TILE_CONTENT_TYPE, CATEGORY_TILE_SKELETON } from '../../component/CategoryTile/CategoryTile.config';

export const CategoryTile = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "cms-misc" */
        '../../component/CategoryTile'
    ),
);

const addCategoryTileReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: CATEGORY_TILE_CONTENT_TYPE },
        replace: (domNode) => (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                <Suspense fallback={ <div /> }>
                    { createElement(CategoryTile, {
                        elements: instance.toReactElements(
                            [domNode],
                            CATEGORY_TILE_SKELETON
                        )
                    }) }
                </Suspense>
            </RenderWhenVisible>
        )
    }
]);

export default addCategoryTileReplacementRule;
