Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/amasty-sorting-improved/src/plugin/RouterContainerMDTP.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-gift-cards/src/plugin/Router/AddGiftCardsTabRoute.plugin.js'),require('/drone/src/build/code/scandipwa/packages/seo-suite/src/plugin/RouterContainer.plugin.js'),require('/drone/src/build/code/scandipwa/packages/ga4-measurement-protocol/src/plugin/Router.container.plugin.js'),require('/drone/src/build/code/scandipwa/packages/seo-suite/src/plugin/RouterContainer.plugin.js'),require('/drone/src/build/code/scandipwa/packages/google-tag-manager/src/plugin/events/general.plugin.js')]);
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AR_STORE_CODES } from 'Component/StoreSwitcher/StoreSwitcher.config';
import {
    DESIGN_ROOM_SCRIPT_ID,
    DESIGN_ROOM_SCRIPT_URL
} from 'Route/DesignRoomPage/DesignRoomPage.config';
import { updateIsRTL } from 'Store/Config/Config.action';
import { trimRecentlyViewedProducts } from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.action';
import { isRtl } from 'Util/CSS/CSS';
import history from 'Util/History';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import {
    CartDispatcher,
    ConfigDispatcher,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountDispatcher,
    ProductCompareDispatcher,
    RouterContainer as SourceRouterContainer,
    WishlistDispatcher
} from './Router.source.container';

export {
    CartDispatcher,
    ConfigDispatcher,
    WishlistDispatcher,
    ProductCompareDispatcher,
    MyAccountDispatcher
};

/** @namespace Scandipwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateIsRTL: (meta) => dispatch(updateIsRTL(meta)),
    init: async () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );

        const { default: dispatcher } = await MyAccountDispatcher;

        await dispatcher.handleCustomerDataOnInit(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
        );
    },
    trimRecentlyViewedProducts: () => dispatch(
        trimRecentlyViewedProducts()
    )
});

/** @namespace Scandipwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentStoreCode: state.ConfigReducer.code,
    isRTL: state.ConfigReducer.isRTL
});

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...this.propTypes,
        currentStoreCode: PropTypes.string,
        isRTL: PropTypes.bool.isRequired,
        updateIsRTL: PropTypes.func.isRequired,
        trimRecentlyViewedProducts: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        currentStoreCode: 'default'
    };

    handleHistoryListen = this.handleHistoryListen.bind(this);

    containerProps() {
        const { currentStoreCode } = this.props;

        return {
            ...super.containerProps(),
            currentStoreCode
        };
    }

    setupDesignRoom() {
        if (document.getElementById(DESIGN_ROOM_SCRIPT_ID) === null
        && !window.location.pathname.includes('design_room')) {
            const script = document.createElement('script');
            script.id = DESIGN_ROOM_SCRIPT_ID;
            script.src = DESIGN_ROOM_SCRIPT_URL;
            script.defer = true;

            document.body.appendChild(script);
        }
    }

    setupClarity() {
        // eslint-disable-next-line func-names
        window.clarity = window.clarity || function () {
            // eslint-disable-next-line fp/no-arguments,prefer-rest-params
            (window.clarity.q = window.clarity.q || []).push(arguments);
        };

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://www.clarity.ms/tag/juzg9y0qt3';

        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s);
    }

    /**
     * Overridden to change direction for Arabic website
     */
    componentDidMount() {
        const {
            currentStoreCode,
            trimRecentlyViewedProducts
        } = this.props;

        waitForPriorityLoad().then(() => this.setupDesignRoom());
        waitForPriorityLoad().then(() => this.setupClarity());
        document.querySelector('html').dir = AR_STORE_CODES.includes(currentStoreCode) ? 'rtl' : 'ltr';
        window.addEventListener('resize', this.handleResize, { passive: true });

        this.unlistenHistory = history.listen(this.handleHistoryListen);
        trimRecentlyViewedProducts();
    }

    /**
     * Overridden to change direction for Arabic website
     */
    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta, currentStoreCode } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        document.querySelector('html').dir = AR_STORE_CODES.includes(currentStoreCode) ? 'rtl' : 'ltr';

        if (!this.handleCheckIfOnlyMainItemsRender()) {
            this.setRenderAllItems();
        } else {
            this.setRenderOnlyMainItems();
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code
            } = this.props;

            const { value: metaTitle = '' } = meta_title || {};

            updateMeta({
                default_title,
                title: metaTitle || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code
            });
        }

        this.setupDesignRoom();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.unlistenHistory) {
            this.unlistenHistory();
        }
    }

    handleHistoryListen() {
        const {
            isRTL: isRTLProp,
            updateIsRTL
        } = this.props;
        const isRTL = isRtl();

        if (isRTL && !isRTLProp) {
            updateIsRTL(true);
        } else if (!isRTL && isRTLProp) {
            updateIsRTL(false);
        }
    }

    initializeApplication() {
        const { init } = this.props;

        waitForPriorityLoad().then(
            () => init()
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
