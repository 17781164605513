import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LoaderPopupReducer from 'Store/LoaderPopup/LoaderPopup.reducer';
import { withReducers } from 'Util/DynamicReducer';

import LoaderPopup from './LoaderPopup.component';

/** @namespace Scandipwa/Component/LoaderPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.LoaderPopupReducer.isLoading
});

/** @namespace Scandipwa/Component/LoaderPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandipwa/Component/LoaderPopup/Container */
export class LoaderPopupContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    containerProps() {
        const {
            isLoading
        } = this.props;

        return {
            isLoading
        };
    }

    render() {
        return (
            <LoaderPopup
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    LoaderPopupReducer
})(connect(mapStateToProps, mapDispatchToProps)(LoaderPopupContainer));
