/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { TYPE_CATEGORY, TYPE_CMS_PAGE, TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import getStore from 'Util/Store';

export const DL_VAL_PAGE_HOME = 'homepage';
export const DL_VAL_PAGE_CART = 'cart';
export const DL_VAL_PAGE_CHECKOUT = 'checkout';
export const DL_VAL_PAGE_THANKYOU = 'success';
export const DL_VAL_PAGE_SUCCESS = 'success';
export const DL_VAL_PAGE_ACCOUNT = 'account';
export const DL_VAL_PAGE_WISHLIST = 'wishlist';
export const DL_VAL_PAGE_FORGOT = 'forgot';
export const DL_VAL_PAGE_CONTACT = 'contact';
export const DL_VAL_PAGE_COMPARE = 'compare';
export const DL_VAL_PAGE_BLOG = 'blog';
export const DL_VAL_PAGE_STORES = 'stores';
export const DL_VAL_PAGE_SEARCH = 'search';
export const DL_VAL_PAGE_CATEGORY = 'category';
export const DL_VAL_PAGE_PRODUCT = 'product';
export const DL_VAL_PAGE_404 = 'not_found';
export const DL_VAL_PAGE_CMS = 'cmspage';
export const DL_VAL_PAGE_LOGIN_CHECKOUT = 'checkout-login';
export const DL_VAL_PAGE_DESIGN_ROOM = 'design-room';
export const DL_VAL_PAGE_THANK_YOU = 'thank-you';
export const DL_VAL_PAGE_FAQ = 'faq';
export const PATH_NAME_MAP = {
    [DL_VAL_PAGE_CART]: '/cart',
    [DL_VAL_PAGE_LOGIN_CHECKOUT]: '/login/checkout',
    [DL_VAL_PAGE_THANKYOU]: '/thankyou',
    [DL_VAL_PAGE_SUCCESS]: '/success',
    [DL_VAL_PAGE_CHECKOUT]: '/checkout',
    [DL_VAL_PAGE_ACCOUNT]: '/account',
    [DL_VAL_PAGE_WISHLIST]: '/wishlist',
    [DL_VAL_PAGE_FORGOT]: '/forgot-password',
    [DL_VAL_PAGE_CONTACT]: '/contact',
    [DL_VAL_PAGE_COMPARE]: '/compare',
    [DL_VAL_PAGE_BLOG]: '/blog',
    [DL_VAL_PAGE_STORES]: '/stores',
    [DL_VAL_PAGE_SEARCH]: '/search',
    [DL_VAL_PAGE_DESIGN_ROOM]: '/design_room',
    [DL_VAL_PAGE_THANK_YOU]: '/thankyou',
    [DL_VAL_PAGE_FAQ]: '/faqs'
};

/** @namespace Scandiweb/Gtm/Data/Page/getPageData */
export const getPageData = async (urlRewriteData) => {
    const { waitForCallback, waitForEndOfLoading } = await import('../util/wait');

    // vvv Wait for store code
    await waitForEndOfLoading(() => getStore().getState().ConfigReducer?.isLoading);
    await waitForCallback(() => getStore().getState().ConfigReducer?.store_code);

    const {
        ConfigReducer: {
            store_code
        } = {}
    } = getStore().getState();

    const realPath = window.location.pathname.replace(
        `/${store_code}`,
        ''
    );

    // vvv Try looking up the page from an array of pre-defined ones
    if (!urlRewriteData) {
        if (realPath === '/') {
            return { pageType: DL_VAL_PAGE_HOME };
        }

        const [pathnameKey] = Object.entries(PATH_NAME_MAP).find(([, pathname]) => (
            realPath.indexOf(pathname) !== -1
        )) || [];

        return pathnameKey ? { pageType: pathnameKey } : false;
        // ^^^ false means It's not the pre-defined so 99% we're gonna have it from urlRewrites
    }

    const {
        type,
        notFound = false
    } = urlRewriteData;

    if (notFound) {
        return { pageType: DL_VAL_PAGE_404 };
    }

    if (!type) {
        return { pageType: '' };
    }

    if (realPath === '/') {
        return { pageType: DL_VAL_PAGE_HOME };
    }

    const typeToKeyMap = {
        [TYPE_CMS_PAGE]: `${DL_VAL_PAGE_CMS}${realPath}`,
        [TYPE_CATEGORY]: DL_VAL_PAGE_CATEGORY,
        [TYPE_PRODUCT]: DL_VAL_PAGE_PRODUCT
    };

    const [pathnameKey] = Object.entries(PATH_NAME_MAP).find(([, pathname]) => (
        realPath.indexOf(pathname) !== -1
    )) || [];

    return { pageType: typeToKeyMap[type] || pathnameKey };
};

// ^^^ vvv I am keeping these to make it easier to create a plugin or change it for custom use and also to avoid complicity.

/** @namespace Scandiweb/Gtm/Data/Page/getProductClickPageData */
export const getProductClickPageData = async (urlPathname, urlRewriteData, category = {}, categoryName = '') => {
    const { waitForCallback } = await import('../util/wait');

    // vvv Wait for store code
    await waitForCallback(() => getStore().getState().ConfigReducer?.store_code);

    const {
        ConfigReducer: {
            store_code
        } = {}
    } = getStore().getState();

    const realPath = urlPathname.replace(
        `/${store_code}`,
        ''
    );

    // vvv Try looking up the page from an array of pre-defined ones
    if (realPath === '/' || realPath === '') {
        return { pageType: DL_VAL_PAGE_HOME };
    }

    const [pathnameKey] = Object.entries(PATH_NAME_MAP).find(([, pathname]) => (
        realPath.indexOf(pathname) !== -1
    )) || [];

    if (pathnameKey) {
        return { pageType: pathnameKey };
    }

    const {
        id: categoryId
    } = category;
    const {
        urlRewrite: {
            id: urlRewriteId = -1,
            type,
            notFound = false
        }
    } = urlRewriteData;

    if (notFound) {
        return { pageType: DL_VAL_PAGE_404 };
    }

    if (!type) {
        return { pageType: '' };
    }

    const typeToKeyMap = {
        [TYPE_CMS_PAGE]: `${DL_VAL_PAGE_CMS} - ${realPath}`,
        [TYPE_CATEGORY]: urlRewriteId === categoryId
            ? `${DL_VAL_PAGE_CATEGORY} - ${categoryName}`
            : DL_VAL_PAGE_CATEGORY,
        [TYPE_PRODUCT]: DL_VAL_PAGE_PRODUCT
    };

    return { pageType: typeToKeyMap[type] };
};
