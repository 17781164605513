/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { lazy } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import { updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';

import UrlKeyQuery from '../query/AmUrlResolver.query';

export const BundlePacksPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-bundle-packs" */ '../route/BundlePacksPage'
));

export const TYPE_AMASTY_BUNDLEPACKS = 'AMASTY_BUNDLEPACKS';

const onSuccess = (args) => {
    const [{ amARPUrlResolver, urlResolver }, dispatch, { urlParam }] = args;

    dispatch(
        updateUrlRewrite(
            amARPUrlResolver || urlResolver || { notFound: true },
            urlParam
        )
    );
};

const prepareRequest = (args, callback, instance) => {
    const [options] = args;

    return [
        ...callback(...args),
        UrlKeyQuery.getQuery(instance.processUrlOptions(options))
    ];
};

const containerProps = (args, callback, instance) => {
    const {
        isLoading
    } = instance.props;

    return {
        ...callback(...args),
        isLoading
    };
};

const renderContent = (args, callback, instance) => {
    const { props, type, isLoading } = instance.props;

    if (type !== TYPE_AMASTY_BUNDLEPACKS) {
        return callback(...args);
    }

    if (isLoading) {
        return (
            <ContentWrapper
              mix={ { block: 'LoaderWrapper' } }
              value={ null }
              label=""
            >
                <Loader isLoading={ isLoading } />
            </ContentWrapper>
        );
    }

    return <BundlePacksPage { ...props } />;
};

export default {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess,
            prepareRequest
        }
    },
    'Route/UrlRewrites/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent
        }
    }
};
