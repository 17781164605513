/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import getStore from 'Util/Store';

import { CODE_STATUS_CHECKER_CMS_TYPE } from '../component/CodeStatusChecker/CodeStatusChecker.config';
import { HOMEPAGE_BUTTON_WIDGET } from '../component/HomePageButton/HomePageButton.config';

const CodeStatusChecker = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-giftcards-code-status-widget" */
    '../component/CodeStatusChecker'
));

const HomePageButton = lazy(() => import(
/* webpackMode: "lazy", webpackChunkName: "amasty-giftcards-homepage-button-widget" */
    '../component/HomePageButton'
));

export const addCodeStatusCheckerToWidgets = (originalMembers) => {
    const { generalActive } = getStore().getState()?.ConfigReducer?.AmastyGiftCardsConfig || {};

    if (!generalActive) {
        return originalMembers;
    }

    return {
        ...originalMembers,
        [CODE_STATUS_CHECKER_CMS_TYPE]: {
            component: CodeStatusChecker
        },
        [HOMEPAGE_BUTTON_WIDGET]: {
            component: HomePageButton
        }
    };
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addCodeStatusCheckerToWidgets
        }
    }
};
