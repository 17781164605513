/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const REVIEW_CONTENT_TYPE = 'scandiweb_review';

export const REVIEW_SKELETON = [{
    name: 'Review',
    type: 'div',
    children: [
        {
            name: 'ProductName',
            type: 'span'
        },
        {
            name: 'Rating',
            type: 'span'
        },
        {
            name: 'ReviewTitle',
            type: 'span'
        },
        {
            name: 'ReviewText',
            type: 'span'
        },
        {
            name: 'ReviewerName',
            type: 'span'
        },
        {
            name: 'Image',
            type: 'img'
        }
    ]
}];
