/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { REVIEWS_CONTENT_TYPE, REVIEWS_SKELETON } from '../../component/Reviews/Reviews.config';

export const Reviews = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "cms-misc" */
        '../../component/Reviews'
    ),
);

export const addReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: REVIEWS_CONTENT_TYPE },
        replace: (domNode) => (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                <Suspense fallback={ <div /> }>
                    { createElement(Reviews, {
                        elements: instance.toReactElements(
                            [domNode],
                            REVIEWS_SKELETON
                        )
                    }) }
                </Suspense>
            </RenderWhenVisible>
        )
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
