/**
 * Magedelight Look n Buy compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';
import { SWITCH_ITEMS_TYPE } from 'SourceComponent/Router/Router.config';

import {
    LOOK_N_BUY_PAGE,
    LOOK_N_BUY_PAGE_ROUTE,
    LOOK_N_BUY_PAGE_ROUTE_POSITION
} from '../../route/LookNBuyPage/LookNBuyPage.config';

export const LookNBuyPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "look-n-buy" */
    '../../route/LookNBuyPage'
));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addRoutesToSwitchItems = (member) => ([
    ...member,
    {
        component: (
             <Route
               path={ `${withStoreRegex(LOOK_N_BUY_PAGE_ROUTE)}/:lookUrlKey?` }
               // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
               render={ (props) => <LookNBuyPage { ...props } /> }
             />
        ),
        position: LOOK_N_BUY_PAGE_ROUTE_POSITION,
        name: LOOK_N_BUY_PAGE
    }
]);

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addRoutesToSwitchItems
        }
    }
};
