/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AFTER_ITEMS_TYPE } from 'Component/Router/Router.config';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

export const { ExitIntent, EXIT_INTENT_COMPONENT_NAME } = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "exit-intent" */
    '../../component/ExitIntent'
));

const addExitIntent = (member) => {
    const { isExitIntentEnabled } = getStore().getState().ConfigReducer?.clerkConfig || {};

    if (!isExitIntentEnabled) {
        return member;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...member,
        {
            component: <ExitIntent />,
            position: member.length,
            name: EXIT_INTENT_COMPONENT_NAME
        }
    ];
};

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            [AFTER_ITEMS_TYPE]: addExitIntent
        }
    }
};
