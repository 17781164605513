import { CartIcon as SourceCartIcon } from 'SourceComponent/CartIcon/CartIcon.component';

/** @namespace Scandipwa/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M 20.703 4.6175 C 20.516 4.3475 20.209 4.1865 19.881 4.1865 H 5.85 L 4.8 1.4165 C 4.45 0.6685
                     4.024 0.25 3.214 0.1865 H 0.881 V 1.8 H 3.214 L 7.958 13.5715 C 8.113 13.9435 8.477 14.1865
                     8.881 14.1865 H 16.881 C 17.298 14.1865 17.671 13.9275 17.818 13.5385 L 20.818 5.5385 C 20.933
                     5.2305 20.89 4.8865 20.703 4.6175 Z M 16.5 12.6 H 9.1 L 6.4 5.7 H 19 L 16.5 12.6 Z"
                />
                <path
                  d="M9.38104 18.1865C10.2095 18.1865 10.881 17.5149 10.881 16.6865C10.881 15.8581 10.2095 15.1865
                     9.38104 15.1865C8.55262 15.1865 7.88104 15.8581 7.88104 16.6865C7.88104 17.5149
                     8.55262 18.1865 9.38104 18.1865Z"
                />
                <path
                  d="M16.381 18.1865C17.2095 18.1865 17.881 17.5149 17.881 16.6865C17.881 15.8581 17.2095 15.1865
                     16.381 15.1865C15.5526 15.1865 14.881 15.8581 14.881 16.6865C14.881 17.5149
                     15.5526 18.1865 16.381 18.1865Z"
                />
            </svg>
        );
    }
}

export default CartIconComponent;
