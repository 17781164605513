/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const PayfortPaymentMethods = {
    FORT_CC: 'aps_fort_cc',
    INSTALLMENT: 'aps_installment',
    APPLE: 'aps_apple',
    VAULT: 'aps_fort_vault',
    TABBY: 'aps_fort_tabby',
    APPLE_EXPRESS: 'aps_apple'
};

export const PayfortElements = {
    PAYMENT_POPUP: 'payfort-modal',
    MERCHANT_PAGE: 'PayfortElements.MERCHANT_PAGE',
    SAVED_CARDS_POPUP: 'PayfortSavedCardsPopup',
    NEW_CARD_POPUP: 'PayfortNewCardPopup',
    EDIT_CARD_POPUP: 'PayfortEditCardPopup',
    CONFIRM_CARD_DELETION_POPUP: 'PayfortConfirmCardDeletionPopup',
    INSTALLMENT_POPUP: 'PayfortInstallmentPopup'
};

export const PayfortEvents = {
    MERCHANT_PAGE_EVENT: 'payfortResponse',
    APPLE_EVENT: 'appleResponse',
    PAY_FAIL_EVENT: 'payfortFailEvent',
    PLACE_ORDER_EVENT: 'placeOrderEvent',
    TOKENS_EVENT: 'payfortTokensResponse'
};

export const PayfortMerchantPageEvents = {
    PAYMENT: 'payfortMerchantPagePayment',
    NEW_TOKEN: 'payfortNewToken'
};

export const PayfortPages = {
    STORED_PAYMENT: 'stored-payment'
};

export const PayfortIntegrationTypes = {
    STANDARD: 'standard',
    HOSTED: 'hosted',
    REDIRECTION: 'redirection'
};

export const PayfortHostedCard = {
    CARDHOLDER_NAME: 'card_holder_name',
    CARD_NUMBER: 'card_number',
    EXPIRATION_DATE: 'expiry_date',
    CVC: 'card_security_code'
};

export const PayfortSavedCardRender = {
    CHECKOUT: 'Checkout',
    CHECKOUT_POPUP: 'CheckoutPopup',
    MY_ACCOUNT: 'MyAccount'
};

export const PayfortIdType = {
    CART: 'cart',
    PRODUCT: 'product'
};

export const PAYFORT_TOKEN_NAME = 'payfortToken';
export const PAYFORT_TOKEN_CVV_PREFIX = 'payfortCVV';
export const PAYFORT_NEW_TOKEN_POPUP_WINDOW = 'newTokenPopupWindow';

export const PAYFORT_CVV_MIN = 3;
export const PAYFORT_CVV_MAX = 4;
export const INSTALLMENT_CARD_BIN_LENGTH = 6;
export const MIN_DIGITS_FOR_CARD_NUMBER = 8;
export const CARD_CHUNK_LENGTH = 4;

export const Payfort3DUrlReplacement = {
    OLD: 'threeDs_url',
    NEW: '3ds_url'
};

export const PayfortAppleVersions = {
    FIVE: 5,
    THREE: 3
};

export const PayfortLocalStorage = 'payfortLocalStorage';

// vvv Apple API
export const RequiredShippingContactFields = [
    'postalAddress',
    'name',
    'email',
    'phone'
];

export const PayfortWindowCache = {
    PaymentInformation: 'payfortCachedPaymentInformation'
};
