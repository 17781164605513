/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/ClerkProducts/Query */
export class ClerkProductsQuery {
    getQuery(productIDs) {
        return new Field('clerkProducts')
            .addArgument('productIDs', '[Int]', productIDs)
            .addField(this.getProductsFields());
    }

    getProductsFields() {
        return new Field('products')
            .addFieldList(ProductListQuery._getProductInterfaceFields());
    }
}

export default new ClerkProductsQuery();
