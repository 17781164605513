/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */

import {
    SCANDIPWA_MEDIA_BASE_IMAGE_SIZE,
    SCANDIPWA_MEDIA_LARGE_IMAGE_SIZE,
    SCANDIPWA_MEDIA_SMALL_IMAGE_SIZE,
    SCANDIPWA_MEDIA_THUMBNAIL_IMAGE_SIZE
} from 'Component/ProductCard/ProductCard.config';
import getStore from 'Util/Store';

/** @namespace Scandipwa/Util/Resize/Index/getCloudflareOptimizationImage */
export const getCloudflareOptimizationImage = (src, width, height) => {
    const {
        isCloudflareOptimizationsEnabled = true,
        defaultImageQuality = 85,
        maxDpr = 2
    } = getStore().getState().ConfigReducer.cloudflareConfig || {};

    const dpr = window.devicePixelRatio > maxDpr ? maxDpr : window.devicePixelRatio;

    if (!isCloudflareOptimizationsEnabled || !src) {
        return src;
    }

    const mediaPathIndex = src.search('/media/');
    const staticPathIndex = src.includes('static/');
    const isAlreadyOptimized = src.includes('cdn-cgi');

    if (staticPathIndex || !src.length || mediaPathIndex === -1 || isAlreadyOptimized) {
        return src;
    }

    const cdnSuffix = `/cdn-cgi/image/${ width ? `width=${ width },` : '' }${ height ? `height=${ height },` : '' }quality=${defaultImageQuality}, format=auto, dpr=${dpr}`;
    const cdnSrc = src.slice(0, mediaPathIndex) + cdnSuffix + src.slice(mediaPathIndex);

    return cdnSrc;
};

/** @namespace Scandipwa/Util/Resize/Index/getOptimizedMediaGallery */
export const getOptimizedMediaGallery = (mediaGallery = [], isBaseSmallImage = false) => {
    if (!mediaGallery?.length) {
        return [];
    }

    return mediaGallery?.map((mediaEntry) => ({
        ...mediaEntry,
        large: {
            url: getCloudflareOptimizationImage(mediaEntry?.large?.url, SCANDIPWA_MEDIA_LARGE_IMAGE_SIZE)
        },
        base: {
            url: getCloudflareOptimizationImage(mediaEntry?.base?.url, isBaseSmallImage ? SCANDIPWA_MEDIA_SMALL_IMAGE_SIZE : SCANDIPWA_MEDIA_BASE_IMAGE_SIZE)
        },
        thumbnail: {
            url: getCloudflareOptimizationImage(
                mediaEntry?.thumbnail?.url,
                SCANDIPWA_MEDIA_THUMBNAIL_IMAGE_SIZE
            )
        }
    }));
};
