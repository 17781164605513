import { TOGGLE_POPUP } from './LoaderPopup.action';

/** @namespace Scandipwa/Store/LoaderPopup/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: false
});

/** @namespace Scandipwa/Store/LoaderPopup/Reducer/LoaderPopupReducer */
export const LoaderPopupReducer = (
    state = getInitialState(),
    action
) => {
    const { type, isLoading } = action;

    switch (type) {
    case TOGGLE_POPUP:
        return { ...state, isLoading };
    default:
        return state;
    }
};

export default LoaderPopupReducer;
