/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import { debounceCallback } from '../util/debounce';

export const URL_BEFORE_PAGE_RELOAD = 'urlBeforePageReload';
export const TIMESTAMP_BEFORE_PAGE_RELOAD = 'timestampBeforePageReload';
export const TIMESTAMP_ON_PAGE_LOAD = 'timestampOnPageLoad';
export const SECOND_IN_MILISECONDS = 1000;

/** @namespace Scandiweb/Gtm/Event/General/firePageReloadEvent */
export const firePageReloadEvent = debounceCallback(async (timeOnPage, reloadURL) => {
    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/PageReload/firePageReloadEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { getCustomerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "customer-util" */
                '../data/customer'
            );
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_PAGE_RELOAD } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            const { customerId } = await getCustomerData();

            pushToDataLayer({
                event: GTM_EVENT_KEY_PAGE_RELOAD,
                customerId,
                timeOnPage,
                reloadURL
            });
        }
    );
});

/** @namespace Scandiweb/Gtm/Event/PageReload/calculateTimeOnPage */
export const calculateTimeOnPage = (timestampOnPageLoad, timestampBeforePageReload) => Math.abs(
    (timestampOnPageLoad - timestampBeforePageReload) / SECOND_IN_MILISECONDS
);
    // * ^^^ Note: timestamp variables are in saved in milliseconds.

/** @namespace Scandiweb/Gtm/Event/PageReload/addWindowOnLoadEventListener */
export const addWindowOnLoadEventListener = () => {
    const urlBeforePageReload = sessionStorage.getItem(URL_BEFORE_PAGE_RELOAD);
    const currentUrl = window.location.href;

    // VVV Check if the page is reloaded and user didn't enter a url manually when we were inside our app
    if (urlBeforePageReload && currentUrl === urlBeforePageReload) {
        const timestampOnPageLoad = sessionStorage.getItem(TIMESTAMP_ON_PAGE_LOAD);
        const timestampBeforePageReload = sessionStorage.getItem(TIMESTAMP_BEFORE_PAGE_RELOAD);
        const timeOnPage = calculateTimeOnPage(timestampOnPageLoad, timestampBeforePageReload);
        firePageReloadEvent(timeOnPage, currentUrl);
        // ^^^ We're passing currentUrl just to make sure it won't change if user somehow changed the url.
    }
    sessionStorage.setItem(TIMESTAMP_ON_PAGE_LOAD, Date.now());
};

/** @namespace Scandiweb/Gtm/Event/PageReload/addWindowOnBeforeUnloadEventListener */
export const addWindowOnBeforeUnloadEventListener = () => {
    addEventListener('beforeunload', () => {
        sessionStorage.setItem(TIMESTAMP_BEFORE_PAGE_RELOAD, Date.now());
        sessionStorage.setItem(URL_BEFORE_PAGE_RELOAD, window.location.href);
    });
    // It will be destroyed on each reload so no need to destroy it.
};
