/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import { debounceCallback } from '../util/debounce';

/** @namespace Scandiweb/Gtm/Event/Filter/fireProductFilterAppliedEvent */
export const fireFilterAppliedEvent = debounceCallback(async (addedFilters, filtersDetails, customerId) => {
    const filterKey = Object.keys(addedFilters[0])[0];
    const filterValue = addedFilters[0][filterKey][0];

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Filter/fireFilterAppliedEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_FILTER_APPLIED } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );
            const { getFilterDetails } = await import(
                /* webpackMode: "lazy", webpackChunkName: "filter-util" */
                '../data/filter'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_FILTER_APPLIED,
                customerId,
                ...getFilterDetails(filtersDetails, filterKey, filterValue)
            });
        }
    );
});

/** @namespace Scandiweb/Gtm/Event/Filter/fireProductFilterAppliedEvent */
export const fireFilterRemovedEvent = debounceCallback(async (removedFilters, filtersDetails, customerId) => {
    const filterKey = Object.keys(removedFilters[0])[0];
    const filterValue = removedFilters[0][filterKey][0];

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Filter/fireFilterRemovedEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_FILTER_REMOVED } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );
            const { getFilterDetails } = await import(
                /* webpackMode: "lazy", webpackChunkName: "filter-util" */
                '../data/filter'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_FILTER_REMOVED,
                customerId,
                ...getFilterDetails(filtersDetails, filterKey, filterValue)
            });
        }
    );
});

export const fireAllFiltersRemovedEvent = debounceCallback(async (removedFilters, filtersDetails, customerId) => {
    const filterKeys = removedFilters.map((filter) => Object.keys(filter)[0]);
    // ^^^ in each element of array there is an object with only one property so [0] is for that

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Filter/fireAllFiltersRemovedEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_ALL_FILTERS_REMOVED } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );
            const { getFiltersCategoryName } = await import(
                /* webpackMode: "lazy", webpackChunkName: "filter-util" */
                '../data/filter'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_ALL_FILTERS_REMOVED,
                customerId,
                filterCategories: getFiltersCategoryName(filtersDetails, filterKeys).join(',')
            });
        }
    );
});

/** @namespace Scandiweb/Gtm/Event/Filter/handleProductFilterEvents */
export const handleProductFilterEvents = async (oldCustomFilters, customFilters, filtersDetails, customerId) => {
    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Filter/handleProductFilterEvents/waitForPriorityLoad/then */
        async () => {
            const { getFilterDifferences, getFiltersCount } = await import(
                /* webpackMode: "lazy", webpackChunkName: "filter-util" */
                '../data/filter'
            );

            const lastPLPUrl = sessionStorage.getItem('lastPLPUrl');
            const [removedFilters, addedFilters] = getFilterDifferences(oldCustomFilters, customFilters);
            const currentLocation = location.origin + location.pathname;
            const addedFiltersCount = getFiltersCount(addedFilters);
            const removedFiltersCount = getFiltersCount(removedFilters);
            const pushRemovedFiltersToDataLayer = lastPLPUrl && lastPLPUrl === currentLocation;
            // ^^^ if it's undefined means it's first time here and we don't need to dispatch RemovedFilters
            // ^^^ if they're equal it means we might removed/added filter(s) if they're not we don't need to dispatch removedFilters
            // ^^^ if we don't have any item in it, don't push it

            if (pushRemovedFiltersToDataLayer) {
                // vvv If it's < 1 it means nothing has been added. If it's 1 means we removed filter by clicking on filters
                if (removedFiltersCount === 1) {
                    fireFilterRemovedEvent(removedFilters, filtersDetails, customerId);
                }

                // vvv If it's < 1 it means nothing has been added. If it's >1 means we removed filters by clicking on 'reset all' button
                if (removedFiltersCount > 1) {
                    fireAllFiltersRemovedEvent(removedFilters, filtersDetails, customerId);
                }
            }

            // vvv If it's < 1 it means nothing has been added. If it's >1 means we added filter through URL not by clicking on filters
            if (addedFiltersCount === 1) {
                fireFilterAppliedEvent(addedFilters, filtersDetails, customerId);
            }

            sessionStorage.setItem('lastPLPUrl', currentLocation);
        }
    );
};
