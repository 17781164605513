export const renderCanonical = (args, callback, instance) => {
    const {
        robots,
        seoConfigurationData: {
            is_disable_canonicals_for_noindex: disableCanonicals = false
        } = {}
    } = instance.props;

    if (disableCanonicals && robots?.includes('noindex')) {
        return null;
    }

    return callback(...args);
};

export default {
    'Scandipwa/Component/Meta/Component': {
        'member-function': {
            renderCanonical
        }
    }
};
