/* eslint-disable react/forbid-elements */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import Loader from 'Component/Loader';
import { Link as SourceLink } from 'SourceComponent/Link/Link.component';

import './Link.style';

/** @namespace Scandipwa/Component/Link/Component */
export class LinkComponent extends SourceLink {
    static propTypes = {
        ...this.propTypes,
        showLoader: PropTypes.bool.isRequired
    };

    render() {
        const {
            className,
            bemProps,
            children,
            to,
            isOpenInNewTab,
            showLoader,
            ...props
        } = this.props;

        if (!to) {
            return (
                <div { ...props } { ...bemProps }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return this.renderRelativePathLink();
        }

        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (/^https?:\/\//.test(to) || isOpenInNewTab) {
            return this.renderAbsolutePathLink(classNameConverted);
        }

        if (showLoader) {
            const {
                isLoaderActive,
                handleLinkClick,
                ...otherProps
            } = this.props;

            return (
                <>
                    <div
                      block="Link"
                      elem="LoaderWrapper"
                      mods={ { isLoaderActive } }
                    >
                        <Loader />
                    </div>
                    <RouterLink
                      { ...otherProps }
                      to={ to }
                      onKeyDown={ handleLinkClick }
                      onClick={ handleLinkClick }
                      // eslint-disable-next-line react/forbid-component-props
                      className={ classNameConverted }
                    >
                        { children }
                    </RouterLink>
                </>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
              // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }
}

export default LinkComponent;
