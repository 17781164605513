/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Clerk/Query/ClerkProductIDsFromOrder/Query */
export class ClerkProductIDsFromOrderQuery {
    getQuery(orderId) {
        return new Field('clerkProductIDsFromOrder')
            .addArgument('orderId', 'String!', orderId);
    }
}

export default new ClerkProductIDsFromOrderQuery();
