/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { VIDEO_CONTENT_TYPE, VIDEO_SKELETON } from '../../component/Video/Video.config';

export const Video = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "cms-misc" */
        '../../component/Video'
    ),
);

const addVideoReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: VIDEO_CONTENT_TYPE },
        replace: (domNode) => (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                <Suspense fallback={ <div /> }>
                    { createElement(Video, {
                        elements: instance.toReactElements(
                            [domNode],
                            VIDEO_SKELETON
                        )
                    }) }
                </Suspense>
            </RenderWhenVisible>
        )
    }
]);

export default addVideoReplacementRule;
