export * from 'SourceStore/Category/Category.action';
export const UPDATE_SCROLL_Y = 'UPDATE_SCROLL_Y';

/**
 * New method to update window scrollY on Redux state
 * @namespace Scandipwa/Store/Category/Action/updateScrollY */
export const updateScrollY = (scrollY) => ({
    type: UPDATE_SCROLL_Y,
    scrollY
});
