/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { PRODUCT_SLIDER_RULE } from '../../component/ProductSlider/ProductSlider.config';

export const ProductSlider = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "cms-misc" */
        '../../component/ProductSlider'
    ),
);

const extractDomAttributes = (domNode) => {
    const { attribs, children } = domNode;

    const conditionSetting = children[0].attribs;

    const autoplaySpeed = parseInt(attribs['data-autoplay-speed'], 10);
    const autoplay = attribs['data-autoplay'] === 'true';

    return {
        sliderConfig: {
            autoplay,
            autoplaySpeed,
            infinite: attribs['data-infinite-loop'] === 'true',
            showArrows: attribs['data-show-arrows'] === 'true',
            showDots: attribs['data-show-dots'] === 'true',
            dataCarouselMode: attribs['data-carousel-mode']
        },
        productQueryParams: {
            conditionsEncoded: conditionSetting.conditions_encoded,
            productsCount: conditionSetting.products_count,
            sortOrder: conditionSetting.sort_order,
            conditionOption: conditionSetting.condition_option,
            conditionOptionValue: conditionSetting.condition_option_value
        },
        pageBuilderStyles: {
            classes: attribs.class,
            dataPbStyle: attribs['data-pb-style']
        }
    };
};

const addReplacementRule = (originalMember) => ([
    ...originalMember,
    {
        query: { dataContentType: PRODUCT_SLIDER_RULE },
        replace: (domNode) => (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                <Suspense fallback={ <div block="ProductSlider" elem="Placeholder" /> }>
                    { createElement(ProductSlider, extractDomAttributes(domNode)) }
                </Suspense>
            </RenderWhenVisible>
        )
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
