import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Link/Link.container';
import { scrollToTop } from 'Util/Browser';
import { noopFn } from 'Util/Common';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import Link from './Link.component';

/** @namespace Scandipwa/Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    static propTypes = {
        ...this.propTypes,
        showLoader: PropTypes.bool,
        onClickBeforeLoader: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        showLoader: true,
        onClickBeforeLoader: noopFn
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleLinkClick: this.handleLinkClick.bind(this)
    };

    state = {
        isLoaderActive: false
    };

    handleLinkClick(e) {
        const {
            onClickBeforeLoader
        } = this.props;

        const to = this.getTo();

        e?.preventDefault();
        e?.stopPropagation();

        if (onClickBeforeLoader) {
            onClickBeforeLoader();
        }

        this.setState({ isLoaderActive: true });

        setTimeout(() => {
            this.setState({ isLoaderActive: false });

            if (to instanceof Object) {
                if (e?.ctrlKey || e?.metaKey) {
                    window.open(appendWithStoreCode(to?.pathname), '_blank');

                    return;
                }

                history.push(to);

                return;
            }

            if (e?.ctrlKey || e?.metaKey) {
                window.open(appendWithStoreCode(to), '_blank');

                return;
            }

            history.push(appendWithStoreCode(to));
        }, 0);

        setTimeout(() => {
            scrollToTop();
        });
    }

    getTo() {
        const { to: toProp } = this.props;
        // fix null, undefined and empty links
        const to = toProp || '/';

        if (typeof to === 'string') {
            // in case this URL is absolute, do not append store
            if (/^https?:\/\//.test(to)) {
                const baseUrl = 'panhomestores.com';
                const isInternalLink = to.indexOf(baseUrl) !== -1;

                if (!isInternalLink) {
                    return to;
                }

                const { pathname } = new URL(to);

                return appendWithStoreCode(pathname);
            }

            return appendWithStoreCode(to);
        }

        const pathname = to.pathname || '/';

        return {
            ...to,
            pathname: appendWithStoreCode(pathname)
        };
    }

    containerProps() {
        const { isLoaderActive } = this.state;

        return {
            ...super.containerProps(),
            isLoaderActive
        };
    }

    render() {
        return (
            <Link
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
