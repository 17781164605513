export const UPDATE_MENU_ITEMS = 'UPDATE_LANDING_MENU_ITEMS';

/**
 * Set navigation menu items
 * @param  {Object} menu Object with all menu items
 * @return {void}
 * @namespace Scandipwa/Store/LandingMenu/Action/updateMenuItems */
export const updateMenuItems = (menu) => ({
    type: UPDATE_MENU_ITEMS,
    menu
});
