/**
 * Magedelight Look n Buy compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const LOOK_N_BUY_PAGE_ROUTE = '/look-n-buy';
export const LOOK_N_BUY_PAGE_ROUTE_POSITION = '100';
export const LOOK_N_BUY_PAGE = 'LookNBuy';
export const LOOK_N_BUY_LIST_PAGE_TYPE_KEY = 'look-n-buy-list-page';
export const LOOK_N_BUY_VIEW_PAGE_TYPE_KEY = 'look-n-buy-view-page';
