/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

export const Widget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "new-clerk" */
    '../../component/Widgets/Widgets'
));

export const renderClerkWidgetPlaceholder = () => {
    const mods = {
        withWrapper: true
    };

    return (<div block="WidgetFactory" elem="RecommendedProductsPlaceholder" mods={ mods } />);
};

export const addClerkToWidgetFactory = (member) => ({
    ...member,
    ClerkWidget: {
        component: Widget,
        fallback: renderClerkWidgetPlaceholder
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addClerkToWidgetFactory
        }
    }
};
