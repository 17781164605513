export const DESIGN_ROOM_URL = 'design_room';
export const DESIGN_ROOM_SCRIPT_ID = 'DesignRoom-Script';
export const DESIGN_ROOM_SCRIPT_URL = 'https://live.hullabalook.com/static/js/hulla_embed.js';
export const DESIGN_ROOM_PAGE_SCRIPT_ID = 'DesignRoomPage-Script';
export const DESIGN_ROOM_PAGE_SCRIPT_ID_FETCH = 'DesignRoomPage-Script_Fetch';
export const DESIGN_ROOM_PAGE_SCRIPT_URL = 'https://panemirates.hulla-cdn.com/pan-room-designer/hulla_integrate.js';

export const HULLA_RESPONSE_CURRENT_WISHLIST = 'current_wishlist';
export const HULLA_RESPONSE_ADD_TO_CART = 'add_to_cart_response';
export const HULLA_MESSAGE_ADD_TO_CART = 'hulla_add_to_cart';
export const HULLA_MESSAGE_ADD_TO_WISHLIST = 'hulla_add_to_wishlist';
export const HULLA_MESSAGE_GET_WISHLIST = 'hulla_get_wishlist';
export const HULLA_MESSAGE_REMOVE_FROM_WISHLIST = 'hulla_remove_from_wishlist';
export const HULLA_ORIGIN = 'https://panemirates.hulla-cdn.com';

// DESIGN_ROOM_STORES: enable Hullabalook feature only for some stores temporarily:
export const DESIGN_ROOM_STORES = [
    'uae_en',
    'uae_ar',
    'default'
];
