import {
    ADD_RECENTLY_VIEWED_PRODUCT,
    addRecentlyViewedProduct,
    UPDATE_LOAD_STATUS,
    UPDATE_RECENTLY_VIEWED_PRODUCTS,
    updateLoadStatus,
    updateRecentlyViewedProducts
} from 'SourceStore/RecentlyViewedProducts/RecentlyViewedProducts.action';

export {
    UPDATE_RECENTLY_VIEWED_PRODUCTS,
    ADD_RECENTLY_VIEWED_PRODUCT,
    UPDATE_LOAD_STATUS,
    addRecentlyViewedProduct,
    updateRecentlyViewedProducts,
    updateLoadStatus
};

export const TRIM_RECENTLY_VIEWED_PRODUCTS = 'TRIM_RECENTLY_VIEWED_PRODUCTS';

/** @namespace Scandipwa/Store/RecentlyViewedProducts/Action/trimRecentlyViewedProducts */
export const trimRecentlyViewedProducts = () => ({
    type: TRIM_RECENTLY_VIEWED_PRODUCTS
});
