/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CART_OVERLAY } from 'Component/Header/Header.config';
import getStore from 'Util/Store';
import { getIsAffordable, getIsPremium } from 'Util/Url';

import { fireProductClickEvent } from '../event/list';
import { getElPosition } from './position';

/** @namespace Scandiweb/Gtm/Util/Handlers/handleClickEvent */
export const handleClickEvent = (e, props) => {
    const { to, isSearchOverlay } = props;

    if (to instanceof String) {
        return;
    }

    const { state, pathname } = to;

    if (!state?.product) {
        return;
    }

    const {
        product,
        product: {
            currentCategory
        }
    } = state;
    const { className } = e?.target?.closest('li') || {};

    const isSlide = className ? className.includes('isSlide') : false;
    const sliderTitle = e?.target?.closest('.Related')?.firstChild?.innerHTML
        || e?.target?.closest('.Recommend-Wrapper')?.querySelector('h2')?.innerHTML
        || e?.target?.closest('.ThankYouPage-Wrapper')?.querySelector('.RecommendedProducts-Title')?.innerHTML
        || e?.target?.closest('.RecentlyViewedWidget')?.firstChild?.innerHTML
        || e?.target?.closest('.CartOverlay-Additional')?.querySelector('h2')?.innerHTML;

    const { urlRewrite: { type } } = getStore().getState().UrlRewritesReducer;
    const isCartOverlay = getStore().getState().OverlayReducer.activeOverlay === CART_OVERLAY;
    const { code } = getStore().getState().ConfigReducer;
    const isCartPage = window.location.pathname.indexOf('cart') !== -1;
    const isThankYouPage = window.location.pathname.indexOf('thankyou') !== -1;
    const isSearchPage = window.location.pathname.indexOf('search') !== -1;
    const isHomePage = window.location.pathname.replace(/[/]/g, '') === code;
    const isBlogPage = window.location.pathname.indexOf('blog') !== -1;

    const affordablePageSliderTitle = getIsAffordable()
        ? `Affordable Page - ${sliderTitle || currentCategory || 'slider'}`
        : null;
    const premiumPageSliderTitle = getIsPremium()
        ? `Premium Page - ${sliderTitle || currentCategory || 'slider'}`
        : null;
    const blogPageSliderTitle = isBlogPage
        ? `Blog - ${sliderTitle || currentCategory || 'slider'}`
        : null;

    const homePageSliderTitle = isHomePage
        ? `Home Page - ${sliderTitle || currentCategory} Slider`
        : null;

    const pdpSliderTitle = type && type === 'PRODUCT' && sliderTitle ? `PDP - ${sliderTitle}` : null;
    const miniCartSliderTitle = isCartOverlay && sliderTitle ? `Mini Cart - ${sliderTitle}` : null;
    const cartSliderTitle = isCartPage && sliderTitle ? `Cart - ${sliderTitle}` : null;
    const thankYouSliderTitle = isThankYouPage && sliderTitle ? `Thank you - ${sliderTitle}` : null;
    const pdpTitle = type && type === 'PRODUCT' ? 'PDP - product' : null;
    const miniCartTitle = isCartOverlay ? 'Mini Cart - product' : null;
    const cartTitle = isCartPage ? 'Cart - product' : null;
    const thankyouTitle = isThankYouPage ? 'Thank you - product' : null;
    const searchPageTitle = isSearchPage ? 'Search - product' : null;
    const serchOverlayTitle = isSearchOverlay ? 'Search Overlay - product' : null;

    fireProductClickEvent(
        product,
        getElPosition(e?.target?.closest('li')) || 1,
        miniCartSliderTitle
            || cartSliderTitle
            || thankYouSliderTitle
            || miniCartTitle
            || cartTitle
            || thankyouTitle
            || searchPageTitle
            || serchOverlayTitle
            || homePageSliderTitle
            || affordablePageSliderTitle
            || premiumPageSliderTitle
            || blogPageSliderTitle
            || pdpSliderTitle
            || pdpTitle
            || null,
        pathname,
        getStore().getState().UrlRewritesReducer,
        getStore().getState().CategoryReducer?.category,
        isSlide
    );
};
