/* eslint-disable react/boolean-prop-naming */

import PropTypes from 'prop-types';
import {
    createRef,
    PureComponent
} from 'react';
import { connect } from 'react-redux';

import {
    ChildrenType,
    MixType,
    RefType
} from 'Type/Common.type';
import {
    DESKTOP,
    MOBILE
} from 'Util/Device';
import { getIsAffordable, getIsHomePage } from 'Util/Url';

import SliderPerSlide from './SliderPerSlide.component';
import { DEFAULT_SLIDE_ITEM_WIDTH_IN_PX } from './SliderPerSlide.config';

/** @namespace Scandipwa/Component/SliderPerSlide/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    isLandingPageEnabled: state.ConfigReducer?.landingPageConfig?.isEnabled
});

/** @namespace Scandipwa/Component/SliderPerSlide/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/SliderPerSlide/Container */
export class SliderPerSlideContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        children: ChildrenType,
        maximumTotalSlideItemPerSlide: PropTypes.number,
        isInfinite: PropTypes.bool,
        mix: MixType,
        sliderRef: RefType,
        sliderWidth: PropTypes.number,
        isHideArrowInSingleSlide: PropTypes.bool,
        hasArrowBorder: PropTypes.bool,
        hasArrowBackground: PropTypes.bool,
        hasContentWrapper: PropTypes.bool,
        isInteractionDisabled: PropTypes.bool,
        isNotInfiniteOnSingleSlide: PropTypes.bool,
        showArrows: PropTypes.bool,
        slideItemWidth: PropTypes.shape({
            desktop: PropTypes.number,
            mobile: PropTypes.number
        }),
        isLandingPage: PropTypes.bool,
        isLandingPageEnabled: PropTypes.bool,
        isDateSlots: PropTypes.bool,
        slidesCount: PropTypes.number
    };

    static defaultProps = {
        children: [],
        maximumTotalSlideItemPerSlide: null,
        isInfinite: false,
        mix: null,
        sliderRef: null,
        sliderWidth: null,
        hasArrowBorder: null,
        hasArrowBackground: null,
        hasContentWrapper: null,
        isInteractionDisabled: null,
        showArrows: null,
        isHideArrowInSingleSlide: false,
        isNotInfiniteOnSingleSlide: false,
        slideItemWidth: null,
        isLandingPage: false,
        isLandingPageEnabled: false,
        isDateSlots: false,
        slidesCount: 0
    };

    state = {
        activeSlide: 0,
        sliderWidth: this.getSliderWidth()
    };

    sliderRef = createRef();

    updateSliderWidth = this.updateSliderWidth.bind(this);

    containerFunctions = {
        handleSlideChange: this.handleSlideChange.bind(this)
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateSliderWidth, { passive: true });
        this.updateSliderWidth();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSliderWidth, { passive: true });
    }

    containerProps() {
        const {
            isInfinite,
            isHideArrowInSingleSlide,
            hasArrowBorder,
            hasArrowBackground,
            hasContentWrapper,
            mix,
            isInteractionDisabled,
            isNotInfiniteOnSingleSlide,
            showArrows,
            children,
            isLandingPage,
            isMobile,
            isLandingPageEnabled,
            isDateSlots
        } = this.props;
        const {
            activeSlide,
            sliderWidth
        } = this.state;

        return {
            isInfinite,
            isHideArrowInSingleSlide,
            hasArrowBorder,
            hasArrowBackground,
            hasContentWrapper,
            mix,
            isInteractionDisabled,
            isNotInfiniteOnSingleSlide,
            showArrows,
            activeSlide,
            sliderWidth,
            ...this.getSlidesMath(),
            sliderRef: this.sliderRef,
            children,
            isLandingPage,
            isMobile,
            isLandingPageEnabled,
            slideWidth: this.getSlideWidth(),
            isDateSlots
        };
    }

    getSlidesMath() {
        const {
            children = [],
            isMobile,
            maximumTotalSlideItemPerSlide,
            slideItemWidth: slideItemWidthProp,
            isLandingPage,
            slidesCount
        } = this.props;

        const {
            sliderWidth
        } = this.state;

        const isAffordable = getIsAffordable();

        const device = isMobile ? MOBILE : DESKTOP;
        const slideItemWidthObject = slideItemWidthProp || DEFAULT_SLIDE_ITEM_WIDTH_IN_PX;
        const slideItemWidthObjectLanding = !isAffordable
            ? slideItemWidthObject.landing
            : slideItemWidthObject.affordable;
        const slideItemWidthMobileLanding = isLandingPage && isMobile && getIsHomePage()
            ? slideItemWidthObject?.landingMobile
            : null;
        const slideItemWidth = isLandingPage && !isMobile
            ? slideItemWidthObjectLanding
            : slideItemWidthMobileLanding || slideItemWidthObject[device];
        const totalSlideItemPerSlide = Math.floor(sliderWidth / slideItemWidth);

        /**
         * Math.min(totalSlideItemPerSlide, slideItemToDisplay):
         * There can be maximum number of items per slide set through config.
         * It can be less than the amount of items fitting into slider width
         */
        const totalSlideItemPerSlideCapped = maximumTotalSlideItemPerSlide
            ? Math.min(totalSlideItemPerSlide, maximumTotalSlideItemPerSlide) : totalSlideItemPerSlide;
        const totalSlideItemPerSlideAll = slidesCount > totalSlideItemPerSlideCapped && isLandingPage
            ? slidesCount
            : totalSlideItemPerSlideCapped;
        const totalSlide = isMobile && isLandingPage
            ? children.length
            : Math.ceil(children.length / totalSlideItemPerSlideAll);

        return {
            totalSlideItemPerSlide: isLandingPage && isMobile
                ? maximumTotalSlideItemPerSlide
                : totalSlideItemPerSlideAll || totalSlideItemPerSlide,
            totalSlide
        };
    }

    getSliderWidth() {
        const {
            sliderWidth
        } = this.state || {};

        // 1st render, sliderWidth is not set yet, fallback to any contentwrapper width found on page
        if (!sliderWidth || !this.sliderRef.current || !this.sliderRef.current.clientWidth) {
            const firstContentWrapper = document.querySelector('.ContentWrapper');

            const wrapperComputedStyle = window.getComputedStyle(firstContentWrapper, null);

            const wrapperWidth = firstContentWrapper.clientWidth - parseFloat(wrapperComputedStyle.paddingLeft)
                - parseFloat(wrapperComputedStyle.paddingRight);

            return wrapperWidth;
        }

        // if slider is rendered, get its width instead
        return this.sliderRef.current.clientWidth;
    }

    getSlideWidth() {
        const element = this.sliderRef.current;
        const slide = element?.children?.[0]?.children?.[0];
        const width = slide?.children?.[1]?.clientWidth || 0;

        return width;
    }

    updateSliderWidth() {
        this.setState({ sliderWidth: this.getSliderWidth() });
    }

    handleSlideChange(activeSlide) {
        this.setState({ activeSlide: Math.abs(activeSlide) });
    }

    render() {
        return (
            <SliderPerSlide
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(SliderPerSlideContainer);
