/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BrowserDatabase from 'Util/BrowserDatabase';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import { DL_VAL_NOT_APPLICABLE } from '../../data/common';
import { fireGeneralEvent } from '../../event/general';
import {
    GTM_LAST_STORE_VIEW,
    GTM_PANEMIRATES_PUSHNOTIFICATIONS,
    PUSHNOTIFICATION_DEVICE_TYPE
} from '../../util/events';
import { pushToDataLayer } from '../../util/push';

// vvv Added a component to use location hook
function GTMGeneralEventTracker() {
    const location = useLocation();

    useEffect(() => {
        waitForPriorityLoad().then(
            async () => {
                const { fireGeneralEvent } = await import('../../event/general');
                const {
                    requestedUrl,
                    urlRewrite
                } = await getStore().getState().UrlRewritesReducer;

                if (requestedUrl !== location.pathname) {
                    const urlRewrite = {
                        type: 'PWA_ROUTER'
                    };

                    fireGeneralEvent(urlRewrite);

                    return;
                }

                fireGeneralEvent(urlRewrite);
            }
        );
    }, [location.pathname]);

    // useEffect(() => {
    //     fireGeneralEvent();
    // }, []);

    return null;
}

const addGTMGeneralEventTracker = (args, callback) => (
    <>
        { callback(args) }
        <GTMGeneralEventTracker />
    </>
);

const addGTMGeneralEvent = (args, callback) => {
    const [urlResolver, urlParam] = args;
    const newUrlRewrite = urlResolver || { notFound: true };

    const {
        requestedUrl = ''
    } = getStore().getState().UrlRewritesReducer;
    const {
        code = DL_VAL_NOT_APPLICABLE
    } = getStore().getState().ConfigReducer;

    // check if URL changed and it's new and prevent sending duplicates event
    if (
        !window.gtmIsGeneralEventFired
        || requestedUrl !== urlParam
        || window.gtmLastGeneralEventId !== newUrlRewrite.id
        || window.gtmLastGeneralEventType !== newUrlRewrite.type
        || window.gtmLastGeneralEventSku !== newUrlRewrite.sku
        || newUrlRewrite.notFound
        || code !== BrowserDatabase.getItem(GTM_LAST_STORE_VIEW)
    ) {
        fireGeneralEvent(newUrlRewrite);
        window.gtmLastGeneralEventType = newUrlRewrite.type;
        window.gtmLastGeneralEventId = newUrlRewrite.id;
        window.gtmLastGeneralEventSku = newUrlRewrite.sku;
    }

    return callback(...args);
};

const firePushNotificationEvent = (args, callback) => {
    const params = new URLSearchParams(window.location.search);

    if (params.has(GTM_PANEMIRATES_PUSHNOTIFICATIONS)) {
        const notificationType = params.get(GTM_PANEMIRATES_PUSHNOTIFICATIONS);
        const deviceType = params.get(PUSHNOTIFICATION_DEVICE_TYPE);

        pushToDataLayer({ event: notificationType, device: deviceType });
    }

    callback(args);
};

export default {
    'Scandipwa/Component/Router/Component': {
        'member-function': {
            renderRouterContent: addGTMGeneralEventTracker
        }
    },
    'Scandipwa/Component/Router/Container': {
        'member-function': {
            componentDidMount: firePushNotificationEvent
        }
    },
    'Store/UrlRewrites/Action/updateUrlRewrite': {
        function: addGTMGeneralEvent
    }
};
