export * from 'SourceComponent/ProductCard/ProductCard.config';

export const ATTRIBUTE_LIMIT_DESKTOP = 2;
export const ATTRIBUTE_LIMIT_MOBILE = 1;
export const PRODUCT_TYPE_SCHEMA = 'http://schema.org/Product';
export const OFFER_TYPE_SCHEMA = 'https://schema.org/Offer';
export const OFFER_PROP_SCHEMA = 'offers';
export const INSTOCK_TYPE_SCHEMA = 'https://schema.org/InStock';
export const OUTOFSTOCK_TYPE_SCHEMA = 'https://schema.org/OutOfStock';
export const SCANDIPWA_MEDIA_LARGE_IMAGE_SIZE = 1200;
export const SCANDIPWA_MEDIA_BASE_IMAGE_SIZE = 650;
export const SCANDIPWA_MEDIA_THUMBNAIL_IMAGE_SIZE = 250;
export const PRODUCT_CARD_PRODUCT_GALLERY_AUTO_PLAY_DELAY = 3000;
export const SCANDIPWA_MEDIA_SMALL_IMAGE_SIZE = 380;
