/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    DESKTOP,
    MOBILE
} from 'Util/Device';

export const CATEGORY_SLIDER_CONTENT_TYPE = 'scandiweb_categoryslider';

export const CATEGORY_SLIDER_SKELETON = [{
    name: 'CategorySlider',
    type: 'div',
    children: [
        {
            name: 'Title',
            type: 'span'
        },
        {
            name: 'Categories',
            type: 'div'
        }
    ]
}];

export const MAXIMUM_SLIDE_ITEMS = 8;
export const CATEGORY_WIDTH_IN_PX = {
    [DESKTOP]: 160,
    [MOBILE]: 120
};

export const LANDING_PAGES = ['affordable', 'premium'];
