import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SliderQuery from 'Query/Slider.query';
import {
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/SliderWidget/SliderWidget.container';
import {
    getDataCacheForQuery,
    getQueryHashForQueries,
    setDataCache
} from 'Util/Cache/Cache';
import { prepareQuery } from 'Util/Query';
import DataContainer from 'Util/Request/DataContainer';
import { executeGet } from 'Util/Request/Request';

import SliderWidget from './SliderWidget.component';
import { DESKTOP_SLIDES_COUNT, SLIDER_WIDGET_CACHE_TTL } from './SliderWidget.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/SliderWidget/Container */
/**
 * Overridden to:
 * - Parent SliderContainer inheritance was removed, because it overriddes state.
 */
export class SliderWidgetContainer extends DataContainer {
    static propTypes = {
        ...this.propTypes,
        isArrowSmall: PropTypes.bool,
        shouldRenderArrowOnMobile: PropTypes.bool,
        hasArrowBorder: PropTypes.bool,
        hasArrowBackground: PropTypes.bool,
        hasArrowContentWrapper: PropTypes.bool,
        fallback: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        isArrowSmall: false,
        shouldRenderArrowOnMobile: true,
        hasArrowBorder: false,
        hasArrowBackground: false,
        hasArrowContentWrapper: false
    };

    /**
     * Overridden to add show_navigation, is_active and show_menu to state,
     * Decrease slider speed for a specific slider
     */

    __construct(props) {
        const { sliderId } = props;

        super.__construct(props, `SliderWidgetContainer-${sliderId}`);

        this.state = {
            slider: {
                slideSpeed: 0,
                slides: [{ image: '', slide_text: '', isPlaceholder: true }],
                show_navigation: false,
                is_active: false,
                show_menu: false
            },
            slidesPerSlide: 1
        };

        const rawQueries = [SliderQuery.getQuery({ sliderId })];

        const queryHash = getQueryHashForQueries(rawQueries);
        const dataCache = getDataCacheForQuery(rawQueries, queryHash);

        if (dataCache) {
            this.state.slider = dataCache;
        }
    }

    /**
     * Overridden to:
     * - request slider using get request and cache it for 3 days
     * - Load and cache slider from window.dataCahce (Needed for BackForward navigation)
     */
    async requestSlider() {
        const { sliderId, showNotification, device: { isMobile } } = this.props;
        const {
            actionName: {
                slider: {
                    slider_id
                } = {},
                slider
            } = {}
        } = window;

        if (slider_id && parseInt(slider_id, 10) === sliderId) {
            this.setState({
                isLoading: false,
                slider
            });

            return;
        }

        const rawQueries = [SliderQuery.getQuery({ sliderId })];

        const queryHash = getQueryHashForQueries(rawQueries);
        const dataCache = getDataCacheForQuery(rawQueries, queryHash);
        const slidesPerSlide = isMobile ? 2 : DESKTOP_SLIDES_COUNT;

        if (dataCache) {
            if (dataCache?.slides?.find(({ desktop_image_2 }) => desktop_image_2)) {
                this.setState({ slidesPerSlide });
            }

            this.setState({
                isLoading: false,
                slider: dataCache
            });

            return;
        }

        try {
            const { slider } = await executeGet(
                prepareQuery(rawQueries), 'Slider', SLIDER_WIDGET_CACHE_TTL
            );

            const newSlides = slider?.slides.reduce((acc, item) => {
                const {
                    slide_id,
                    title,
                    is_active,
                    desktop_image_2,
                    desktop_image_3,
                    mobile_image_2,
                    mobile_image_3,
                    slide_link_2,
                    slide_link_3,
                    slide_text_2,
                    slide_text_3
                } = item;

                if (!desktop_image_2) {
                    return [...acc, { ...item }];
                }

                /* eslint-disable prefer-template */
                const desktopImage2 = 'media/' + desktop_image_2;
                const desktopImage3 = 'media/' + desktop_image_3;
                const mobileImage2 = 'media/' + mobile_image_2;
                const mobileImage3 = 'media/' + mobile_image_3;
                /* eslint-enable prefer-template */

                this.setState({ slidesPerSlide });

                return [
                    ...acc,
                    { ...item },
                    {
                        desktop_image: desktopImage2,
                        mobile_image: mobileImage2,
                        slide_link: slide_link_2,
                        slide_text: slide_text_2,
                        title,
                        is_active,
                        slide_id
                    },
                    {
                        desktop_image: desktopImage3,
                        mobile_image: mobileImage3,
                        slide_link: slide_link_3,
                        slide_text: slide_text_3,
                        title,
                        is_active,
                        slide_id
                    }
                ];
            }, []);

            const newSlider = { ...slider, slides: newSlides };

            setDataCache(queryHash, newSlider);

            this.setState({
                isLoading: false,
                slider: newSlider
            });
        } catch {
            showNotification('error', __('Error fetching Slider!'));
            this.setState({
                isLoading: false
            });
        }
    }

    containerProps() {
        const {
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            hasArrowContentWrapper,
            showMenu,
            fallback,
            device
        } = this.props;
        const {
            slider,
            slider: {
                slideSpeed
            },
            slidesPerSlide
        } = this.state;

        return {
            device,
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            hasArrowContentWrapper,
            showMenu,
            slider: {
                ...slider,
                slideSpeed
            },
            fallback,
            slidesPerSlide
        };
    }

    componentDidMount() {
        this.requestSlider();
    }

    componentDidUpdate(prevProps) {
        const { sliderId } = this.props;
        const { sliderId: pSliderId } = prevProps;

        if (sliderId !== pSliderId) {
            this.requestSlider();
        }
    }

    _getGalleryPictures() {
        const { gallery } = this.state;

        return gallery;
    }

    render() {
        return (
            <SliderWidget
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderWidgetContainer);
