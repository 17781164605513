/**
 * ScandiPWA Price Slider compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { HUNDRED, ZERO } from 'Component/ProductAttributes/ProductAttributes.config';
import { UPDATE_PRODUCT_LIST_INFO } from 'SourceStore/ProductListInfo/ProductListInfo.action';

const addMinAndMaxPrice = (args, callback) => (
    {
        ...callback(...args),
        minimumPrice: 0,
        maximumPrice: 0
    }
);

const updateMinMaxPrice = (args, callback) => {
    const result = callback(...args);

    const [, action] = args;

    const {
        type,
        products: {
            categoryPriceRange: {
                min,
                max
            } = {}
        } = {}
    } = action;

    if (type !== UPDATE_PRODUCT_LIST_INFO) {
        return result;
    }

    return {
        ...result,
        minimumPrice: (min === max || min < ZERO) ? ZERO : min,
        maximumPrice: max < HUNDRED ? HUNDRED : max
    };
};

export default {
    'Store/ProductListInfo/Reducer/getInitialState': {
        function: addMinAndMaxPrice
    },
    'Store/ProductListInfo/Reducer/ProductListReducer': {
        function: updateMinMaxPrice
    }
};
