import { UPDATE_AREAS } from './Areas.action';

/** @namespace Scandipwa/Store/Areas/Reducer/getInitialState */
export const getInitialState = () => ({
    areas: []
});

/** @namespace Scandipwa/Store/Areas/Reducer/AreasReducer */
export const AreasReducer = (
    state = getInitialState(),
    action
) => {
    const { type, areas } = action;

    switch (type) {
    case UPDATE_AREAS:
        return {
            ...state,
            areas
        };

    default:
        return state;
    }
};

export default AreasReducer;
