import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PopupStandard from './PopupStandard.component';

/** @namespace Scandipwa/Component/PopupStandard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/PopupStandard/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandipwa/Component/PopupStandard/Container */
export class PopupStandardContainer extends PureComponent {
    static propTypes = {
        renderTitle: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        hidePopupAndGoBack: PropTypes.func.isRequired,
        isWithCloseIcon: PropTypes.bool,
        // TODO: This should eventually be removed after all popups refactored
        renderHeader: PropTypes.func.isRequired,
        children: PropTypes.element.isRequired
    };

    static defaultProps = {
        isWithCloseIcon: true
    };

    containerFunctions = {};

    containerProps = () => {
        const {
            renderTitle,
            isMobile,
            children,
            renderHeader,
            hidePopupAndGoBack,
            isWithCloseIcon
        } = this.props;

        return {
            renderTitle,
            isMobile,
            renderHeader,
            children,
            hidePopupAndGoBack,
            isWithCloseIcon
        };
    };

    render() {
        return (
            <PopupStandard
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupStandardContainer);
