import { PropTypes } from 'prop-types';
import { createRef } from 'react';

import {
    IMAGE_LOADING,
    IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';
import {
    IMAGE_LOADED
} from 'SourceComponent/Image/Image.config';
import { noopFn } from 'Util/Common';

/** @namespace Scandipwa/Component/Image/Component */
export class ImageComponent extends SourceImage {
    static propTypes = {
        ...this.propTypes,
        isLazyLoaded: PropTypes.bool,
        onError: PropTypes.func,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        isLazyLoaded: true,
        onError: noopFn,
        width: '',
        height: ''
    };

    /**
     * Overridden to make sure that no text overflowing its container displays
     */
    renderMap = {
        ...this.renderMap,
        [IMAGE_NOT_FOUND]: this.renderPlaceholderImage.bind(this),
        [IMAGE_NOT_SPECIFIED]: this.renderPlaceholderImage.bind(this)
    };

    imgRef = createRef();

    /**
     * Overridden to handle case when placeholder src is provided
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        super.componentDidMount();

        this.handlePlaceholderSrc();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onError() {
        const { onError } = this.props;

        this.setState({ imageStatus: IMAGE_NOT_FOUND });
        onError();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onLoad() {
        const { onImageLoad } = this.props;

        if (onImageLoad) {
            onImageLoad();
        }

        this.setState({ imageStatus: IMAGE_LOADED });
    }

    /**
     * New method to load src separately
     * while using alternative placeholder src in the meantime
     * the will be alternative cached if we are coming from clicking on product card
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handlePlaceholderSrc() {
        const { placeholderSrc, src } = this.props;

        if (placeholderSrc) {
            const img = this.imgRef?.current;
            const newImg = new Image();

            // eslint-disable-next-line func-names
            newImg.onload = function () {
                img.src = this.src;
            };

            newImg.src = src;
        }
    }

    /**
     * Overridden to add image ref and change src to placeholder src
     */
    renderStyledImage() {
        const {
            alt,
            src,
            placeholderSrc,
            style,
            title,
            isLazyLoaded,
            currentSlide
        } = this.props;
        const { imageStatus } = this.state;

        return (
            <img
              ref={ this.imgRef }
              block="Image"
              elem="Image"
              src={ placeholderSrc || src || '' }
              alt={ alt }
              mods={ {
                  isLoading: imageStatus === IMAGE_LOADING
              } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={ isLazyLoaded ? 'lazy' : 'eager' }
              data-current-slide={ currentSlide }
            />
        );
    }

    /**
     * Overridden to add image ref and change src to placeholder src
     */
    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
            placeholderSrc,
            isLazyLoaded,
            currentSlide,
            width,
            height
        } = this.props;

        return (
            <img
              ref={ this.imgRef }
              block={ className }
              src={ placeholderSrc || src || '' }
              alt={ alt }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={ isLazyLoaded ? 'lazy' : 'eager' }
              data-current-slide={ currentSlide }
              width={ width }
              height={ height }
            />
        );
    }

    renderPlaceholderImage() {
        const {
            alt,
            style,
            title,
            className,
            currentSlide
        } = this.props;

        return (
            <img
              className={ className }
              alt={ alt }
              style={ style }
              title={ title }
              loading="lazy"
              data-current-slide={ currentSlide }
            />
        );
    }
}

export default ImageComponent;
