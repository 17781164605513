/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const ACCORDION_SHOW_MORE_CONTENT_TYPE = 'scandiweb_accordion_show_more';

export const ACCORDION_SHOW_MORE_SKELETON = [{
    name: 'BaseAccordionShowMore',
    type: 'div',
    children: [
        { name: 'ExpandableContent', type: 'div' }
    ]
}];

export const ACCORDION_SHOW_MORE_CONTENT_ELEMENT_INITIAL_CHILDREN_LENGTH = 1;
