/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */

import { App as SourceApp } from 'SourceComponent/App/App.component';

/** @namespace Scandipwa/Component/App/Component */
export class App extends SourceApp {
    renderUnStated(children) {
        return children;
    }

    renderSharedTransition() {
        return null;
    }
}

export default App;
