/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getProductClickPageData } from './page';

/** @namespace Scandiweb/Gtm/Data/List/getProductImpressionsData */
export const getProductImpressionsData = async (
    products,
    positions,
    forcedList,
    urlPathname,
    urlRewriteData,
    category,
    isSlide
) => {
    const { waitForCallback } = await import('../util/wait');

    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const { pageType: list } = await getProductClickPageData(
        urlPathname,
        urlRewriteData,
        category,
        products[0].currentCategory
    );
    // ^^^ Reuse page data as list information
    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const batchedImpressions = await products.map(async (product, index) => {
        const { getProductListEntryData } = await import('./product');

        // vvv To remove variant from productData
        // eslint-disable-next-line no-unused-vars
        const { variant, ...productData } = await getProductListEntryData(
            product, positions[index]
        );

        return {
            ...productData,
            list: forcedList || (isSlide ? `${list}_slider` : list)
        };
    });

    return {
        ecommerce: {
            currencyCode,
            impressions: await Promise.all(batchedImpressions)
        }
    };
};

/** @namespace Scandiweb/Gtm/Data/List/getProductClickData */
export const getProductClickData = async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category,
    isSlide
) => {
    const { pageType: list } = await getProductClickPageData(
        urlPathname,
        urlRewriteData,
        category,
        product?.currentCategory
    );
    // ^^^ Reuse page data as list information

    const { getProductListEntryData } = await import('./product');

    // vvv To remove variant from productData
    // eslint-disable-next-line no-unused-vars
    const { variant, ...productData } = await getProductListEntryData(
        product, position,
    );

    return {
        ecommerce: {
            click: {
                actionField: {
                    list: forcedList || (isSlide ? `${list}_slider` : list)
                },
                products: [productData]
            }
        }
    };
};
