/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const STORE_FINDER_HEADER_TAG = 'store-finder-our-stores';
export const STORE_DETAILS_HEADER_TAG = 'store-finder-details';
export const POSITION = 'position';
export const STORE_FINDER_PAGE_URL = '/stores';
export const STORE_FINDER_MEDIA_PATH = '/media/store_finder/stores/';

// vvv map config
// the perfect size is 25 x 41, but we can change it later.
export const MARKER_ICON_WIDTH = 25;
export const MARKER_ICON_HEIGHT = 41;
export const MARKER_ICON_CLASS_NAME = 'leaflet-div-icon';
export const MAP_PADDING = 27;
export const MAP_POPUP_PADDING = 50;
export const MAP_POPUP_OFFSET_X = 180;
export const MAP_POPUP_OFFSET_Y = 150;
export const MAP_POPUP_OFFSET_MOBILE_X = 0;
export const MAP_POPUP_OFFSET_MOBILE_Y = 100;
export const DETAILS_MAP_ZOOM_LEVEL = 18;
export const DETAILS_MAP_ANIMATION_DURATION = 0.8;

export const META_OBJECT_META_KEYWORD = 'stores';

// Auto resize columns height paremeters
export const MIN_HEIGHT_STORECARD_TITLE = 64;
export const MAX_TABLET_WIDTH = 1024;
export const WORKING_HOURS_POS = 6;
export const COLUMNS_AT_DESKTOP = 3;
export const COLUMNS_AT_TABLET = 2;
