/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PagebuilderElementType } from '../../type/Elements.type';
import CategoryTabsComponent from './CategoryTabs.component';

/** @namespace Scandiweb/AdobePageBuilder/Component/CategoryTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandiweb/AdobePageBuilder/Component/CategoryTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandiweb/AdobePageBuilder/Component/CategoryTabs/Container */
export class CategoryTabsContainer extends PureComponent {
    static propTypes = {
        elements: PagebuilderElementType.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    containerFunctions = {
        setActiveTab: this.setActiveTab.bind(this)
    };

    containerProps() {
        const { elements, isMobile } = this.props;
        const { activeTab } = this.state;

        return {
            elements,
            activeTab,
            isMobile
        };
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            activeTab: 0
        };
    }

    setActiveTab(activeTab) {
        this.setState({ activeTab });
    }

    render() {
        return (
            <CategoryTabsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTabsContainer);
