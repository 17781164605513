/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from '@scandipwa/scandipwa/src/util/Store';

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import { debounceCallback } from '../util/debounce';

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireAddToWishlistEvent */
export const fireAddToWishlistEvent = debounceCallback(
    async (newlyAddedWishlistItemsData = [], customerId) => {
    // ^^^ It accepts array for migration in the future
        waitForPriorityLoad().then(
            /** @namespace Scandiweb/Gtm/Event/Wishlist/fireAddToWishlistEvent/debounceCallback/waitForPriorityLoad/then */
            async () => {
                const { pushToDataLayer } = await import(
                    /* webpackMode: "lazy", webpackChunkName: "push-util" */
                    '../util/push'
                );
                const { GTM_EVENT_KEY_ADD_TO_WISHLIST } = await import(
                    /* webpackMode: "lazy", webpackChunkName: "events-util" */
                    '../util/events'
                );

                const {
                    default_display_currency_code: currency
                } = getStore().getState()?.ConfigReducer;

                newlyAddedWishlistItemsData.forEach(async (product) => {
                    pushToDataLayer({
                        event: GTM_EVENT_KEY_ADD_TO_WISHLIST,
                        customerId,
                        ecommerce: {
                            currency,
                            value: product.price * product.quantity,
                            items: {
                                ...product
                            }
                        }
                    });
                });
            }
        );
    }
);

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveItemFromWishlistEvent */
export const fireRemoveItemFromWishlistEvent = debounceCallback(
    async (newlyRemovedWishlistItemsData = [], customerId) => {
    // ^^^ It accepts array for migration in the future
        waitForPriorityLoad().then(
            /** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveItemFromWishlistEvent/debounceCallback/waitForPriorityLoad/then */
            async () => {
                const { pushToDataLayer } = await import(
                    /* webpackMode: "lazy", webpackChunkName: "push-util" */
                    '../util/push'
                );
                const { GTM_EVENT_KEY_REMOVE_FROM_WISHLIST } = await import(
                    /* webpackMode: "lazy", webpackChunkName: "events-util" */
                    '../util/events'
                );

                const {
                    default_display_currency_code: currency
                } = getStore().getState()?.ConfigReducer;

                newlyRemovedWishlistItemsData.forEach(async (product) => {
                    pushToDataLayer({
                        event: GTM_EVENT_KEY_REMOVE_FROM_WISHLIST,
                        customerId,
                        ecommerce: {
                            currency,
                            value: product.price * product.quantity,
                            items: {
                                ...product
                            }
                        }
                    });
                });
            }
        );
    }
);

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveAllWishlistItemsEvent */
export const fireRemoveAllWishlistItemsEvent = debounceCallback(async (customerId) => {
    // ! You can't use getStore() because we're in a reducer
    // vvv I'm using == because value can be 0 too but it's acceptable we only need undefined (backup plan) and null
    // vvv and I'm checking for null because in this case it's same as using !isSignedIn()
    if (customerId == null) {
        return;
    }

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveAllWishlistItemsEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST
            });
        }
    );
});

/** @namespace Scandiweb/Gtm/Event/Wishlist/addToWishlistEventHandler */
export const addToWishlistEventHandler = async (items = []) => {
    if (items.length < 1) {
        return;
    }

    const { getCustomerData } = await import('../data/customer');
    const { getNewlyAddedWishlistItemsData } = await import('../data/wishlist');

    const { customerId } = await getCustomerData();
    const newlyAddedWishlistItemsData = await getNewlyAddedWishlistItemsData(items);

    fireAddToWishlistEvent(newlyAddedWishlistItemsData, customerId);
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/removeItemFromWishlistEventHandler */
export const removeItemFromWishlistEventHandler = async (itemId, productsInWishlist, customerId) => {
    const { getRemovedItemFromWishlist } = await import('../data/wishlist');

    // ! You can't use getStore() because we're in a reducer
    const removedItem = await getRemovedItemFromWishlist(itemId, productsInWishlist);

    if (!removedItem.id) {
        return;
    }
    // ^^^ if the item is invalid or we didn't find it don't push the data

    fireRemoveItemFromWishlistEvent([removedItem], customerId);
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/wishlistUpdateHandler */
export const wishlistUpdateHandler = debounceCallback(async (newProductsInWishlist, productsInWishlist, customerId) => {
    const { getUpdatedWishlistItems } = await import('../data/wishlist');

    // ! You can't use getStore() because we're in a reducer
    const updatedItems = await getUpdatedWishlistItems(newProductsInWishlist, productsInWishlist);

    if (!updatedItems.length) {
        return;
    }

    updatedItems.forEach((item) => {
        // vvv positive mean we clicked on '+' button
        if (item.quantity > 0) {
            fireAddToWishlistEvent([item], customerId);
        }

        // vvv negative mean we clicked on '-' button
        if (item.quantity < 0) {
            fireRemoveItemFromWishlistEvent(
                [{ ...item, quantity: item.quantity * -1 }], customerId
                // ^^^ We need a positive number for quantity
            );
        }
    });
});
