import {
    DESKTOP,
    MOBILE
} from 'Util/Device';

export const MAXIMUM_SLIDE_ITEMS = 8;
export const MAXIMUM_SLIDE_ITEMS_LANDING_1440 = 3;
export const MAXIMUM_SLIDE_ITEMS_LANDING = 4;
export const CATEGORY_WIDTH_IN_PX = {
    [DESKTOP]: 160,
    [MOBILE]: 120,
    landing: 411,
    landingMobile: 252,
    affordable: 302,
    affordableMobile: 252,
    rows: 302
};
