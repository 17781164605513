/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/AmazonPayfort/Util/MyAccount/Util/addNewTabToTabsMap */
export const addNewTabToTabsMap = (tabMap, tab) => {
    const newTabMap = { ...tabMap }; // create a copy of the original tabMap object
    // eslint-disable-next-line no-restricted-syntax
    const [tabKey] = Object.keys(tab);

    newTabMap[tabKey] = tab[tabKey];

    // sort the keys of the newTabMap object by the section property
    const sortedKeys = Object.keys(newTabMap).sort((a, b) => newTabMap[a].section - newTabMap[b].section);

    // reassemble tabMap using sorted keys
    const sortedTabMap = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key of sortedKeys) {
        sortedTabMap[key] = newTabMap[key];
    }

    return sortedTabMap;
};
