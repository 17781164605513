/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';

import CategoryTabs from '../../component/CategoryTabs';
import { CATEGORY_TABS_CONTENT_TYPE, CATEGORY_TABS_SKELETON } from '../../component/CategoryTabs/CategoryTabs.config';

const addCategoryTabsReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: CATEGORY_TABS_CONTENT_TYPE },
        replace: (domNode) => (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                { createElement(CategoryTabs, {
                    elements: instance.toReactElements(
                        [domNode],
                        CATEGORY_TABS_SKELETON
                    )
                }) }
            </RenderWhenVisible>
        )
    }
]);

export default addCategoryTabsReplacementRule;
