/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const PRODUCT_WIDGET = 'product';
export const CATEGORY_WIDGET = 'category';
export const CART_WIDGET = 'cart';
export const MINICART_WIDGET = 'minicart';
export const WIDGET_CMS_TYPE = 'ClerkWidget';
export const HOMEPAGE_WIDGET = 'homepage';
export const BLOG_WIDGET = 'blog';
export const CLERK_PRODUCT_LIMIT = 12;
export const CLERK_EMAIL = 'email';
export const CLERK_WAITING_TIME_LIMIT_MS = 5000;
export const CLERK_LAST_RECOMMEND_TYPE = 'ClerkLastRecommendType';
export const CLERK_NOT_USE_CACHED = 'ClerkNotUseCached';

export const strategyMap = {
    'Best Cross Sell Products': 'recommendations/complementary',
    'Best Sellers': 'recommendations/popular',
    'Hot Products': 'recommendations/trending',
    'Newest Products': 'recommendations/new',
    'Best Sellers In Category': 'recommendations/category/popular',
    'Hot Products In Category': 'recommendations/category/trending',
    'Newest Products In Category': 'recommendations/category/new',
    'Best Alternative Products': 'recommendations/substituting',
    'Most Sold With': 'recommendations/most_sold_with',
    'Recommendations Based On Keywords': 'recommendations/keywords',
    'Visitor Recommendations': 'recommendations/visitor/complementary',
    'Visitor Alternatives': 'recommendations/visitor/substituting',
    'Visitor Click History': 'recommendations/visitor/history',
    'Recommendations Based On Orders': 'recommendations/customer/complementary',
    'Similar To Order History': 'recommendations/customer/substituting',
    'Customer Order History': 'recommendations/customer/history',
    'What Customers Look At Right Now': 'recommendations/currently_watched',
    'Recently Purchased Products': 'recommendations/recently_bought'
};

export const getterType = {
    'Best Cross Sell Products': 'products',
    'Best Sellers': null,
    'Hot Products': null,
    'Newest Products': null,
    'Best Sellers In Category': 'category',
    'Hot Products In Category': 'category',
    'Newest Products In Category': 'category',
    'Best Alternative Products': 'products',
    'Most Sold With': 'products',
    'Recommendations Based On Keywords': null,
    'Visitor Recommendations': null,
    'Visitor Alternatives': null,
    'Visitor Click History': null,
    'Recommendations Based On Orders': 'email',
    'Similar To Order History': 'email',
    'Customer Order History': 'email',
    'What Customers Look At Right Now': null,
    'Recently Purchased Products': null
};
