/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/*
 * disabled multiple eslint rules to match coding style of ScandiPWA Core
*/
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

import { MY_RETURNS, URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';

export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    'Route/MyAccount'
));

export const AmastyMyReturnsPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "paypal-returns" */
    '../../route/AmastyMyReturnsPage'
));

export const AmastyViewReturn = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "paypal-view-returns" */
    '../../route/AmastyViewReturn'
));

export const AmastyNewReturn = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "paypal" */
    '../../route/AmastyNewReturn'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
            <Route
              path={ withStoreRegex(`/${ URL_PREFIX }/account/history`) }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> }
            />
        ),
        position: 150
    },
    {
        component: (
            <Route
              path={ withStoreRegex(`/${ URL_PREFIX }/account/view/request/:requestId`) }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> }
            />
        ),
        position: 150
    },
    {
        component: (
            <Route
              path={ withStoreRegex(`/${ URL_PREFIX }/account/newreturn/order`) }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> }
            />
        ),
        position: 150
    }
];

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
