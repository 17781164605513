import {
    DESKTOP,
    MOBILE
} from 'Util/Device';

export const DEFAULT_SLIDE_ITEM_WIDTH_IN_PX = {
    [MOBILE]: 120,
    [DESKTOP]: 160
};

export const CRUMBS_OFFSET = 3;
