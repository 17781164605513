/**
 * GA4MeasurementProtocol frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const componentDidMount = (args, callback) => {
    callback(...args);

    const landingPageUrl = window.location.href;
    const { referrer } = document;

    if (!window.sessionStorage.getItem('landingPageUrl')) {
        window.sessionStorage.setItem('landingPageUrl', landingPageUrl);
    }

    if (!window.sessionStorage.getItem('referrer')) {
        window.sessionStorage.setItem('referrer', referrer);
    }
};

export default {
    'Scandipwa/Component/Router/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
