/**
 * Mageplaza Instagram Feed compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    DESKTOP,
    MOBILE
} from 'Util/Device';

export const INSTAGRAM_FEED_LU_TYPE = 'Mageplaza\\InstagramFeed\\Block\\Widget';
export const INSTAGRAM_FEED_CMS_TYPE = 'MageplazaInstagramFeed';
export const INSTAGRAM_FEED_IS_CUSTOM_DESIGN = '1';
export const INSTAGRAM_FEED_SHOW_CAPTION = '1';
export const INSTAGRAM_FEED_SHOW_POPUP = '1';

export const INSTAGRAM_FEED_MIN_CAPTION_SIZE = 10;
export const INSTAGRAM_FEED_MEDIA_TYPE_VIDEO = 'VIDEO';
export const INSTAGRAM_FEED_LAYOUT_MULTIPLE = 'multiple';
export const INSTAGRAM_FEED_LAYOUT_SINGLE = 'single';
export const INSTAGRAM_FEED_LAYOUT_OPTIMIZED = 'optimized';
export const INSTAGRAM_FEED_IMAGE_MAX_WIDTH = 100;

export const INSTAGRAM_FEED_POPUP_SLIDER = 'instagram_slider';

export const INSTAGRAM_FEED_SLIDES_TO_SHOW = {
    [DESKTOP]: 4,
    [MOBILE]: 1.25
};

export const MOBILE_INSTAGRAM_FEED_SLIDES_TO_SCROLL = 1;
