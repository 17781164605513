/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    DESKTOP,
    MOBILE
} from 'Util/Device';

export const REVIEWS_CONTENT_TYPE = 'scandiweb_reviews';

export const REVIEWS_SKELETON = [{
    name: 'Reviews',
    type: 'div',
    children: [
        {
            name: 'Review',
            type: 'div',
            isLoopParent: true
        }
    ]
}];

export const REVIEWS_SLIDER_SLIDES_TO_SHOW = {
    [DESKTOP]: 2,
    [MOBILE]: 1.25
};

export const MOBILE_REVIEWS_SLIDER_SLIDES_TO_SCROLL = 1;
