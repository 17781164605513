import {
    MenuIcon as SourceMenuIcon
} from 'SourceComponent/MenuIcon/MenuIcon.component';

import './MenuIcon.override.style';

/** @namespace Scandipwa/Component/MenuIcon/Component */
export class MenuIconComponent extends SourceMenuIcon {
    /**
     * Overridden to change menu icon
     */
    render() {
        return (
            <svg block="MenuIcon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M 2.5 15 H 17.5 V 13.25 H 2.5 V 15 Z
                     M 2.5 10.875 H 17.5 V 9.125 H 2.5 V 10.875 Z
                     M 2.5 5 V 6.75 H 17.5 V 5 H 2.5 Z"
                />
            </svg>
        );
    }
}

export default MenuIconComponent;
