import { GUEST_EMAIL } from 'Component/NotifyButton/NotifyButton.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { STOCKNOTIFICATION_ACTION_TYPE } from './StockNotification.action';

/** @namespace Scandipwa/Store/StockNotification/Reducer/getInitialState */
export const getInitialState = () => ({
    payload: BrowserDatabase.getItem(GUEST_EMAIL)
});

/** @namespace Scandipwa/Store/StockNotification/Reducer/StockNotificationReducer */
export const StockNotificationReducer = (state = getInitialState(), action) => {
    const { payload, type } = action;

    switch (type) {
    case STOCKNOTIFICATION_ACTION_TYPE:
        return payload;

    default:
        return state;
    }
};

export default StockNotificationReducer;
