/** @namespace AmastyImprovedSortingSpwa/Util/Sorting/Index/getDescendingAttributes */
export const getDescendingAttributes = (options) => {
    if (!options.length) {
        return [];
    }

    return options
        .filter(({ sortDirection }) => (sortDirection === 'DESC'))
        .map(({ id }) => id);
};
