/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const CATEGORY_TILE_CONTENT_TYPE = 'scandiweb_category_tile';

export const CATEGORY_TILE_SKELETON = [{
    name: 'CategoryTile',
    type: 'div',
    children: [
        {
            name: 'Link',
            type: 'a',
            children: [
                {
                    name: 'Image',
                    type: 'img'
                },
                {
                    name: 'Title',
                    type: 'span'
                }
            ]
        }
    ]
}];
