/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const CONTACT_FORM_CONTENT_TYPE = 'scandiweb_contactform';

export const CONTACT_FORM_SKELETON = [{
    name: 'ContactForm',
    type: 'div'
}];
