/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
import { render } from 'react-dom';

import App from 'Component/App';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import 'Style/main';

waitForPriorityLoad().then(
    /** @namespace Scandipwa/Render/waitForPriorityLoad/then */
    () => {
        if ('serviceWorker' in navigator) {
            const swUrl = '/service-worker.js';
            navigator.serviceWorker.register(swUrl, { scope: '/' });
        }

        if (window.metaHtml) {
            const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
            Object.values(doc?.head?.children || {}).forEach((node) => document.head.appendChild(node));
        }
    }
);

waitForPriorityLoad().then(
    /** @namespace Scandipwa/Render/waitForPriorityLoad/then */
    () => import('Style/base/_font.scss')
);

if (module.hot) {
    module.hot.accept();
}

function HotApp() {
    return <App />;
}

render(<HotApp />, document.getElementById('root'));
