import { HUNDRED, ZERO } from 'Component/ProductAttributes/ProductAttributes.config';
import { SHOULD_USE_STATE_FILTERS } from 'Route/CategoryPage/CategoryPage.config';
import {
    getInitialState as sourceGetInitialState,
    ProductListReducer as sourceProductListReducer
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import {
    UPDATE_PRODUCT_LIST_INFO,
    UPDATE_PRODUCT_LIST_INFO_FROM_CLERK
} from 'Store/ProductListInfo/ProductListInfo.action';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import { SET_LAST_APPLIED_FILTER } from './ProductListInfo.action';

/**
 * Overridden to skip adding to filters if attribute has "_bucket" text on the end of the name
 */
/** @namespace Scandipwa/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters) => filters.reduce((co, item) => {
    const {
        request_var: attribute_code,
        name: attribute_label,
        position: attribute_position,
        filter_items,
        is_boolean,
        has_swatch
    } = item;
    const isBucketAttribute = /_bucket$/.test(attribute_label);

    if (isBucketAttribute) {
        return co;
    }

    const { attribute_values, attribute_options } = filter_items.reduce((attribute, option) => {
        const { value_string } = option;
        const { attribute_values, attribute_options } = attribute;

        attribute_values.push(value_string);

        return {
            ...attribute,
            attribute_options: {
                ...attribute_options,
                [value_string]: option
            }
        };
    }, { attribute_values: [], attribute_options: {} });

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label,
            attribute_position,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
            is_boolean,
            has_swatch
        }
    };
}, {});

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/updateProductListInfo */
export const updateProductListInfo = (state, action) => {
    const {
        products: {
            filters: availableFilters = []
        } = {},
        selectedFilter: {
            customFilters = {}
        } = {}
    } = action;
    const {
        lastAppliedFilter,
        filters
    } = state;

    const key = Object.entries(customFilters)
        .reduce((key, [name, value]) => `${key}-${name}-${value}`.replace(' ', ''), '');

    if (!window.ProductListInfo) {
        window.ProductListInfo = {};
    }

    const cachedFilters = window.ProductListInfo[key] ?? null;
    const shouldUseStateFilters = BrowserDatabase.getItem(SHOULD_USE_STATE_FILTERS);

    if (cachedFilters && shouldUseStateFilters) {
        return {
            ...state,
            ...sourceProductListReducer(state, action),
            filters: cachedFilters
        };
    }

    const reducedAvailableFilters = reduceFilters(availableFilters);
    const prevFilter = filters[lastAppliedFilter] ?? null;
    const filter = reducedAvailableFilters[lastAppliedFilter] ?? null;

    if (prevFilter && filter && shouldUseStateFilters && key) {
        filter.attribute_options = prevFilter.attribute_options;
        filter.attribute_values = prevFilter.attribute_values;
    }

    if (key) {
        window.ProductListInfo[key] = reducedAvailableFilters;
    }

    if (!shouldUseStateFilters) {
        window.ProductListInfo = {};

        BrowserDatabase.setItem(true, SHOULD_USE_STATE_FILTERS);
    }

    return {
        ...state,
        ...sourceProductListReducer(state, action),
        filters: reducedAvailableFilters
    };
};

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/getInitialState */
export const getInitialState = () => ({
    lastAppliedFilter: null,
    ...sourceGetInitialState()
});

/**
 * Overridden to use reduceFilters on this file
 */
/** @namespace Scandipwa/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO: {
        return updateProductListInfo(state, action);
    }
    case SET_LAST_APPLIED_FILTER:
        const {
            lastAppliedFilter
        } = action;

        return {
            ...state,
            lastAppliedFilter
        };
    case UPDATE_PRODUCT_LIST_INFO_FROM_CLERK: {
        const {
            selectedFilter = {},
            filters,
            options: {
                args: {
                    sort: sortFields,
                    minPrice,
                    maxPrice
                }
            }
        } = action;

        return {
            ...state,
            filters: reduceFilters(filters),
            sortFields,
            minPrice: Math.floor(minPrice),
            maxPrice: Math.ceil(maxPrice),
            isLoading: false,
            selectedFilter,
            minimumPrice: (minPrice === maxPrice || minPrice < ZERO) ? ZERO : minPrice,
            maximumPrice: maxPrice < HUNDRED ? HUNDRED : maxPrice
        };
    }
    default:
        return sourceProductListReducer(state, action);
    }
};

export default ProductListReducer;
