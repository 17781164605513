/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-param-reassign */

import { Children, cloneElement } from 'react';

import {
    firePromoClickEvent, firePromoClickEventForCategorySlider, firePromoViewEvent, firePromoViewEventForCategorySlider
} from '../../event/banner';

export const BANNER_ON_SCROLL_DELAY_IN_MS = 30;
export const IN_VIEW_OFFSET = 80;

const isElementVisible = (element, isOnLoad = false) => {
    const bBox = element?.getBoundingClientRect();

    if (!bBox) {
        return false;
    }

    // this gets the vertical center coordinate of your element
    const elementCenter = bBox.top + (bBox.height / 2);
    // this gets the vertical center of the window (viewport)
    const windowCenter = window.innerHeight / 2;

    if (isOnLoad) {
        return Math.abs(windowCenter - elementCenter) <= IN_VIEW_OFFSET;
    }

    return Math.abs(windowCenter - elementCenter) <= windowCenter + IN_VIEW_OFFSET;
};

const addBannerObserver = (args, callback, instance) => {
    document.addEventListener('scroll', () => {
        setTimeout(() => {
            const { base, props } = instance;
            if (isElementVisible(base)) {
                if (props.sliderTitle) {
                    firePromoViewEventForCategorySlider(props);

                    return;
                }

                if (props.sliderTitle !== '') {
                    firePromoViewEvent(props);
                }
            }
        }, BANNER_ON_SCROLL_DELAY_IN_MS);
    }, { passive: true });

    window.addEventListener('load', () => {
        setTimeout(() => {
            const { base, props } = instance;
            if (isElementVisible(base, true)) {
                if (props.sliderTitle) {
                    firePromoViewEventForCategorySlider(props);

                    return;
                }

                if (props.sliderTitle !== '') {
                    firePromoViewEvent(props);
                }
            }
        }, BANNER_ON_SCROLL_DELAY_IN_MS);
    }, { passive: true });

    return callback(...args);
};

const addPromoClickData = (args, callback, instance) => {
    const [slide, i] = args;
    const {
        slide_link,
        mobile_image
    } = slide;

    const Component = callback(...args);

    if (mobile_image?.indexOf('null') !== -1) {
        return Component;
    }

    if (!slide_link) {
        return Component;
    }

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props,
            (child) => {
                if (child.to) {
                    child.parentProps = instance.props;
                    child.slide = slide;
                    child.slideIndex = i;

                    return child.children;
                }

                return child.children;
            }
        )
    );
};

const addPromoClickEvent = (args, callback, instance) => {
    const {
        parentProps,
        slide,
        slideIndex,
        isCategorySlider,
        sliderTitle
    } = instance.props;

    if (!parentProps && !slide && !slideIndex) {
        callback(...args);

        return;
    }

    if (isCategorySlider) {
        setTimeout(() => {
            firePromoClickEventForCategorySlider(sliderTitle, slide, slideIndex);
        }, BANNER_ON_SCROLL_DELAY_IN_MS);

        callback(...args);

        return;
    }

    setTimeout(() => {
        firePromoClickEvent(parentProps, slide, slideIndex);
    }, BANNER_ON_SCROLL_DELAY_IN_MS);

    callback(...args);
};

const addPromoClickDataForCategoriesSlider = (args, callback, instance) => {
    const [category, index] = args;
    const Component = callback(...args);
    const { sliderTitle } = instance.props;

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props,
            (child) => {
                const { props } = child.children;

                if (props.to) {
                    props.sliderTitle = sliderTitle;
                    props.slide = category;
                    props.slideIndex = index;
                    props.isCategorySlider = true;

                    return child.children;
                }

                return child.children;
            }
        )
    );
};

export default {
    'Component/SliderWidget/Component': {
        'member-function': {
            render: addBannerObserver,
            renderSlide: addPromoClickData
        }
    },
    'Scandipwa/Component/CategoriesSlider/Component': {
        'member-function': {
            render: addBannerObserver,
            renderCategory: addPromoClickDataForCategoriesSlider
        }
    },
    'Component/Link/Container': {
        'member-function': {
            handleLinkClick: addPromoClickEvent
        }
    }
};
