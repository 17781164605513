/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const FIXED_IMAGE_CONTENT_TYPE = 'scandiweb_fixedimage';

export const FIXED_IMAGE_SKELETON = [{
    name: 'FixedImage',
    type: 'div'
}];
