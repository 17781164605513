import { Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import HomeSlider from 'Component/SliderWidget';
import {
    WidgetFactory as SourceWidgetFactory
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';
import { convertNumberToBoolean } from 'Util/Common';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import {
    promoBannerImageHeight, promoBannerImageWidth,
    SLIDER
} from './WidgetFactory.config';

import './WidgetFactory.style.scss';

export const ProductListWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-product-list" */
    'Component/ProductListWidget'
));
export const NewProducts = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-new-product" */
    'Component/NewProducts'
));
export const RecentlyViewedWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
    'Component/RecentlyViewedWidget'
));

/** @namespace Scandipwa/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback.bind(this)
        }
    };

    renderSliderFallback() {
        const { sliderId } = this.props;
        if (sliderId === 1) {
            return <div block="HeaderFallback" elem="PromoBanner" />;
        }

        // This is the homepage-promo-banner-slider id, we need to calculate the height it will have after final render
        // for CLS purposes
        if (sliderId === 2) {
            const width = window.innerWidth;
            const height = Math.round((width * promoBannerImageHeight) / promoBannerImageWidth);

            return (<div block="WidgetFactory" elem="SliderPlaceholder" style={ { height, width } } />);
        }

        return (<div block="WidgetFactory" elem="SliderPlaceholder" />);
    }

    renderDefaultFallback() {
        return (<div block="WidgetFactory" elem="Placeholder" />);
    }

    /**
     * Overridden to pass additional props
     */
    renderContent() {
        const {
            type,
            sliderId = null,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            hasArrowContentWrapper,
            showMenu,
            pageSize
        } = this.props;

        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (!Widget) {
            return null;
        }

        return (
            <Suspense fallback={ fallback ? fallback() : this.renderDefaultFallback() }>
                <Widget
                  sliderId={ sliderId }
                  displayType={ displayType }
                  productsCount={ productsCount }
                  showPager={ showPager }
                  storeId={ storeId }
                  title={ title }
                  conditionsEncoded={ conditionsEncoded }
                  isArrowSmall={ convertNumberToBoolean(isArrowSmall) }
                  shouldRenderArrowOnMobile={ convertNumberToBoolean(shouldRenderArrowOnMobile) }
                  hasArrowBorder={ convertNumberToBoolean(hasArrowBorder) }
                  hasArrowBackground={ convertNumberToBoolean(hasArrowBackground) }
                  hasArrowContentWrapper={ convertNumberToBoolean(hasArrowContentWrapper) }
                  showMenu={ showMenu }
                  pageSize={ pageSize }
                  fallback={ fallback }
                />
            </Suspense>
        );
    }

    render() {
        return (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => this.renderFallback() }>
                <Suspense fallback={ this.renderFallback() }>
                    { this.renderContent() }
                </Suspense>
            </RenderWhenVisible>
        );
    }
}

export default WidgetFactoryComponent;
