import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Categories/Query */
export class CategoriesQuery {
    getQuery(options = {}) {
        this.options = options;

        return new Field('categories')
            .addArgument(...this._getConditionalArguments())
            .addField(this._getItemsField());
    }

    _getItemsField() {
        return new Field('items')
            .addFieldList(this._getDefaultFields());
    }

    _getConditionalArguments() {
        const { categoryIds } = this.options;

        if (categoryIds) {
            return ['filters', 'CategoryFilterInput', { ids: { in: categoryIds } }];
        }

        throw new Error(__('There was an error requesting the category'));
    }

    _getDefaultFields() {
        return [
            'id',
            'url',
            'name',
            'image',
            'url_key',
            'url_path',
            'is_active',
            'meta_title',
            'description',
            'canonical_url',
            'product_count',
            'meta_keywords',
            'default_sort_by',
            'meta_description',
            'landing_page',
            'display_mode',
            'is_anchor'
        ];
    }
}

export default new CategoriesQuery();
