/**
 * Amazon Payfort compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */

import {
    lazy, Suspense
} from 'react';
import { Route } from 'react-router-dom';

import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';
import { FIRST_SECTION } from 'Type/Account.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { PayfortPages } from '../../Payfort.config';
import { addNewTabToTabsMap } from '../../util/MyAccount.util';

export const MySavedCards = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-dashboard" */
    '../../component/StoredPayment'
));

export const CreditCardsIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-misc" */
    'Component/CreditCardsIcon'
));

export const withStoreRegex = (path) => window.storeRegexText?.concat(path);

export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

const addPayfortStoredPaymentsToTabMap = (originalMember) => addNewTabToTabsMap(originalMember, {
    [PayfortPages.STORED_PAYMENT]: {
        url: '/vault/cards/listaction',
        tabName: __('Credit cards'),
        section: FIRST_SECTION,
        isFullUrl: true,
        icon: <Suspense fallback={ null }><CreditCardsIcon /></Suspense>,
        order: 5,
        showHeading: false
    }
});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const addStoredPaymentsToRouter = (originalMember) => [
    ...originalMember,
    {
        // eslint-disable-next-line max-len, react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex('/vault/cards/listaction') } render={ (props) => <MyAccount { ...props } selectedTab={ PayfortPages.STORED_PAYMENT } /> } />,
        position: 69,
        name: PayfortPages.STORED_PAYMENT
    }
];

const addStoredPaymentsToRenderMap = (originalMember) => ({
    ...originalMember,
    [PayfortPages.STORED_PAYMENT]: MySavedCards
});

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: addPayfortStoredPaymentsToTabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addStoredPaymentsToRenderMap
        }
    },
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addStoredPaymentsToRouter
        }
    }
};
