Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/seo-suite/src/plugin/MetaComponent.plugin.js')]);
import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

import { DEFAULT_DESCRIPTION, META_DESCRIPTION, TITLE_META_NAME } from './Meta.config';

export * from 'SourceComponent/Meta/Meta.component';

/** @namespace Scandipwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    /**
     * removed default title render (whats left is vendor)
     */

    renderTitle() {
        const {
            title_prefix,
            title_suffix,
            title,
            metadata
        } = this.props;

        if (!metadata.length || metadata?.filter((meta) => (meta.name === TITLE_META_NAME)).length === 0) {
            metadata.push({ name: 'title' });
        }

        const [{ content }] = metadata?.filter((meta) => (meta.name === TITLE_META_NAME));
        const newTitle = content || title;
        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        return (
            <title>
                { `${ titlePrefix }${ newTitle }${ titleSuffix }` }
            </title>
        );
    }

    renderMetaTag() {
        const { metadata } = this.props;
        const isDesctiprionFound = metadata.find((metaObj) => metaObj.name === META_DESCRIPTION);

        return (
            <>
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                    <meta
                      key={ name || property }
                      name={ name }
                      property={ property }
                      content={ content }
                    />
                    );
                }) }
                { !isDesctiprionFound && <meta name={ META_DESCRIPTION } content={ DEFAULT_DESCRIPTION } /> }
            </>
        );
    }

    renderMeta() {
        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderMetaTag() }
            </>
        );
    }
}

export default MetaComponent;
