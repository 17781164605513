/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { appendWithStoreCode } from 'Util/Url';

export const HtmlTemplates = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "HtmlSitemap" */
    '../route/HtmlSitemap'
));

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ appendWithStoreCode('/sitemap') } component={ HtmlTemplates } />,
            position: 90
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
