/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { QUICK_VIEW_POPUP_ID } from 'Component/QuickViewPopup/QuickViewPopup.config';
import ProductListQuery from 'Query/ProductList.query';
import WishlistQuery from 'Query/Wishlist.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { removeItemFromWishlist } from 'Store/Wishlist/Wishlist.action';
import { isSignedIn } from 'Util/Auth/Token';
import { getCartId } from 'Util/Cart';
import history from 'Util/History';
import { ADD_TO_CART, getIndexedProduct } from 'Util/Product';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation, fetchQuery } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';
import { appendWithStoreCode } from 'Util/Url';

import { PayfortElements } from '../../../amazon-payfort/src/Payfort.config';
import ClerkProductIDsFromOrderQuery from '../query/ClerkProductIDsFromOrder.query';
import ClerkProductsQuery from '../query/ClerkProducts.query';
import { showPowerStepPopup } from './popup';

export const LoadMore = 20;

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "cart-dispatcher" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "wishlist-dispatcher" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Scandiweb/Clerk/Util/Handlers/sendClickEvent */
export const sendClickEvent = async (data) => {
    const {
        clerkTrackingConfig: {
            publicKey
        }
    } = window._clerkConfig;
    const payload = {
        key: publicKey,
        visitor: 'auto',
        ...data
    };

    const clerk = window.Clerk;

    if (!clerk) {
        return;
    }

    clerk(
        'call',
        'log/click',
        payload
    );
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkOpenProductUrl */
export const clerkOpenProductUrl = (event) => {
    // when data found, report event and open product. otherwise exit and allow anchor to work
    const anchor = event?.target?.closest('a');
    const { dataset: { clerkProductId: productId, clerkProductUrl: url } = {} } = anchor || {};
    const params = event?.split('|');
    const clerkUrl = params?.length ? url || params[0] : null;
    const clerkProductId = params?.length ? productId || params[1] : null;

    if (!clerkProductId || !clerkUrl) {
        return;
    }

    const { pathname, search = '' } = new URL(clerkUrl);
    const splitPathname = pathname.split('/');
    const productUrl = splitPathname[splitPathname.length - 1];
    const { dispatch } = getStore();

    sendClickEvent({
        product: clerkProductId
    });

    // Hide breadcrumbs after click on the product from search list overlay.
    // vvv Otherwise old breadcrumbs stay at the page for a while.
    dispatch(toggleBreadcrumbs(false));

    if (event.ctrlKey || event.metaKey) {
        window.open(appendWithStoreCode(productUrl), '_blank');
    } else {
        dispatch(toggleOverlayByKey(''));
        history.push({ pathname: appendWithStoreCode(productUrl), search });
    }

    if (event instanceof Event) {
        event.preventDefault();
    }
};

/** @namespace Scandiweb/Clerk/Util/Handlers/getClerkProduct */
export const getClerkProduct = async (productId, cart = false) => {
    const options = {
        args: {
            filter: {
                productID: productId
            }
        }
    };

    if (cart) {
        try {
            const { clerkProducts: { products } } = await fetchQuery(
                ClerkProductsQuery.getQuery([productId])
            );

            return getIndexedProduct(products[0]);
        } catch {
            return {};
        }
    }

    try {
        const { products: { items } } = await executeGet(
            prepareQuery([ProductListQuery.getQuery(options)]),
            'ClerkGetProduct',
            ONE_MONTH_IN_SECONDS
        );

        return getIndexedProduct(items[0]);
    } catch {
        return {};
    }
};

/** @namespace Scandiweb/Clerk/Util/Handlers/getWishlistItem */
export const getWishlistItem = (sku) => {
    const { productsInWishlist } = getStore().getState().WishlistReducer;

    if (!productsInWishlist) {
        return null;
    }

    // TODO: After new graphql will need to check by options
    return Object.values(productsInWishlist).find(
        ({ sku: wishlistSku }) => sku === wishlistSku
    );
};

/** @namespace Scandiweb/Clerk/Util/Handlers/isInWishlist */
export const isInWishlist = (magentoProduct) => {
    const [{ sku: productSku }] = magentoProduct;

    if (!productSku) {
        return false;
    }

    return !!getWishlistItem(productSku);
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkRemoveProductFromWishlist */
export const clerkRemoveProductFromWishlist = async (sku) => {
    const wishlistItem = getWishlistItem(sku);
    const { dispatch } = getStore();
    const { wishlist: { id } } = wishlistItem;

    try {
        await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(id));
    } catch (e) {
        return Promise.reject();
    }

    dispatch(removeItemFromWishlist(id));

    return Promise.resolve();
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkAddToWishlist */
export const clerkAddToWishlist = async (productId) => {
    const product = await getClerkProduct(productId);
    const { dispatch } = getStore();
    const wishlistId = getStore().getState().WishlistReducer?.id;
    const wishlistDispatcher = (await WishlistDispatcher).default;
    const { sku } = product;

    if (!isSignedIn()) {
        dispatch(showNotification('info', __('You must login or register to add items to your wishlist.')));

        return;
    }

    const magentoProduct = magentoProductTransform(
        ADD_TO_CART,
        product,
        1,
        [],
        [...[], ...[], ...[]],
    );

    if (!isInWishlist(magentoProduct)) {
        await wishlistDispatcher.addItemToWishlist(dispatch, {
            items: magentoProduct,
            wishlistId
        });

        return;
    }

    await clerkRemoveProductFromWishlist(sku);
};

/** @namespace Scandiweb/Clerk/Util/Handlers/getParameters */
export const getParameters = (search) => {
    const params = search?.replace('?', '')?.split('&');

    return params?.reduce((acc, param) => {
        const parameters = param.split('=');

        if (parameters.length === 1) {
            return acc;
        }

        return { ...acc, [parameters[0]]: parameters[1] };
    }, {}) || {};
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkAddToCart */
export const clerkAddToCart = async (productIdAndparentUrlAndParentId) => {
    const paramsArray = productIdAndparentUrlAndParentId.split(' ');
    const productId = paramsArray[0];
    const parentIncomingUrl = paramsArray[1] && paramsArray[1] !== 'undefined' // coming from clerk like this
        ? paramsArray[1]
        : null;

    const parentId = paramsArray[2] && paramsArray[2] !== 'undefined' // coming from clerk like this
        ? paramsArray[2]
        : null;

    const { search = '' } = parentIncomingUrl
        ? new URL(parentIncomingUrl)
        : {};

    const product = await getClerkProduct(parentId || productId, true);

    if (!Object.keys(product).length) {
        const { dispatch } = getStore();

        dispatch(showNotification('error', __('We are processing prices. Soon product will be available.')));

        return;
    }

    const {
        id,
        attributes
    } = product;

    window.clerkSelectedProduct = id;

    showPowerStepPopup(product);

    const cartDispatcher = (await CartDispatcher).default;
    const { dispatch } = getStore();
    const cartId = getCartId();

    const parameters = getParameters(search);
    const configurableOptions = Object.keys(parameters) ? transformParameters(parameters, attributes) : [];

    const products = magentoProductTransform(
        ADD_TO_CART,
        product,
        1,
        [],
        configurableOptions
    );

    await cartDispatcher.addProductToCart(dispatch, { products, cartId });
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkOpenSearch */
export const clerkOpenSearch = () => {
    const search = document.getElementById('search').value.trim().replace(/\s/g, ' ');

    history.push(appendWithStoreCode(`/search/${search}`));
};

/** @namespace Scandiweb/Clerk/Util/Handlers/getProductIdsFromOrder */
export const getProductIdsFromOrder = async (orderId) => {
    try {
        const { clerkProductIDsfromOrder } = await executeGet(
            prepareQuery([ClerkProductIDsFromOrderQuery.getQuery(orderId)]),
            'ClerkProductsFromOrder',
            ONE_MONTH_IN_SECONDS
        );

        return clerkProductIDsfromOrder;
    } catch {
        return [];
    }
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkChangePrice */
export const clerkChangePrice = () => {
    const min = document.getElementById('clerk-range-input');
    const max = document.getElementById('clerk-range-input-max');

    if (!min || !max) {
        return;
    }

    // eslint-disable-next-line new-cap
    window.Clerk('content', '#search-results', (content) => {
        content.param('filter', `price >= ${min.value} and price <= ${max.value}`);
    });
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkLoadMore */
export const clerkLoadMore = () => {
    // eslint-disable-next-line new-cap
    window.Clerk('content', '#search-results', (content) => {
        content.more(LoadMore);
    });
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkSortChanged */
export const clerkSortChanged = () => {
    const clerk = window.Clerk;

    const { value = '' } = document.getElementById('clerkSortOptions');
    const filter = value.split(':');

    if (!filter.length || !clerk) {
        return;
    }

    if (filter[0] === 'default' || !filter[1] === 'default') {
        clerk('content', '[data-target][data-query][data-template][data-clerk-content-id]', 'param', {
            order: null,
            orderby: null
        });

        clerk('on', 'rendered', '#search-results', (_content, _data) => {
            const option = document.getElementById('clerkSortOptions');

            if (!option) {
                return;
            }

            option.value = value;
        });

        return;
    }

    clerk('content', '[data-target][data-query][data-template][data-clerk-content-id]', 'param', {
        orderby: filter[0],
        order: filter[1]
    });

    clerk('on', 'rendered', '#search-results', (_content, _data) => {
        const option = document.getElementById('clerkSortOptions');

        if (!option) {
            return;
        }

        option.value = value;
    });
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkGetLinkTo */
export const clerkGetLinkTo = (product, url) => {
    const { state: { category = null } = {} } = history.location;

    return {
        pathname: url,
        state: { product, prevCategoryId: category },
        search: ''
    };
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkOpenQuickView */
export const clerkOpenQuickView = async (productIdAndparentUrlAndParentId) => {
    const paramsArray = productIdAndparentUrlAndParentId.split(' ');
    const productId = paramsArray[0];
    const parentUrl = paramsArray[1] !== 'undefined' // coming from clerk like this
        ? paramsArray[1]
        : null;

    const parentId = paramsArray[2] !== 'undefined' // coming from clerk like this
        ? paramsArray[2]
        : null;

    const product = await getClerkProduct(parentId || productId);
    const { id, sku, url } = product;
    const { dispatch } = getStore();

    dispatch(showPopup(QUICK_VIEW_POPUP_ID, {
        productID: id,
        productSKU: sku,
        linkTo: clerkGetLinkTo(product, parentUrl || url)
    }));
};

/** @namespace Scandiweb/Clerk/Util/Handlers/clerkOpenInstallment */
export const clerkOpenInstallment = (event, data) => {
    event.stopPropagation();
    event.preventDefault();
    const params = data.split('|');
    const productId = params[0];
    const price = params[1];
    const currency = getStore().getState().ConfigReducer.currency.base_currency_code;
    const { dispatch } = getStore();
    const isMobile = window.mobileCheck();

    const rest = {
        id: +productId,
        amount: +price,
        currency,
        type: 'product'
    };

    window.installmentPopupState = rest;

    const payload = isMobile ? { title: __('Installments') } : {};

    dispatch(showPopup(PayfortElements.INSTALLMENT_POPUP, payload));
    window.installmentPopupState = null;
};

/**
 * Copy from Clerk.js code
 */
/** @namespace Scandiweb/Clerk/Util/Handlers/colorNameToHex */
export const colorNameToHex = (color) => {
    const colors = {
        aliceblue: '#f0f8ff',
        'antique oak': '#5c3e29',
        antiquewhite: '#faebd7',
        aqua: '#00ffff',
        aquamarine: '#7fffd4',
        azure: '#f0ffff',
        beige: '#f5f5dc',
        bisque: '#ffe4c4',
        black: '#000000',
        blanchedalmond: '#ffebcd',
        blue: '#0000ff',
        blueviolet: '#8a2be2',
        brown: '#a52a2a',
        burlywood: '#deb887',
        cadetblue: '#5f9ea0',
        chartreuse: '#7fff00',
        chocolate: '#d2691e',
        coral: '#ff7f50',
        cornflowerblue: '#6495ed',
        cornsilk: '#fff8dc',
        crimson: '#dc143c',
        cyan: '#00ffff',
        darkblue: '#00008b',
        darkcyan: '#008b8b',
        darkgoldenrod: '#b8860b',
        darkgray: '#a9a9a9',
        darkgreen: '#006400',
        darkkhaki: '#bdb76b',
        darkmagenta: '#8b008b',
        grey: '#808080',
        darkolivegreen: '#556b2f',
        darkorange: '#ff8c00',
        darkorchid: '#9932cc',
        darkred: '#8b0000',
        darksalmon: '#e9967a',
        darkseagreen: '#8fbc8f',
        darkslateblue: '#483d8b',
        darkslategray: '#2f4f4f',
        darkturquoise: '#00ced1',
        darkviolet: '#9400d3',
        darkgrey: '#5a5a5a',
        darkbrown: '#5c4033',
        darkoak: '#6f5339',
        deeppink: '#ff1493',
        deepskyblue: '#00bfff',
        dimgray: '#696969',
        dodgerblue: '#1e90ff',
        firebrick: '#b22222',
        floralwhite: '#fffaf0',
        forestgreen: '#228b22',
        fuchsia: '#ff00ff',
        gainsboro: '#dcdcdc',
        ghostwhite: '#f8f8ff',
        gold: '#ffd700',
        goldenrod: '#daa520',
        gray: '#808080',
        green: '#008000',
        greenyellow: '#adff2f',
        honeydew: '#f0fff0',
        hotpink: '#ff69b4',
        'indianred ': '#cd5c5c',
        indigo: '#4b0082',
        ivory: '#fffff0',
        khaki: '#f0e68c',
        lavender: '#e6e6fa',
        lavenderblush: '#fff0f5',
        lawngreen: '#7cfc00',
        lemonchiffon: '#fffacd',
        lightblue: '#add8e6',
        lightcoral: '#f08080',
        lightcyan: '#e0ffff',
        lightgoldenrodyellow: '#fafad2',
        lightgrey: '#d3d3d3',
        lightgreen: '#90ee90',
        lightpink: '#ffb6c1',
        lightsalmon: '#ffa07a',
        lightseagreen: '#20b2aa',
        lightskyblue: '#87cefa',
        lightslategray: '#778899',
        lightsteelblue: '#b0c4de',
        lightyellow: '#ffffe0',
        lightbrown: '#c4a484',
        lime: '#00ff00',
        limegreen: '#32cd32',
        linen: '#faf0e6',
        magenta: '#ff00ff',
        maroon: '#800000',
        mediumaquamarine: '#66cdaa',
        mediumblue: '#0000cd',
        mediumorchid: '#ba55d3',
        mediumpurple: '#9370d8',
        mediumseagreen: '#3cb371',
        mediumslateblue: '#7b68ee',
        mediumspringgreen: '#00fa9a',
        mediumturquoise: '#48d1cc',
        mediumvioletred: '#c71585',
        midnightblue: '#191970',
        mintcream: '#f5fffa',
        cream: '#FFFDD0',
        mistyrose: '#ffe4e1',
        moccasin: '#ffe4b5',
        navajowhite: '#ffdead',
        navy: '#000080',
        natural: '#808080',
        oldlace: '#fdf5e6',
        olive: '#808000',
        olivedrab: '#6b8e23',
        orange: '#ffa500',
        orangered: '#ff4500',
        orchid: '#da70d6',
        palegoldenrod: '#eee8aa',
        palegreen: '#98fb98',
        paleturquoise: '#afeeee',
        palevioletred: '#d87093',
        papayawhip: '#ffefd5',
        peachpuff: '#ffdab9',
        peru: '#cd853f',
        pink: '#ffc0cb',
        plum: '#dda0dd',
        powderblue: '#b0e0e6',
        purple: '#800080',
        rebeccapurple: '#663399',
        red: '#ff0000',
        rosybrown: '#bc8f8f',
        royalblue: '#4169e1',
        saddlebrown: '#8b4513',
        salmon: '#fa8072',
        sandybrown: '#f4a460',
        seagreen: '#2e8b57',
        seashell: '#fff5ee',
        sienna: '#a0522d',
        silver: '#c0c0c0',
        skyblue: '#87ceeb',
        slateblue: '#6a5acd',
        slategray: '#708090',
        snow: '#fffafa',
        springgreen: '#00ff7f',
        steelblue: '#4682b4',
        tan: '#d2b48c',
        teal: '#008080',
        thistle: '#d8bfd8',
        tomato: '#ff6347',
        turquoise: '#40e0d0',
        violet: '#ee82ee',
        wheat: '#f5deb3',
        white: '#ffffff',
        whitesmoke: '#f5f5f5',
        yellow: '#ffff00',
        yellowgreen: '#9acd32',
        walnut: '#773f1a'
    };

    if (typeof colors[color.toLowerCase()] !== 'undefined') {
        return colors[color.toLowerCase()];
    }

    // Prevent code to be failed if color is missed
    return '#ffffff';
};
