/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { FIXED_IMAGE_CONTENT_TYPE, FIXED_IMAGE_SKELETON } from '../../component/FixedImage/FixedImage.config';

export const FixedImage = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "cms-misc" */
        '../../component/FixedImage'
    ),
);

const addFixedImageReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: FIXED_IMAGE_CONTENT_TYPE },
        replace: (domNode) => (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ () => <div /> }>
                <Suspense fallback={ <div /> }>
                    { createElement(FixedImage, {
                        elements: instance.toReactElements(
                            [domNode],
                            FIXED_IMAGE_SKELETON
                        )
                    }) }
                </Suspense>
            </RenderWhenVisible>
        )
    }
]);

export default addFixedImageReplacementRule;
