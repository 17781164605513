/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import { debounceCallback } from '../util/debounce';

/** @namespace Scandiweb/Gtm/Event/ErrorMessage/fireErrorMessageEvent */
export const fireErrorMessageEvent = debounceCallback(async (message) => {
    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/ErrorMessage/fireErrorMessageEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { getCustomerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "customer-util" */
                '../data/customer'
            );
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_ERROR_MESSAGE } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            const { customerId } = await getCustomerData();

            pushToDataLayer({
                event: GTM_EVENT_KEY_ERROR_MESSAGE,
                customerId,
                message
            });
        }
    );
});
