/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const TIMER_WRAPPER_CONTENT_TYPE = 'scandiweb_timerwrapper';
export const ONE_DAY = 24;
export const MILLISECONDS_IN_ONE_HOUR = 36e5;
export const TIMER_UPDATE_INTERVAL = 1000;

export const TIMER_WRAPPER_SKELETON = [{
    name: 'BaseTimerWrapper',
    type: 'div',
    children: [
        {
            name: 'Timer', type: 'span'
        },
        {
            name: 'Children', type: 'div'
        }
    ]
}];
