/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import CategorySlider from '../../component/CategorySlider';
import
{
    CATEGORY_SLIDER_CONTENT_TYPE,
    CATEGORY_SLIDER_SKELETON
} from '../../component/CategorySlider/CategorySlider.config';

const addReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: CATEGORY_SLIDER_CONTENT_TYPE },
        replace: (domNode) => (
            <Suspense fallback={ <div block="CmsPage" elem="CategorySliderPlaceholder" /> }>
                { createElement(CategorySlider, {
                    elements: instance.toReactElements(
                        [domNode],
                        CATEGORY_SLIDER_SKELETON
                    )
                }) }
            </Suspense>
        )
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
