import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ArrowIcon.style';

/** @namespace Scandipwa/Component/ArrowIcon/Component */
export class ArrowIconComponent extends PureComponent {
    static propTypes = {
        isShort: PropTypes.bool,
        isWhite: PropTypes.bool,
        isRight: PropTypes.bool
    };

    static defaultProps = {
        isShort: false,
        isWhite: false,
        isRight: false
    };

    render() {
        const { isShort, isWhite, isRight } = this.props;

        if (isRight) {
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33337 10H15" stroke="#1D1F1C" strokeWidth="2" />
                    <path
                      d="M10 4.16699L15.8333 10.0003L10 15.8337"
                      stroke="#1D1F1C"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                </svg>

            );
        }

        if (isShort) {
            return (
                <svg block="ArrowIcon" width="20" height="21" viewBox="0 0 20 21" fill="none" mods={ { isShort, isWhite } } xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33301 10.6667H14.9997" strokeWidth="2" />
                    <path d="M10 4.8335L15.8333 10.6668L10 16.5002" strokeWidth="2" strokeLinecap="square" />
                </svg>
            );
        }

        return (
            <svg block="ArrowIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.16602 5.34167L5.34102
                4.16667L14.166 12.9917V7.5H15.8327V15.8333H7.49935V14.1667H12.991L4.16602 5.34167Z"
                />
            </svg>

        );
    }
}

export default ArrowIconComponent;
