import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import { withReducers } from 'Util/DynamicReducer';

import { MAX_META_DESCRIPTION_LENGTH, PAGE_PARAMETER_NAMES } from './Meta.config';
/** @namespace Scandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    meta_title: state.MetaReducer.meta_title,
    currentPage: state.ProductListReducer?.currentArgs?.currentPage,
    filters: state.ProductListInfoReducer?.filters,
    selectedFilter: state.ProductListInfoReducer?.selectedFilter,
    pageType: state.UrlRewritesReducer?.urlRewrite?.type,
    storeCode: state.ConfigReducer?.store_code,
    productDescription: state.ProductReducer?.product?.description?.html,
    availableBrands: state.ConfigReducer?.brandsConfig?.brandDictionary
});

/** @namespace Scandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    containerProps() {
        const {
            filters,
            selectedFilter
        } = this.props;

        return {
            ...super.containerProps(),
            filters,
            selectedFilter
        };
    }

    /**
     * checks passed Mageworx settings and returns connected value and page number
     */
    combinePageParameter(pageParameter, value, option) {
        if (!value) {
            return '';
        }

        switch (option) {
        case PAGE_PARAMETER_NAMES.BEGINNING:
            return `${pageParameter} ${pageParameter ? '|' : ''} ${value}`;
        case PAGE_PARAMETER_NAMES.END:
            return `${value} ${pageParameter ? '|' : ''} ${pageParameter}`;
        default:
            return value;
        }
    }

    getAvailableBrandsUrlArray() {
        const {
            availableBrands = {}
        } = this.props;

        return Object.values(availableBrands)?.map(({ url, label }) => ({ url: /[^/]*$/.exec(url)[0], label })) || [];
    }

    getValidTitle() {
        const {
            title,
            categoryGender
        } = this.props;

        return (!categoryGender || title === categoryGender)
            ? title : `${title} - ${categoryGender}`;
    }

    /**
     * overridden to add page num to keywords accordingly to Mageworx settings
     */
    _getKeywords() {
        const {
            keywords,
            default_keywords,
            currentPage,
            seoConfigurationData: {
                addPageNumToMetaKeywords: addNumOption = 'off'
            } = { addPageNumToMetaKeywords: 'off' }
        } = this.props;
        // eslint-disable-next-line
        let newKeywords = keywords;
        const pageParameter = (currentPage && currentPage !== 1) ? `${__('Page')} ${currentPage}` : '';

        newKeywords = this.combinePageParameter(pageParameter, newKeywords, addNumOption);
        const newDefault_keywords = this.combinePageParameter(pageParameter, default_keywords, addNumOption);

        return newKeywords || newDefault_keywords;
    }

    /**
     * overridden to add page num to description accordingly to Mageworx settings
     */
    _getDescription() {
        const {
            description,
            default_description,
            currentPage,
            seoConfigurationData: {
                addPageNumToMetaDescription: addNumOption = 'off'
            } = { addPageNumToMetaDescription: 'off' }
        } = this.props;
        // eslint-disable-next-line
        let newDescription = description;
        const pageParameter = (currentPage && currentPage !== 1) ? `${__('Page')} ${currentPage}` : '';

        newDescription = this.combinePageParameter(pageParameter, newDescription, addNumOption);
        const newDefault_description = this.combinePageParameter(pageParameter, default_description, addNumOption);

        return newDescription || newDefault_description;
    }

    /**
     * overridden to add page num to title accordingly to Mageworx settings
     */
    _getTitle() {
        const {
            default_title,
            meta_title,
            currentPage,
            seoConfigurationData: {
                addPageNumToMetaTitle: addNumOption = 'off'
            } = { addPageNumToMetaTitle: 'off' }
        } = this.props;
        const pageParameter = (currentPage && currentPage !== 1) ? `${__('Page')} ${currentPage}` : '';
        // eslint-disable-next-line
        let validTitle = meta_title || this.getValidTitle();

        const newTitle = this.combinePageParameter(pageParameter, validTitle, addNumOption);
        const newDefault_title = this.combinePageParameter(pageParameter, default_title, addNumOption);

        return newTitle || newDefault_title;
    }

    /**
     * added product description as valid site description
     */
    _getMetadata() {
        const {
            productDescription
        } = this.props;

        const cleanProductDescription = productDescription
            ?.replace(/<\/?[^>]+(>|$)/g, '')
            ?.substring(0, MAX_META_DESCRIPTION_LENGTH);

        const meta = {
            title: this._getTitle(),
            description: this._getDescription() || cleanProductDescription,
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode()
        };

        return this._generateMetaFromMetadata(meta);
    }
}

export default withReducers({
    ProductListInfoReducer
})(connect(mapStateToProps, mapDispatchToProps)(MetaContainer));
