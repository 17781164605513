import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import Loader from 'Component/Loader';

import './LoaderPopup.style';

/** @namespace Scandipwa/Component/LoaderPopup/Component */
export class LoaderPopupComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    render() {
        const { isLoading } = this.props;

        return createPortal(
            <div
              block="LoaderPopup"
              mods={ { isVisible: isLoading } }
            >
                <Loader isLoading={ isLoading } />
            </div>,
            document.body
        );
    }
}

export default LoaderPopupComponent;
