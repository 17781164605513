/* eslint-disable no-magic-numbers */
import { getStore } from 'Util/Store';

export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_DAY_IN_SECONDS = 86400;
export const ONE_SECOND_IN_MS = 1000;
export const ONE_DAY_IN_HOURS = 24;
export const MINUTES_IN_SECONDS = 60;
export const ONE_HOUR_IN_MS = MINUTES_IN_SECONDS * MINUTES_IN_SECONDS * ONE_SECOND_IN_MS;
export const PURCHASE_BEFORE_DELIVERY_TIME = ONE_HOUR_IN_MS * 48;
export const PURCHASE_AFTER_DELIVERY_TIME = ONE_HOUR_IN_MS * 72;

export const SKIP_SUNDAY = [
    'uae_en',
    'uae_ar'
];

/** @namespace Scandipwa/Util/Date/Index/getDay */
export const getDay = (date, format) => new Intl.DateTimeFormat(format, { weekday: 'short' }).format(date);

/** @namespace Scandipwa/Util/Date/Index/getFullWeekDay */
export const getFullWeekDay = (date, format) => new Intl.DateTimeFormat(format, { weekday: 'long' }).format(date);

/** @namespace Scandipwa/Util/Date/Index/getMonth */
export const getMonth = (date, format) => new Intl.DateTimeFormat(format, { month: 'short' }).format(date);

/** @namespace Scandipwa/Util/Date/Index/getFullMonth */
export const getFullMonth = (date, format) => new Intl.DateTimeFormat(format, { month: 'long' }).format(date);

/** @namespace Scandipwa/Util/Date/Index/getDayOfMonth */
export const getDayOfMonth = (date, digitCount) => (`0${ (new Date(date)).getDate()}`).slice(digitCount * -1);

/** @namespace Scandipwa/Util/Date/Index/getMonthNumber */
export const getMonthNumber = (date, digitCount) => (`0${ (new Date(date)).getMonth() + 1}`).slice(digitCount * -1);
// ^^^ +1 because getMonth() return [0-11]

/** @namespace Scandipwa/Util/Date/Index/getFullYear */
export const getFullYear = (date) => (new Date(date)).getFullYear();

/** @namespace Scandipwa/Util/Date/Index/getFullDateInDigits */
export const getFullDateInDigits = (date, separator = '', monthDigitsCount, dayDigitsCount) => (
    // vvv why an array? first of all using '``' would make the string very long and ESLint would cry about it
    // vvv ... and the other reason i avoid using '+' was separator what if someone pass a number... in that case it would break the date.
    [
        getDayOfMonth(date, dayDigitsCount),
        getMonthNumber(date, monthDigitsCount),
        getFullYear(date)
    ].join(separator)
);

/** @namespace Scandipwa/Util/Date/Index/concatZeroIfNeeded */
// eslint-disable-next-line no-magic-numbers
export const concatZeroIfNeeded = (n) => (n < 10 ? `0${n}` : n);

/** @namespace Scandipwa/Util/Date/Index/getDaySuffix */
export const getDaySuffix = (day) => {
    /* eslint-disable no-magic-numbers */
    if (day === 1 || day === 21 || day === 31) {
        return 'st';
    } if (day === 2 || day === 22) {
        return 'nd';
    } if (day === 3 || day === 23) {
        return 'rd';
    }
    /* eslint-disable no-magic-numbers */

    return 'th';
};

/** @namespace Scandipwa/Util/Date/Index/createDateWithTime */
export const createDateWithTime = (currentDateInfo, daysToAdd = 0) => {
    const {
        year,
        month,
        day,
        hours,
        minutes,
        seconds
    } = currentDateInfo;

    const {
        ConfigReducer:
        {
            timezone
        } = {}
    } = getStore().getState() || {};

    return new Date(
        new Date(year, month, day + daysToAdd, hours, minutes, seconds)
            .toLocaleString('en-US', { timeZone: timezone })
    );
};

/** @namespace Scandipwa/Util/Date/Index/getIsDeliveryTimePast */
export const getIsDeliveryTimePast = (currentDate, deliveryDate) => currentDate > deliveryDate;

/** @namespace Scandipwa/Util/Date/Index/calculateRemainingTime */
export const calculateRemainingTime = (currentDate, deliveryDateInCurrentDay, deliveryDateInNextDay) => {
    const targetDeliveryDate = getIsDeliveryTimePast(currentDate, deliveryDateInCurrentDay)
        ? deliveryDateInNextDay
        : deliveryDateInCurrentDay;

    const targetHour = targetDeliveryDate.getHours();
    const currentHour = currentDate.getHours();
    const remainingHours = targetHour - currentHour;

    if (remainingHours <= 0) {
        return 0;
    }

    const remainingTimeInMs = remainingHours * ONE_HOUR_IN_MS;
    return Math.floor(remainingTimeInMs / ONE_SECOND_IN_MS);
};

/** @namespace Scandipwa/Util/Date/Index/getEstimatedDeliveryDateAndRemainingTime */
export const getEstimatedDeliveryDateAndRemainingTime = (shouldChangeDate = false) => {
    const {
        ConfigReducer: {
            timezone,
            code,
            orderBeforeXDeliveryConfig: {
                pan_order_before_x_delivery_time: panOrderBeforeXDeliveryTime = '00,00,00',
                pan_order_before_x_delivery_delivery_delay_in_day: panOrderBeforeXDeliveryDeliveryDelayInDay = 9,
                pan_order_before_x_delivery_collection_delivery_delay_in_day:
                    panOrderBeforeXDeliveryCollectionDeliveryDelayInDay = 2,
                pan_order_before_x_delivery_is_off_day_enabled: isPanOrderBeforeXDeliveryOffDayEnabled = false,
                pan_order_before_x_delivery_off_day: panOrderBeforeXDeliveryOffDay = 5
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                shipping_addresses: {
                    selected_shipping_method = {}
                } = {}
            } = {}
        } = {}
    } = getStore().getState() || {};

    const {
        carrier_code = ''
    } = selected_shipping_method || {};

    const isClickAndCollect = carrier_code === 'instore';
    const url = window.location.pathname;
    const isCartOrCheckout = url.indexOf('cart') !== -1 || url.indexOf('checkout') !== -1;

    const [beforeHour, beforeMinute, beforeSecond] = panOrderBeforeXDeliveryTime.split(',').map(Number);

    const currentDate = new Date(new Date().toLocaleString('en-US', { timeZone: timezone }));
    const currentTime = currentDate.getTime();

    const beforeTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        beforeHour,
        beforeMinute,
        beforeSecond
    ).getTime();

    const panOrderBeforeXDeliveryDeliveryDelayInDayTotal = code?.indexOf('ksa') !== -1
        ? 5
        : panOrderBeforeXDeliveryDeliveryDelayInDay;

    // eslint-disable-next-line fp/no-let
    let totalDelay = isClickAndCollect && isCartOrCheckout
        ? panOrderBeforeXDeliveryCollectionDeliveryDelayInDay
        : panOrderBeforeXDeliveryDeliveryDelayInDayTotal;

    // If the current time has passed the before time, increase delay by 1 day
    if (currentTime > beforeTime) {
        totalDelay++;
    }

    if (isPanOrderBeforeXDeliveryOffDayEnabled) {
        // eslint-disable-next-line fp/no-let
        for (let i = 0; i < totalDelay; i++) {
            const tempDate = new Date(
                new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + i)
                    .toLocaleString('en-US', { timeZone: timezone })
            );

            // If it's an off day, add an extra day to the delay
            if (tempDate.getDay() === panOrderBeforeXDeliveryOffDay) {
                totalDelay++;
            }
        }

        // vvv For KSA store if source is KSA only or UAE+KSA present
        if (shouldChangeDate) {
            totalDelay += 10;
        }

        const estimatedDay = (currentDate.getDay() + totalDelay) % 7;

        if (estimatedDay === panOrderBeforeXDeliveryOffDay) {
            totalDelay++;
        }
    }

    const currentDateInfo = {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        day: currentDate.getDate(),
        // Left it as it was, possibly a client requirement to have this time in estimated delivery date
        hours: beforeHour,
        minutes: beforeMinute,
        seconds: beforeSecond
    };

    const deliveryDateInCurrentDay = createDateWithTime(currentDateInfo);
    const deliveryDateInNextDay = createDateWithTime(currentDateInfo, totalDelay);

    const remainingTime = calculateRemainingTime(
        currentDate,
        deliveryDateInCurrentDay,
        deliveryDateInNextDay
    );

    const estimatedDeliveryDate = getIsDeliveryTimePast(currentDate, deliveryDateInCurrentDay)
        ? createDateWithTime(currentDateInfo, totalDelay)
        : deliveryDateInNextDay;

    return {
        estimatedDeliveryDate,
        remainingTime
    };
};
