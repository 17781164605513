Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/google-tag-manager/src/plugin/events/banner.plugin.js')]);
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ArrowIcon from 'Component/ArrowIcon';
import {
    CATEGORY_WIDTH_IN_PX,
    MAXIMUM_SLIDE_ITEMS,
    MAXIMUM_SLIDE_ITEMS_LANDING,
    MAXIMUM_SLIDE_ITEMS_LANDING_1440
} from 'Component/CategoriesSlider/CategoriesSlider.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import Slider from 'Component/Slider';
import SliderPerSlide from 'Component/SliderPerSlide';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { CategoryTreeType } from 'Type/Category.type';
import { LayoutType } from 'Type/Layout.type';

import './CategoriesSlider.style';

/** @namespace Scandipwa/Component/CategoriesSlider/Component */
export class CategoriesSliderComponent extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        activeLayoutType: LayoutType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isLandingPage: PropTypes.bool.isRequired,
        categoryImages: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            src: PropTypes.oneOf([PropTypes.number, PropTypes.string])
        })).isRequired,
        isAffordable: PropTypes.bool.isRequired,
        isSquareSlide: PropTypes.bool.isRequired,
        isSliderDisabled: PropTypes.bool.isRequired,
        isPremium: PropTypes.bool.isRequired,
        isHomePage: PropTypes.bool.isRequired,
        categoryTitles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string
        })).isRequired,
        categoryCustomUrls: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            customUrl: PropTypes.string
        })).isRequired,
        isLoading: PropTypes.bool.isRequired,
        slidesCount: PropTypes.number.isRequired,
        handleUrlClick: PropTypes.func.isRequired,
        isParentCategoryUsed: PropTypes.bool.isRequired,
        parentSliderCategory: CategoryTreeType
    };

    static defaultProps = {
        parentSliderCategory: []
    };

    renderSubcategorySliderItemFallback = this.renderSubcategorySliderItemFallback.bind(this);

    /**
     * New method to render image of categories slider
     */
    renderImage(category, index) {
        const {
            image = ''
        } = category;
        const {
            isLandingPage,
            isAffordable,
            isSquareSlide,
            isSliderDisabled
        } = this.props;

        if (typeof image !== 'string') {
            return (
                <div
                  block="CategoriesSlider"
                  elem="SliderImage"
                  mods={ {
                      isLandingPage,
                      isAffordable,
                      isSquareSlide,
                      isSliderDisabled
                  } }
                >
                    { image }
                </div>
            );
        }

        return (
            <div
              block="CategoriesSlider"
              elem="SliderImage"
              mods={ {
                  isLandingPage,
                  isAffordable,
                  isSquareSlide,
                  isSliderDisabled
              } }
            >
                <Image
                  key={ index }
                  src={ image }
                  ratio="custom"
                  mix={ {
                      block: 'CategoriesSlider',
                      elem: 'Image',
                      mods: {
                          isPlaceholder: !image,
                          isLandingPage,
                          isAffordable,
                          isSquareSlide,
                          isSliderDisabled
                      }
                  } }
                  isPlaceholder={ !image }
                />
            </div>
        );
    }

    /**
     * New method to render category on the slider slide
     * isLastSlide is needed for last slides to add additional margins to the end of the container for better spacing
     */
    renderCategory(category, index, isLastSlide = false) {
        const {
            activeLayoutType,
            isLandingPage,
            categoryImages = [],
            isAffordable,
            isSquareSlide,
            isSliderDisabled,
            isPremium,
            categoryTitles,
            isHomePage,
            categoryCustomUrls,
            handleUrlClick,
            category: parentSlider
        } = this.props;
        const {
            name,
            url,
            id,
            description,
            image,
            children_count = 0
        } = category;
        const customUrl = categoryCustomUrls?.find(
            ({ id: categoryId, customUrl }) => categoryId === id && customUrl
        )?.customUrl;
        const { image: parentCategoryImage = '' } = parentSlider.find(
            ({ id: parentId }) => parentId === id
        ) || {};

        const linkUrl = {
            pathname: customUrl || url,
            state: {
                category: id,
                categoryTitle: name,
                categoryDescription: description,
                categoryImage: parentCategoryImage || image,
                categoryChildrenCount: parseInt(children_count, 10)
            }
        };

        if (activeLayoutType === LIST_LAYOUT) {
            return (
                <Link
                  block="CategoriesSlider"
                  elem="Category"
                  mods={ { isListView: true } }
                  to={ linkUrl }
                  key={ id }
                  onClickBeforeLoader={ handleUrlClick }
                >
                    <div block="CategoriesSlider" elem="Title">{ name }</div>
                </Link>
            );
        }

        const categoryImage = categoryImages?.find(({ id: categoryId, src }) => categoryId === id && src);
        const customTitle = categoryTitles?.find(({ id: categoryId, title }) => categoryId === id && title)?.title;

        const newCategory = { ...category, image: categoryImage ? categoryImage.src : image };

        return (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={ this.renderCategorySliderFallback.bind(this) }>
                <Link
                  block="CategoriesSlider"
                  elem="Category"
                  mods={ {
                      isListView: activeLayoutType === LIST_LAYOUT,
                      isLandingPage,
                      isAffordable,
                      isHomePage,
                      isSquareSlide,
                      isSliderDisabled,
                      isLastSlide
                  } }
                  to={ linkUrl }
                  key={ id }
                  onClickBeforeLoader={ handleUrlClick }
                >
                    { this.renderImage(newCategory, index) }
                    <div
                      block="CategoriesSlider"
                      elem="Title"
                      mods={ {
                          isLandingPage,
                          isAffordable,
                          isSquareSlide,
                          isSliderDisabled,
                          isPremium,
                          isHomePage
                      } }
                    >
                        { customTitle || name }
                        { isLandingPage && <ArrowIcon isShort /> }
                    </div>
                </Link>
            </RenderWhenVisible>
        );
    }

    renderSlideItems() {
        const { category = [], parentSliderCategory, isParentCategoryUsed } = this.props;
        const { length: categoryLength } = category;

        if (isParentCategoryUsed) {
            return parentSliderCategory.map(
                (item, index) => this.renderCategory(item, index, categoryLength === index + 1)
            );
        }

        return category.map(
            (item, index) => this.renderCategory(item, index, categoryLength === index + 1)
        );
    }

    renderSlider() {
        const {
            isMobile,
            isLandingPage,
            isAffordable,
            isSliderDisabled,
            slidesCount
        } = this.props;

        if (isSliderDisabled) {
            return this.renderSlideItems();
        }

        const elem = isLandingPage ? 'SliderLanding' : 'Slider';
        const defaultWidth = 1440;
        const maxSlides = window.innerWidth <= defaultWidth && !isAffordable
            ? MAXIMUM_SLIDE_ITEMS_LANDING_1440
            : MAXIMUM_SLIDE_ITEMS_LANDING;

        const maxSlidesMobile = isLandingPage && isMobile ? 2 : null;

        if (isMobile && !isLandingPage) {
            return (
                <Slider
                  isActiveImageFromPropsUsed={ false }
                  isInfinite
                  isLandingPage={ isLandingPage }
                  slideItemToDisplay={ {
                      mobile: MAXIMUM_SLIDE_ITEMS,
                      desktop: isLandingPage ? MAXIMUM_SLIDE_ITEMS_LANDING : MAXIMUM_SLIDE_ITEMS
                  } }
                  slideItemWidth={ CATEGORY_WIDTH_IN_PX }
                  showArrows
                  isArrowsDisplayed
                  isArrowsBackgroundDisplayed
                  isPaginationDisplayed={ false }
                  mix={ {
                      block: 'CategoriesSlider',
                      elem
                  } }
                  arrowMods={ {
                      hasArrowBorder: !isMobile,
                      hasArrowBackground: isMobile
                  } }
                  isScrollable
                >
                    { this.renderSlideItems() }
                </Slider>
            );
        }

        return (
            <SliderPerSlide
              isHideArrowInSingleSlide
              hasArrowBorder={ !isMobile }
              hasArrowBackground={ isMobile }
              isNotInfiniteOnSingleSlide
              mix={ {
                  block: 'CategoriesSlider',
                  elem
              } }
              isInteractionDisabled={ !isMobile }
              slideItemToDisplay={ isLandingPage && {
                  mobile: MAXIMUM_SLIDE_ITEMS,
                  desktop: MAXIMUM_SLIDE_ITEMS_LANDING
              } }
              isInfinite
              showArrows
              slideItemWidth={ CATEGORY_WIDTH_IN_PX }
              isLandingPage={ isLandingPage }
              maximumTotalSlideItemPerSlide={ isLandingPage ? maxSlidesMobile || maxSlides : null }
              slidesCount={ slidesCount }
            >
                { this.renderSlideItems() }
            </SliderPerSlide>
        );
    }

    renderSubcategorySliderFallback() {
        return (
            <div block="CategoryPageFallback" elem="SubcategorySlider">
                { Array.from(
                    { length: 3 },
                    this.renderSubcategorySliderItemFallback
                ) }
            </div>
        );
    }

    renderSubcategorySliderItemFallback() {
        const { activeLayoutType } = this.props;

        return (
            <div
              block="CategoryPageFallback"
              elem="SubcategorySliderItem"
              mods={ { isList: activeLayoutType === 'list' } }
            />
        );
    }

    renderCategorySliderFallback() {
        const { isLoading, isSquareSlide } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="CategoriesSlider" elem="SliderFallback" mods={ { isSquareSlide } }>
                <div block="SliderFallback" elem="SlideItem" />
                <div block="SliderFallback" elem="SlideItem" />
                <div block="SliderFallback" elem="SlideItem" />
                <div block="SliderFallback" elem="SlideItem" />
            </div>
        );
    }

    render() {
        const {
            category = [],
            activeLayoutType,
            isLandingPage,
            isSquareSlide,
            isAffordable,
            isSliderDisabled,
            isHomePage,
            isLoading
        } = this.props;

        const isListView = activeLayoutType === LIST_LAYOUT;

        if (isLoading) {
            return this.renderCategorySliderFallback();
        }

        if (!category.length) {
            return null;
        }

        return (
            <div
              block="CategoriesSlider"
              mods={ {
                  isListView,
                  isLandingPage,
                  isSquareSlide,
                  isAffordable,
                  isSliderDisabled,
                  isHomePage
              } }
            >
                { this.renderSlider() }
            </div>
        );
    }
}

export default CategoriesSliderComponent;
