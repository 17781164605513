/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { DeviceType, getDeviceType } from 'Util/Device';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import { DL_VAL_NOT_APPLICABLE } from '../data/common';
import { getPageData } from '../data/page';
import { debounceCallback } from '../util/debounce';
import { GTM_LAST_STORE_VIEW } from '../util/events';
import { getLanguageNameByCode } from '../util/languageName';

/** @namespace Scandiweb/Gtm/Event/General/getDeviceProperty */
export function getDeviceProperty() {
    const deviceType = getDeviceType();

    switch (deviceType) {
    case DeviceType.MOBILE_PWA_IOS_APP:
        return 'mobile_pwa_ios_app';
    case DeviceType.MOBILE_PWA_ANDROID_APP:
        return 'mobile_pwa_android_app';
    case DeviceType.DESKTOP_PWA:
        return 'desktop_pwa';
    case DeviceType.IOS_WKWEBVIEW:
        return 'mobile_iOS_app';
    case DeviceType.ANDROID_WEBVIEW:
        return 'mobile_Android_app';
    case DeviceType.IOS:
    case DeviceType.ANDROID:
    case DeviceType.OTHER_MOBILE:
        return 'mobile_web';
    default:
        return 'desktop_web';
    }
}

/** @namespace Scandiweb/Gtm/Event/General/fireGeneralEvent */
export const fireGeneralEvent = debounceCallback(async (urlRewriteData) => {
    const pageData = await getPageData(urlRewriteData);

    // vvv If there is no page data don't dispatched the event
    if (!pageData) {
        return;
    }
    // ^^ one of the reasons we might not have data is when we have data from url rewrite and we need to...
    // ... skip the the event fired by GTMGeneralEventTracker to prevent firing to events (which one of them is incorrect)

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/General/fireGeneralEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { waitForCallback } = await import(
                /* webpackMode: "lazy", webpackChunkName: "wait-util" */
                '../util/wait'
            );

            await waitForCallback(() => getStore().getState().ConfigReducer?.code);
            await waitForCallback(() => getStore().getState().ConfigReducer?.locale);

            const { getCustomerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "customer-util" */
                '../data/customer'
            );

            const {
                userLoginState,
                customerId,
                customerEmail,
                customerHashedEmail,
                customerPhone,
                customerHashedPhone,
                userExistingCustomer,
                userLifetimeValue,
                userLifetimeOrders
            } = await getCustomerData();

            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_GENERAL } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            pushToDataLayer({
                event: GTM_EVENT_KEY_GENERAL,
                userLoginState,
                customerId,
                customerEmail,
                customerHashedEmail,
                customerPhone,
                customerHashedPhone,
                userExistingCustomer,
                userLifetimeValue,
                userLifetimeOrders,
                ...pageData,
                storeView: getStore().getState().ConfigReducer?.code || DL_VAL_NOT_APPLICABLE,
                language: getLanguageNameByCode(getStore().getState().ConfigReducer?.locale),
                device_type: getDeviceProperty()
            });

            window.gtmIsGeneralEventFired = true;

            BrowserDatabase.setItem(
                getStore().getState().ConfigReducer?.code || DL_VAL_NOT_APPLICABLE,
                GTM_LAST_STORE_VIEW
            );
        }
    );
});
