/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import {
    PureComponent
} from 'react';
import { connect } from 'react-redux';

import CategoriesSlider from 'Component/CategoriesSlider';
import CategoriesQuery from 'Query/Categories.query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery } from 'Util/Request';
import { ENGLISH_LOCALIZATION, getLocalization } from 'Util/Store';
import { getIsHomePage } from 'Util/Url';

import { PagebuilderElementType } from '../../type/Elements.type';
import { LANDING_PAGES } from './CategorySlider.config';

import './CategorySlider.style';

/** @namespace Scandiweb/AdobePageBuilder/Component/CategorySlider/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    baseUrl: state.ConfigReducer.base_url
});

/** @namespace Scandiweb/AdobePageBuilder/Component/CategorySlider/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AdobePageBuilder/Component/CategorySlider/Component */
export class CategorySliderComponent extends PureComponent {
    static propTypes = {
        elements: PagebuilderElementType.isRequired,
        baseUrl: PropTypes.string.isRequired
    };

    state = {
        categories: [],
        isLandingPage: false,
        categoryImages: [],
        isLoading: false
    };

    componentDidMount() {
        this.requestCategories();
    }

    getCategoryIds() {
        const {
            elements: {
                Categories: { childEleBag = [] } = {}
            } = {}
        } = this.props;
        const [children = []] = childEleBag;

        const defaultCategories = children.map(({
            props: {
                elements: {
                    Link: { propsBag = [] } = {}
                } = {}
            } = {}
        } = {}) => {
            const [linkProps] = propsBag;

            return linkProps?.href;
        });

        const landingPageCategories = children.map(({
            props: {
                children: {
                    props: {
                        elements: {
                            Link: { propsBag = [] } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = {}) => {
            const [linkProps] = propsBag;

            return linkProps?.href;
        });

        const isLandingPageEnabled = BrowserDatabase.getItem('config')?.storeConfig?.landingPageConfig?.isEnabled;

        const isLandingPage = (!Object.values(defaultCategories).find((item) => item) && this.getIsLandingPage())
            || (!Object.values(defaultCategories).find((item) => item) && getIsHomePage() && isLandingPageEnabled);

        this.setState({ isLandingPage });

        return Object.values(defaultCategories).find((item) => item) ? defaultCategories : landingPageCategories;
    }

    getIsLandingPage() {
        const { baseUrl } = this.props;

        const url = window.location.pathname;

        if (getIsHomePage()) {
            return true;
        }

        if (url === baseUrl) {
            return true;
        }

        const parts = url.split('/').filter((item) => item);

        if (LANDING_PAGES.includes(parts?.[parts?.length - 1])) {
            return true;
        }

        return false;
    }

    getCategoryImages() {
        const {
            elements: {
                Categories: {
                    childEleBag = []
                } = {}
            } = {}
        } = this.props;
        const [children = []] = childEleBag;

        const landingPageCategories = children.map(({
            props: {
                children: {
                    props: {
                        elements: {
                            Link: { propsBag = [] } = {},
                            CategorySlide: {
                                childEleBag: imageChildEleBag = []
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = {}) => {
            const [linkProps] = propsBag;

            if (!imageChildEleBag?.[0]?.length) {
                return {};
            }

            const imageBag = imageChildEleBag?.[0]?.find(({ props }) => props?.children?.props?.src) || {};
            const {
                props: {
                    children: {
                        props: {
                            src
                        } = {}
                    } = {}
                } = {}
            } = imageBag;

            return { id: linkProps?.href, src };
        });

        return landingPageCategories;
    }

    async requestCategories() {
        const categoryIds = this.getCategoryIds();
        const categoryImages = this.getCategoryImages();

        this.setState({ isLoading: true });

        const { categories: { items = [] } = {} } = await fetchQuery(
            CategoriesQuery.getQuery({ categoryIds })
        ) || {};

        if (!items?.length) {
            this.setState({ isLoading: false });

            return;
        }

        // vvv Sorting by position from admin panel
        items.sort((a, b) => categoryIds.indexOf(a.id) - categoryIds.indexOf(b.id));

        this.setState({
            categories: items,
            categoryImages,
            isLoading: false
        });
    }

    getTitle() {
        const {
            elements: {
                CategorySlider: {
                    propsBag: [{
                        'data-arabic-title': arabicTitle = ''
                    }] = []
                },
                Title: {
                    childEleBag: titleBag = ['']
                } = {},
                code = ''
            }
        } = this.props;

        return getLocalization(code) === ENGLISH_LOCALIZATION ? titleBag[0] : arabicTitle;
    }

    getIsSquareType() {
        const {
            elements: {
                CategorySlider: {
                    propsBag: [{
                        'data-type': squareType = ''
                    }] = []
                }
            }
        } = this.props;

        return !!squareType;
    }

    getSlidesCount() {
        const {
            elements: {
                CategorySlider: {
                    propsBag: [{
                        'data-slides-count': slidesCount = 0
                    }] = []
                }
            }
        } = this.props;

        return +slidesCount;
    }

    getIsSliderDisabled() {
        const {
            elements: {
                CategorySlider: {
                    propsBag: [{
                        'data-disable-slider': disableSlider = ''
                    }] = []
                }
            }
        } = this.props;

        return !!disableSlider;
    }

    getSlideTitles() {
        const {
            elements: {
                Categories: { childEleBag = [] } = {}
            } = {}
        } = this.props;
        const [children = []] = childEleBag;

        const landingPageCategories = children.map(({
            props: {
                children: {
                    props: {
                        elements: {
                            CategorySlide: { propsBag = [] } = {},
                            Link: { propsBag: linkBag = [] } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = {}) => {
            const [linkProps] = linkBag;
            const [mainProps] = propsBag;

            return { id: linkProps?.href, title: mainProps?.['data-custom-title'] };
        });

        return landingPageCategories;
    }

    getSlideCustomUrls() {
        const {
            elements: {
                Categories: { childEleBag = [] } = {}
            } = {}
        } = this.props;
        const [children = []] = childEleBag;

        const landingPageCategories = children.map(({
            props: {
                children: {
                    props: {
                        elements: {
                            CategorySlide: { propsBag = [] } = {},
                            Link: { propsBag: linkBag = [] } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = {}) => {
            const [linkProps] = linkBag;
            const [mainProps] = propsBag;

            return { id: linkProps?.href, customUrl: mainProps?.['data-customUrl'] };
        });

        return landingPageCategories;
    }

    renderTitle() {
        const { isLandingPage } = this.state;
        const pbTitle = this.getTitle();
        const title = pbTitle || __('Related categories');

        return (
            <h2
              block="CategorySlider"
              elem="Title"
              mods={ { Hidden: isLandingPage } }
            >
                { title }
            </h2>
        );
    }

    getClosestTitle() {
        const list = this.base?.closest('.widget')?.querySelectorAll('strong') || [];
        const parent = this.base?.closest('.widget')?.parentNode;
        const sliderTop = this.base?.getBoundingClientRect()?.top;

        if (!this.base) {
            return null;
        }

        const elementsTop = Array.from(list, (element) => {
            const elementTop = element.getBoundingClientRect().top;

            return { [elementTop]: element };
        });

        if (elementsTop.length === 1) {
            return Object.values(elementsTop[0])[0]?.innerText;
        }

        const filteredTitles = elementsTop?.filter((element) => {
            const pos = Object.keys(element)[0];

            return Math.abs(pos) > Math.abs(sliderTop);
        });

        if (filteredTitles.length === 1) {
            return Object.values(filteredTitles[0])[0]?.innerText;
        }

        const positions = filteredTitles.map((element) => Object.keys(element)[0]);
        const closestPos = Math.max(...positions);
        const closestElement = filteredTitles.find((element) => element[closestPos]) || {};

        const strongText = Object.values(closestElement)?.[0]?.innerText;

        return parent?.getAttribute('class')?.split(' ')?.[0] || strongText;
    }

    render() {
        const {
            categories,
            isLandingPage,
            categoryImages,
            isLoading
        } = this.state;

        return (
            <div block="CategorySlider" mods={ { isLandingPage } }>
                { this.renderTitle() }
                <CategoriesSlider
                  category={ categories }
                  categoryImages={ categoryImages }
                  isLandingPage={ isLandingPage }
                  isSquareSlide={ this.getIsSquareType() }
                  isSliderDisabled={ this.getIsSliderDisabled() }
                  categoryTitles={ this.getSlideTitles() }
                  categoryCustomUrls={ this.getSlideCustomUrls() }
                  isLoading={ isLoading }
                  sliderTitle={ this.getClosestTitle() }
                  slidesCount={ this.getSlidesCount() }
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySliderComponent);
