/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getClerkProduct } from '@scandiweb/clerk/src/util/handlers';

import { appendWithStoreCode } from 'Util/Url';

import { ProductListEntryTracker } from '../../component/ProductListEntryTracker';
import { DL_VAL_PAGE_SEARCH } from '../../data/page';
import { handleClickEvent } from '../../util/handlers';

const addProductCardImpressionObserver = (args, callback, instance) => (
    <ProductListEntryTracker product={ instance.props.product }>
        { callback(...args) }
    </ProductListEntryTracker>
);

const addSearchItemImpressionObserver = (args, callback, instance) => (
    <ProductListEntryTracker
      product={ instance.props.product }
      list={ DL_VAL_PAGE_SEARCH }
    >
        { callback(...args) }
    </ProductListEntryTracker>
);

const addProductCardClick = (args, callback, instance) => {
    const [event] = args;
    handleClickEvent(event, instance.props);

    callback(...args);
};

const addSearchOverlayObserver = (args, callback) => {
    const clerk = window.Clerk;

    if (!clerk) {
        callback(...args);

        return;
    }

    clerk('on', 'rendered', () => {
        const list = document.getElementsByClassName('clerkItemWrapper');
        Array.from(list).forEach((element) => element.addEventListener('click', async (e) => {
            const parent = e.target.closest('.clerkItemWrapper');
            const { href } = parent.querySelector('a');
            const productId = parent.querySelector('a').getAttribute('data-clerk-product-id');
            const to = new URL(href);
            const splitPathname = to.pathname.split('/');
            const productUrl = splitPathname[splitPathname.length - 1];
            to.pathname = appendWithStoreCode(productUrl);

            const product = await getClerkProduct(productId);

            const state = {
                product
            };

            to.state = state;

            handleClickEvent(e, { to, isSearchOverlay: true });
        }));
    });

    callback(...args);
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            render: addProductCardImpressionObserver
        }
    },
    'Component/Link/Container': {
        'member-function': {
            handleLinkClick: addProductCardClick
        }
    },
    'Component/SearchItem/Component': {
        'member-function': {
            render: addSearchItemImpressionObserver
        }
    },
    'Component/Header/Container': {
        'member-function': {
            onSearchBarChange: addSearchOverlayObserver
        }
    }
};
