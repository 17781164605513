/**
 * Microsoft Advertising Tag compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

// vvv Added a component to use location hook
function MatPageViewEventTracker() {
    const location = useLocation();

    useEffect(() => {
        waitForPriorityLoad().then(
            async () => {
                const { firePageView } = await import('../event/pageView');

                firePageView();
            }
        );
    }, [location.pathname]);

    return null;
}

const addMatPageViewEventTracker = (args, callback) => (
    <>
        { callback(args) }
        <MatPageViewEventTracker />
    </>
);

export default {
    'Scandipwa/Component/Router/Component': {
        'member-function': {
            renderRouterContent: addMatPageViewEventTracker
        }
    }
};
