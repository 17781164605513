import {
    SliderQuery as SourceSliderQuery
} from 'SourceQuery/Slider.query';

/** @namespace Scandipwa/Query/Slider/Query */
export class SliderQuery extends SourceSliderQuery {
    /**
     * Overridden to add show_navigation, is_active and show_menu fields
     */
    _getSliderFields() {
        return [
            this._getSlidesField(),
            this._getSlideSpeedField(),
            'slider_id',
            'title',
            'show_navigation',
            'is_active',
            'show_menu'
        ];
    }

    /**
     * Overridden to query additional fields
     */
    _getSlideFields() {
        return [
            ...super._getSlideFields(),
            'slide_link',
            'desktop_image_2',
            'desktop_image_3',
            'mobile_image_2',
            'mobile_image_3',
            'slide_text_2',
            'slide_text_3',
            'slide_link_2',
            'slide_link_3'
        ];
    }
}

export default new SliderQuery();
