import {
    VALIDATION_INPUT_TYPE as SOURCE_VALIDATION_INPUT_TYPE,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

/**
 * Overridden to add VALIDATION_INPUT_TYPE.steetAddress property
 */
export const VALIDATION_INPUT_TYPE = {
    ...SOURCE_VALIDATION_INPUT_TYPE,
    streetAddress: 'streetAddress'
};

/**
 * Overridden to change VALIDATION_INPUT_TYPE.phone and streetAddress property value
 * to have different configuration for different country code
 */
export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    // Inputs
    [VALIDATION_INPUT_TYPE.phone]: {
        971: /^0?5[0-9]{8}$/im,
        974: /^(3|5|6|7)[0-9]{7}$/im,
        968: /^(7|9)[0-9]{7}$/im,
        966: /^0?5[0-9]{8}$/im
    },
    [VALIDATION_INPUT_TYPE.streetAddress]: /^[a-zA-Z0-9\s.,-]+$/
};

/**
 * Overridden to add VALIDATION_INPUT_TYPE.steetAddress validation message and modify VALIDATION_INPUT_TYPE.phone validation message
 */
export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.streetAddress]: __('Address Can only contain dashes, commas and dots.'),
    [VALIDATION_INPUT_TYPE.phone]: __('Incorrect phone input! Only numbers allowed!')
};
