/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { createElement, Suspense } from 'react';

import ArrowIcon from 'Component/ArrowIcon';
import ChevronIcon from 'Component/ChevronIcon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Slider from 'Component/Slider';
import WidgetFactory from 'Component/WidgetFactory';
import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';
import { isMobile } from 'Util/Mobile';
import { fixPageBuilderStyleImage, PAGEBUILDER_EXCLUDE_TAGS } from 'Util/PageBuilder';
import { AfterPriority, setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { getCloudflareOptimizationImage } from 'Util/Resize';
import getStore from 'Util/Store';

import { CATEGORY_SLIDER_CONTENT_TYPE } from '../../../packages/adobe-page-builder/src/component/CategorySlider/CategorySlider.config';
import { FIXED_IMAGE_CONTENT_TYPE } from '../../../packages/adobe-page-builder/src/component/FixedImage/FixedImage.config';

export const PAGEBUILDER_MOBILE_ONLY_CLASS = 'pagebuilder-mobile-only';
export const PAGEBUILDER_DESKTOP_ONLY_CLASS = 'pagebuilder-mobile-hidden';
export const PAGEBUILDER_HIDDEN_CLASS = 'pagebuilder-hidden';

/** @namespace Scandipwa/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    static propTypes = {
        ...this.propTypes,
        isContentAfterProductDetails: PropTypes.bool,
        fallback: PropTypes.func,
        isPriorityContent: PropTypes.bool,
        onImageLoad: PropTypes.func
    };

    static defaultProps = {
        isContentAfterProductDetails: false,
        isPriorityContent: false
    };

    isPriorityLoading= false;

    firstPriorityLoading = false;

    lastBlock = null;

    rules = [
        ...this.rules,
        {

            query: { attribs: [{ 'data-element': 'video' }] },
            replace: this.replaceYoutubeVideo
        },
        {
            query: { attribs: [{ class: 'pagebuilder-column-line' }] },
            replace: this.replacePageBuilderColumnLine
        },
        {
            query: { attribs: [{ 'data-content-type': 'html' }] },
            replace: this.replacePageBuilderBlock
        },
        {
            query: { attribs: [{ 'data-content-type': 'row' }] },
            replace: this.replacePageBuilderBlock
        },
        {
            query: { attribs: [{ 'data-content-type': 'text' }] },
            replace: this.replacePageBuilderBlock
        },
        {
            query: { attribs: [{ class: 'visible-' }] },
            replace: this.replaceVisibilityByDevice
        }
    ];

    componentWillUnmount() {
        if (window.isPrefetchValueUsed) {
            window.isPrefetchValueUsed = false;
        }
    }

    parserOptions = {
        replace: (domNode) => {
            const {
                data,
                name: domName,
                attribs: domAttrs,
                parent: domParent,
                next,
                children
            } = domNode;
            const { isPriorityContent } = this.props;

            if (
                (domName && domName.indexOf('imgid') !== -1)
                || (domName && domName.indexOf('imgsrc') !== -1)
            ) {
                return <></>;
            }

            if (isPriorityContent) {
                if (!domParent && !next && !this.lastBlock) {
                    if (children.length) {
                        this.lastBlock = this.getLastRenderElement(children[children.length - 1]);
                    } else {
                        this.lastBlock = this.getLastRenderElement(children[children.length - 1]);
                    }
                }

                if (this.lastBlock === domNode && !this.isPriorityLoading) {
                    setLoadedFlag();
                }
            }

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;
                return replace.call(this, domNode);
            }
        }
    };

    getLastRenderElement(lastChildren) {
        const { children = [] } = lastChildren || {};
        if (children.length) {
            return this.getLastRenderElement(children[children.length - 1]);
        }

        return lastChildren;
    }

    blockContainsSlider(domNode) {
        if (domNode.name === 'widget') {
            const {
                attribs: {
                    type
                } = {}
            } = domNode;

            if (type === 'Slider') {
                return true;
            }
        }

        if (domNode.children) {
            // eslint-disable-next-line no-restricted-syntax
            for (const child of domNode.children) {
                if (this.blockContainsSlider(child)) {
                    return true;
                }
            }
        }

        return false;
    }

    elementIsHidden(element) {
        const {
            attribs: {
                class: className,
                'data-content-type': dataContentType = ''
            } = {},
            parent
        } = element;
        const {
            ConfigReducer: {
                device: {
                    isMobile
                } = {}
            } = {}
        } = getStore().getState();

        const hiddenDesktop = className?.includes(PAGEBUILDER_MOBILE_ONLY_CLASS) && !isMobile; // should only appear on mobile but screen is desktop
        const hiddenMobile = className?.includes(PAGEBUILDER_DESKTOP_ONLY_CLASS) && isMobile; // should only appear on desktop but screen is mobile
        const hidden = className?.includes(PAGEBUILDER_HIDDEN_CLASS); // should always hide
        const lowPriorityContent = [FIXED_IMAGE_CONTENT_TYPE, CATEGORY_SLIDER_CONTENT_TYPE];

        // Image should be hidden or of type FIXED_IMAGE_CONTENT_TYPE (Low priority)
        if (hiddenDesktop || hiddenMobile || hidden || lowPriorityContent.includes(dataContentType)) {
            return true;
        }

        if (!parent) {
            return false;
        }

        return this.elementIsHidden(parent);
    }

    /**
     * Priority block is only the first block that has priority element.
     */
    blockContainsPriority(element) {
        if (this.elementIsPriority(element)) {
            return true;
        }

        const { children } = element;
        if (Array.isArray(children)) {
            // eslint-disable-next-line fp/no-loops, fp/no-let
            for (let i = 0; i < children.length; i++) {
                if (this.blockContainsPriority(children[i])) {
                    return true;
                }
            }
        }

        return false;
    }

    elementIsPriority(element) {
        const { attribs = {}, name } = element;
        const {
            actionName: {
                slider: { slider_id: preloadedSliderId } = {}
            } = {},
            location: { pathname },
            storeList
        } = window;
        const path = pathname.replace(/^\/+|\/+$/g, '');
        const isHomePage = path === '' || storeList.includes(path);

        // Homepage check
        if (isHomePage && preloadedSliderId) {
            const { type, slider_id } = attribs;
            if (type === 'Slider' && slider_id === preloadedSliderId) {
                return true;
            }

            return false;
        }

        // Other checks, i.e: blog post.
        if (
            (isMobile.any() && attribs['data-element'] === 'mobile_image')
            || (!isMobile.any() && attribs['data-element'] === 'desktop_image')
            || name === 'img'
        ) {
            return true;
        }

        return false;
    }

    /**
     * Cases to pass:
     * + Html component used for non priority content
     * + Block has priority element
     * + No priority element has reached yet
     */
    replacePageBuilderBlock(domNode) {
        const { isPriorityContent } = this.props;
        const {
            attribs,
            children
        } = domNode;

        const shouldUseFullWidth = children?.find(
            ({ attribs }) => attribs?.class?.indexOf('no-wrapper') !== -1
        );

        const newAttribs = shouldUseFullWidth ? { ...attribs, 'data-width': 'no-wrapper' } : attribs;

        const [element] = children;
        const shoudUseArrowForSubcategory = element?.attribs?.class && element?.attribs?.class?.indexOf('-subcategory') !== -1;
        const shouldUseViewAll = element?.attribs?.class && element?.attribs?.class?.indexOf('product-promo-title-wrapper') !== -1;

        if (shouldUseViewAll) {
            const url = element?.attribs?.['data-url'];

            const content = (
                <Link
                  block="AffordablePromoTitle"
                  to={ url }
                >
                    <div { ...this.attributesToProps(newAttribs) }>
                        { domToReact(children, this.parserOptions) }
                    </div>
                    <ContentWrapper>
                    <div block="AffordablePromoTitle" elem="Arrow">
                    { __('View all') }
                        <ChevronIcon />
                    </div>
                    </ContentWrapper>
                </Link>
            );

            return content;
        }

        const content = shoudUseArrowForSubcategory
            ? (
            <div { ...this.attributesToProps(newAttribs) }>
                { domToReact(children, this.parserOptions) }
                <ArrowIcon isShort />
            </div>
            )
            : (
            <div { ...this.attributesToProps(newAttribs) }>
                { domToReact(children, this.parserOptions) }
            </div>
            );

        if (!isPriorityContent) {
            return content;
        }

        if (this.blockContainsPriority(domNode)) {
            return content;
        }

        if (!this.isPriorityLoading) {
            return content;
        }

        return (
            <AfterPriority fallback={ null }>
                { content }
            </AfterPriority>
        );
    }

    replaceWidget(domNode) {
        const { attribs } = domNode;
        const { fallback, isPriorityContent } = this.props;
        const attributes = this.attributesToProps(attribs);
        const { type, sliderId: slider_id } = attributes;
        const {
            actionName: {
                slider: { slider_id: preloadedSliderId } = {}
            } = {}
        } = window;

        if (this.elementIsHidden(domNode)) {
            return (
                <Suspense fallback={ fallback ? fallback() : <div /> }>
                    { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */ }
                    <WidgetFactory { ...attributes } />
                </Suspense>
            );
        }

        if (
            isPriorityContent
            && type === 'Slider'
            && (slider_id && slider_id === parseInt(preloadedSliderId, 10))
            && !this.isPriorityLoading
        ) {
            this.isPriorityLoading = true;
        }

        return (
            <Suspense fallback={ fallback ? fallback() : <div /> }>
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */ }
                <WidgetFactory { ...attributes } />
            </Suspense>
        );
    }

    /**
     * Replace img to React Images
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceImages(domNode) {
        const { isMenuImagesBlock } = this.props;
        const { attribs } = domNode;
        const attributes = attributesToProps(attribs);
        const { height, width } = attribs;
        const { screen: { width: windowWidth } } = window;

        if (attribs.src) {
            if (isMenuImagesBlock) {
                // eslint-disable-next-line no-magic-numbers
                return this.renderImage(domNode, attributes, getCloudflareOptimizationImage(attribs.src, 500, 300));
            }

            // eslint-disable-next-line no-magic-numbers
            const imageResizeWidth = Number(width) || isMobile.any() ? windowWidth : 1024;

            if (attribs.src.indexOf(PAGEBUILDER_EXCLUDE_TAGS) !== -1) {
                const newAttributes = fixPageBuilderStyleImage(attributes);

                return this.renderImage(domNode, newAttributes, getCloudflareOptimizationImage(newAttributes.src, imageResizeWidth, height));
            }

            return this.renderImage(domNode, attributes, getCloudflareOptimizationImage(attribs.src, imageResizeWidth, height));
        }

        return null;
    }

    renderImage(domNode, attrs, src) {
        const { isPriorityContent, onImageLoad } = this.props;

        const content = (
            <Image
              { ...attrs }
              src={ src }
              isPlain
              isRemoveSizesInStyles
              onImageLoad={ onImageLoad }
            />
        );

        if (!isPriorityContent) {
            return content;
        }

        if (!window.isPriorityLoaded && !this.isPriorityLoading && !this.elementIsHidden(domNode)) {
            this.isPriorityLoading = true;

            return (
                <Image
                  { ...attrs }
                  src={ src }
                  isPlain
                  isRemoveSizesInStyles
                  onImageLoad={ setLoadedFlag }
                  onError={ setLoadedFlag }
                />
            );
        }

        return (
            <AfterPriority fallback={ <div /> }>
                { content }
            </AfterPriority>
        );
    }

    /**
     * Overridden to:
     * - Not scroll to top when opening new tab
     */
    scrollToTopFunction(e) {
        const element = e.currentTarget;

        // Check if the clicked element itself is "a" with target="_blank"
        if (element.tagName === 'A' && element.getAttribute('target') === '_blank') {
            // Do nothing as it's a link that opens in a new tab
            return;
        }
        // Otherwise, scroll to the top
        document.documentElement.scrollIntoView();
    }

    /**
     * Overridden to:
     * - Not scroll to top when opening new tab
     */
    // eslint-disable-next-line consistent-return
    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                if (attribs?.class && attribs?.class?.indexOf('landingShopNow') !== -1) {
                    return (
                        <Link
                          onClick={ this.scrollToTopFunction }
                          { ...attributesToProps({ ...attrs, to: href }) }
                        >
                            { domToReact(children, this.parserOptions) }
                            <ArrowIcon isShort isWhite />
                        </Link>
                    );
                }

                return (
                        <Link
                          onClick={ this.scrollToTopFunction }
                          { ...attributesToProps({ ...attrs, to: href }) }
                        >
                            { domToReact(children, this.parserOptions) }
                        </Link>
                );
            }
        }
    }

    replaceYoutubeVideo({ name, attribs, children }) {
        const props = this.attributesToProps(attribs);
        const videoService = attribs.src.includes('vimeo') ? 'vimeo' : 'youtube';
        const isYoutube = videoService === 'youtube';

        // eslint-disable-next-line fp/no-let
        let src = `${attribs.src }&controls=0&autoplay=1&loop=1`;

        if (isYoutube) {
            const start = src.lastIndexOf('/') + 1;
            const end = src.indexOf('?');

            const ID = src.slice(start, end);

            src += `&rel=0&playlist=${ID}&disablekb=1&autohide=1&showinfo=0&playsinline=1&fs=0&enablejsapi=1`;
        }

        return createElement(
            name,
            {
                ...props,
                src,
                loading: 'lazy',
                ...(isYoutube ? { title: '' } : {}),
                style: {
                    ...(props.style ? { ...props.style } : {}),
                    pointerEvents: 'none'
                }
            },
            children
        );
    }

    replacePageBuilderColumnLine({ _, attribs, children }) {
        const { isContentAfterProductDetails } = this.props;
        const popular = children?.filter(({ attribs }) => attribs?.class?.indexOf('PopularDepartments') !== -1);

        if (popular?.length > 1 && isMobile.any()) {
            return (
                <Slider
                  { ...attributesToProps({
                      showCrumbs: true,
                      areCrumbsLinear: true,
                      isScrollable: true,
                      draggableTabIndex: -1
                  }) }
                >
                    { domToReact(children) }
                </Slider>
            );
        }

        if (!isContentAfterProductDetails) {
            return;
        }

        if (!children?.length) {
            return;
        }

        const columns = children.filter(({ attribs }) => attribs.class === 'pagebuilder-column');

        if (columns?.length <= 1) {
            return;
        }

        // vvv Swapping columns for mobile by appending -reverse to class
        const firstColumnIsText = columns[0]?.children?.some(({ attribs }) => attribs['data-content-type'] === 'text');
        const secondColumnIsImage = columns[1]?.children?.some(({ attribs }) => attribs['data-content-type'] === 'image');

        if (firstColumnIsText && secondColumnIsImage) {
            // eslint-disable-next-line no-param-reassign
            attribs.class = 'pagebuilder-column-line-reverse';
        }
    }

    replaceVisibilityByDevice(block) {
        const isAndroid = isMobile.android();
        const isiOS = isMobile.iOS();
        const isInstalledApp = isMobile.standaloneMode() && (isAndroid || isiOS);

        const visibilityMap = {
            'visible-web-only': !isAndroid && !isiOS,
            'visible-ios-only': isiOS,
            'visible-android-only': isAndroid,
            'visible-mobile-app-only': isInstalledApp,
            'visible-not-mobile-app-only': !isInstalledApp
        };

        Object.entries(visibilityMap).forEach((object) => {
            if (block.attribs.class?.includes(object[0]) && !object[1]) {
                // eslint-disable-next-line no-param-reassign
                block.attribs.class = 'hidden';
            }
        });

        return block;
    }
}

export default HtmlComponent;
