/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Slider from 'Component/Slider';
import {
    ChildrenType,
    MixType,
    RefType
} from 'Type/Common.type';
import { getIsAffordable, getIsHomePage, getIsPremium } from 'Util/Url';

/** @namespace Scandipwa/Component/SliderPerSlide/Component */
export class SliderPerSlideComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType,
        totalSlide: PropTypes.number,
        totalSlideItemPerSlide: PropTypes.number,
        slideItemWidth: PropTypes.shape({
            desktop: PropTypes.number,
            mobile: PropTypes.number,
            landingMobile: PropTypes.number,
            landing: PropTypes.number
        }),
        isInfinite: PropTypes.bool.isRequired,
        mix: MixType.isRequired,
        sliderRef: RefType.isRequired,
        sliderWidth: PropTypes.number.isRequired,
        isHideArrowInSingleSlide: PropTypes.bool.isRequired,
        hasArrowBorder: PropTypes.bool.isRequired,
        hasArrowBackground: PropTypes.bool.isRequired,
        hasContentWrapper: PropTypes.bool.isRequired,
        isInteractionDisabled: PropTypes.bool.isRequired,
        isNotInfiniteOnSingleSlide: PropTypes.bool.isRequired,
        showArrows: PropTypes.bool.isRequired,
        activeSlide: PropTypes.number.isRequired,
        handleSlideChange: PropTypes.func.isRequired,
        isLandingPage: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isLandingPageEnabled: PropTypes.bool.isRequired,
        slideWidth: PropTypes.number.isRequired,
        isDateSlots: PropTypes.bool.isRequired
    };

    static defaultProps = {
        children: [],
        totalSlide: 0,
        totalSlideItemPerSlide: 0,
        slideItemWidth: {
            mobile: 120,
            desktop: 160,
            landingMobile: 252
        }
    };

    renderSlide = (_, idx) => {
        const {
            children = [],
            totalSlide,
            totalSlideItemPerSlide
        } = this.props;

        if (idx >= totalSlide) {
            return null;
        }

        const start = idx * totalSlideItemPerSlide;
        const end = (idx + 1) * totalSlideItemPerSlide;

        return (
            <div key={ idx } block="Slider" elem="Slide">
                { children.slice(start, end) }
            </div>
        );
    };

    renderSlides() {
        const {
            children = [],
            totalSlideItemPerSlide
        } = this.props;

        if (!totalSlideItemPerSlide || !children?.length) {
            return [];
        }

        return children.map(this.renderSlide).filter((item) => item);
    }

    render() {
        const {
            isHideArrowInSingleSlide,
            hasArrowBorder,
            hasArrowBackground,
            hasContentWrapper,
            mix,
            isInteractionDisabled,
            isInfinite: isInfiniteProp,
            sliderRef,
            sliderWidth,
            isNotInfiniteOnSingleSlide,
            showArrows,
            activeSlide,
            handleSlideChange,
            totalSlide,
            isLandingPage,
            isMobile,
            isLandingPageEnabled,
            slideWidth,
            isDateSlots
        } = this.props;

        const isInfinite = isNotInfiniteOnSingleSlide && totalSlide <= 1 ? false : isInfiniteProp;
        const shouldShow = isLandingPage
            || getIsAffordable()
            || isLandingPageEnabled
            || getIsHomePage()
            || getIsPremium();
        const isAutoplay = isLandingPageEnabled && !(getIsHomePage() || getIsPremium());

        if (shouldShow && isMobile) {
            return (
                <Slider
                  isInfinite={ isInfinite }
                  isHideArrowInSingleSlide={ isHideArrowInSingleSlide }
                  hasArrowBorder={ hasArrowBorder }
                  hasArrowBackground={ hasArrowBackground }
                  hasContentWrapper={ hasContentWrapper }
                  mix={ mix }
                  isInteractionDisabled={ isInteractionDisabled }
                  sliderRef={ sliderRef }
                  sliderWidth={ slideWidth }
                  showArrows={ false }
                  activeImage={ activeSlide }
                  onActiveImageChange={ handleSlideChange }
                  showCrumbs={ shouldShow }
                  autoSlide={ false }
                  areCrumbsLinear={ shouldShow && isMobile }
                  animationDuration={ 60 }
                  isScrollable={ !isDateSlots }
                >
                  { this.renderSlides() }
                </Slider>
            );
        }

        return (
            <Slider
              isInfinite={ isInfinite }
              isHideArrowInSingleSlide={ isHideArrowInSingleSlide }
              hasArrowBorder={ hasArrowBorder }
              hasArrowBackground={ hasArrowBackground }
              hasContentWrapper={ hasContentWrapper }
              mix={ mix }
              isInteractionDisabled={ isInteractionDisabled }
              sliderRef={ sliderRef }
              sliderWidth={ sliderWidth }
              showArrows={ showArrows }
              activeImage={ activeSlide }
              onActiveImageChange={ handleSlideChange }
              showCrumbs={ shouldShow }
              autoSlide={ isAutoplay }
              areCrumbsLinear={ shouldShow && isMobile }
            >
                { this.renderSlides() }
            </Slider>
        );
    }
}

export default SliderPerSlideComponent;
