/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';

import UrlKeyQuery from '../query/AmUrlResolver.query';
import {
    TYPE_FAQ, TYPE_FAQ_CATEGORY, TYPE_FAQ_QUESTION, TYPE_FAQ_SEARCH
} from '../route/FaqPage/FaqPage.config';

import '../style/Loader.style';

export const FaqPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "amasty-faq" */ '../route/FaqPage'));

const onSuccess = (args) => {
    const [{ amUrlResolver, urlResolver }, dispatch, { urlParam }] = args;

    dispatch(
        updateUrlRewrite(
            amUrlResolver || urlResolver || { notFound: true },
            urlParam
        )
    );
    dispatch(updateNoMatch(!amUrlResolver && !urlResolver));
};

const prepareRequest = (args, callback, instance) => {
    const [options] = args;

    return [
        ...callback(...args),
        UrlKeyQuery.getQuery(instance.processUrlOptions(options))
    ];
};

const containerProps = (args, callback, instance) => {
    const {
        isLoading
    } = instance.props;

    return {
        ...callback(...args),
        isLoading
    };
};

const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite: {
            id
        }
    } = instance.props;
    const result = callback(...args);

    switch (instance.getType()) {
    case TYPE_FAQ_CATEGORY:
        return { categoryId: id };
    case TYPE_FAQ_QUESTION:
        return { questionId: id };
    default:
        return result;
    }
};

const renderFaqContent = (args, instance) => {
    const { props, type, isLoading } = instance.props;

    if (isLoading) {
        return (
            <ContentWrapper
              mix={ { block: 'LoaderWrapper' } }
              value={ null }
              label=""
            >
                <Loader isLoading={ isLoading } />
            </ContentWrapper>
        );
    }

    return <FaqPage { ...props } type={ type } />;
};

const renderContent = (args, callback, instance) => {
    const { type } = instance.props;

    switch (type) {
    case TYPE_FAQ:
    case TYPE_FAQ_SEARCH:
    case TYPE_FAQ_CATEGORY:
    case TYPE_FAQ_QUESTION:
        return renderFaqContent(args, instance);
    default:
        return callback(...args);
    }
};

const getFallbackType = (args, callback) => {
    // TODO Ticket PAN-644 QA comment fix
    // this is bad and not a proper solution for it, but I don't have any other ideas ;(
    // Tried to follow comment from UrlRewrites getType method in the container of creating controller but failed miserably
    // Maybe someone can give a shot and come up with a better solution
    if (location.pathname.includes('faqs')) {
        return '';
    }

    return callback(...args);
};

export default {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess,
            prepareRequest
        }
    },
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps,
            containerProps
        }
    },
    'Scandipwa/Route/UrlRewrites/Container': {
        'member-function': {
            getFallbackType
        }
    },
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent
        }
    }
};
