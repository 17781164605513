/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import getStore from 'Util/Store';

import { POWERSTEP_POPUP } from '../component/PowerStep/PowerStep.config';

/** @namespace Scandiweb/Clerk/Util/Popup/showPowerStepPopup */
export const showPowerStepPopup = (payload) => {
    const { dispatch } = getStore();
    const { activeOverlay } = getStore().getState().OverlayReducer;
    const popupPayload = getStore().getState().PopupReducer?.popupPayload;
    const isPowerstepPopup = Object.keys(popupPayload)[0] === 'powerstep-popup';

    // vvv Hide Powerstep Popup if product was added to cart from PowerstepPopup
    if (activeOverlay === 'powerstep-popup' && isPowerstepPopup) {
        dispatch(hideActivePopup());
    }

    dispatch(showPopup(POWERSTEP_POPUP, payload));
};
