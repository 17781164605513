import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ChevronIcon from 'Component/ChevronIcon';
import { CategoryTreeType } from 'Type/Category.type';
import { LayoutType } from 'Type/Layout.type';
import { getIsAffordable, getIsHomePage, getIsPremium } from 'Util/Url';

import CategoriesSlider from './CategoriesSlider.component';

/** @namespace Scandipwa/Component/CategoriesSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/CategoriesSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/CategoriesSlider/Container */
export class CategoriesSliderContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        category: CategoryTreeType.isRequired,
        activeLayoutType: LayoutType.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
        isLandingPage: PropTypes.bool,
        categoryImages: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            src: PropTypes.oneOf([PropTypes.number, PropTypes.string])
        })),
        isSquareSlide: PropTypes.bool,
        isSliderDisabled: PropTypes.bool,
        categoryTitles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string
        })),
        categoryCustomUrls: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            customUrl: PropTypes.string
        })),
        isLoading: PropTypes.bool,
        sliderTitle: PropTypes.string,
        slidesCount: PropTypes.number,
        parentSliderCategory: CategoryTreeType
    };

    static defaultProps = {
        isCurrentCategoryLoaded: false,
        isLandingPage: false,
        categoryImages: [],
        isSquareSlide: false,
        isSliderDisabled: false,
        categoryTitles: [],
        categoryCustomUrls: [],
        isLoading: false,
        sliderTitle: '',
        slidesCount: 0,
        parentSliderCategory: []
    };

    state = {
        isParentCategoryUsed: false
    };

    containerFunctions = {
        handleUrlClick: this.handleUrlClick.bind(this)
    };

    handleUrlClick() {
        const { isParentCategoryUsed } = this.state;

        const parentSliderCategory = this.getParentSliderCategory();

        if (!parentSliderCategory) {
            return;
        }

        if (isParentCategoryUsed) {
            this.setState({ isParentCategoryUsed: false });

            return;
        }

        this.setState({ isParentCategoryUsed: true });
    }

    getParentSliderCategory() {
        const { category, parentSliderCategory } = this.props;

        if (category?.length === parentSliderCategory?.length) {
            return null;
        }

        const rootCategory = category.find(({ url }) => url === window.location.pathname);

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const newCategory = {
            ...rootCategory,
            name: '',
            image: <ChevronIcon direction="left" />
        };

        return [newCategory, ...parentSliderCategory];
    }

    containerProps() {
        const {
            category,
            activeLayoutType,
            isMobile,
            isCurrentCategoryLoaded,
            isLandingPage,
            categoryImages,
            isSquareSlide,
            isSliderDisabled,
            categoryTitles,
            isLoading,
            sliderTitle,
            slidesCount,
            categoryCustomUrls
        } = this.props;
        const { isParentCategoryUsed } = this.state;

        return {
            category,
            activeLayoutType,
            isMobile,
            isCurrentCategoryLoaded,
            isLandingPage,
            categoryImages,
            isAffordable: getIsAffordable(),
            isSquareSlide,
            isSliderDisabled,
            isPremium: getIsPremium(),
            isHomePage: getIsHomePage(),
            categoryTitles,
            isLoading,
            sliderTitle,
            slidesCount,
            categoryCustomUrls,
            parentSliderCategory: this.getParentSliderCategory(),
            isParentCategoryUsed
        };
    }

    render() {
        return (
            <CategoriesSlider
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSliderContainer);
