/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { PACK_LIST_CMS_TYPE } from '../component/PackList/PackList.config';
import { PRODUCT_BUNDLE_PACK_CMS_TYPE } from '../component/ProductBundlePack/ProductBundlePack.config';
import { RELATED_CMS_TYPE } from '../component/Related/Related.config';

export const ProductBundlePack = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-bundle-pack" */
    '../component/ProductBundlePack'
));
export const Related = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "related" */
    '../component/Related'
));
export const PackList = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pack-list" */
    '../component/PackList'
));

export const addAmastyWidgetsToWidgetFactory = (member) => ({
    ...member,
    [PACK_LIST_CMS_TYPE]: {
        component: PackList
    },
    [RELATED_CMS_TYPE]: {
        component: Related
    },
    [PRODUCT_BUNDLE_PACK_CMS_TYPE]: {
        component: ProductBundlePack
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addAmastyWidgetsToWidgetFactory
        }
    }
};
