/**
 * Tamara Payment compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import TamaraPriceReducer from '../store/TamaraPrice/TamaraPrice.reducer';

export default {
    'Store/Index/getStaticReducers': {
        function: (args, callback) => ({
            ...callback(args),
            TamaraPriceReducer
        })
    }
};
