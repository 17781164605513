/**
 * Mageplaza Instagram Feed compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { INSTAGRAM_FEED_CMS_TYPE } from '../component/InstagramFeed/InstagramFeed.config';

export const InstagramFeed = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "instagram" */
    '../component/InstagramFeed'
));

const addInstagramFeedToWidgets = (args, callback, instance) => {
    const {
        title,
        description,
        design,
        total_number,
        layout,
        number_row,
        show_caption = '',
        show_popup = '',
        type
    } = instance.props;

    if (type !== INSTAGRAM_FEED_CMS_TYPE) {
        return callback(args);
    }

    const widget = {
        params: {
            // vvv Mapping params from CMS to match Layout Updates format
            title,
            description,
            design,
            total_number,
            layout,
            number_row,
            show_caption: show_caption.toString(),
            show_popup: show_popup.toString()
        }
    };

    return (
        // eslint-disable-next-line react/jsx-no-bind
        <RenderWhenVisible fallback={ () => <div style={ { height: '400px' } } /> }>
            <Suspense fallback={ null }>
                <InstagramFeed widget={ widget } />
            </Suspense>
        </RenderWhenVisible>
    );
};

export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent: addInstagramFeedToWidgets
        }
    }
};
