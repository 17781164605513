import PropTypes from 'prop-types';

import {
    ContentWrapper as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';
import { ChildrenType, MixType } from 'Type/Common.type';

import './ContentWrapper.override.style';

/** @namespace Scandipwa/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends SourceContentWrapper {
    static propTypes = {
        children: ChildrenType,
        mix: MixType,
        wrapperMix: PropTypes.shape({
            block: PropTypes.string,
            elem: PropTypes.string
        }),
        isNotSection: PropTypes.bool
    };
}

export default ContentWrapperComponent;
