import { getInitialState, MyAccountReducer as sourceMyAccountReducer } from 'SourceStore/MyAccount/MyAccount.reducer';

import {
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_LOYALTY_POINTS,
    UPDATE_CUSTOMER_LOYALTY_POINTS_CONFIG,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS
} from './MyAccount.action';

export * from 'SourceStore/MyAccount/MyAccount.reducer';

/** @namespace Scandipwa/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const {
        loyaltyConfig,
        loyaltyPoints
    } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_SIGN_IN_STATUS:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_PASSWORD_RESET_STATUS:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_DETAILS:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_IS_LOADING:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_IS_LOCKED:
        return sourceMyAccountReducer(state, action);

    case UPDATE_CUSTOMER_LOYALTY_POINTS_CONFIG:
        return {
            ...state,
            loyaltyPointsConfig: loyaltyConfig
        };
    case UPDATE_CUSTOMER_LOYALTY_POINTS:
        return {
            ...state,
            loyaltyPoints
        };
    default:
        return state;
    }
};

export default MyAccountReducer;
