Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/seo-suite/src/plugin/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/panemirates-complaint/src/plugin/router/Router.component.plugin.js'),require('/drone/src/build/code/scandipwa/packages/MicrosoftAdvertisingTag/src/plugin/pageView.plugin.js'),require('/drone/src/build/code/scandipwa/packages/magedlight-look-n-buy/src/plugin/Router/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/google-tag-manager/src/plugin/events/general.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amazon-payfort/src/plugin/PayfortPayments/StoredPaymentTokens.plugin.js'),require('/drone/src/build/code/scandipwa/packages/new-clerk/src/plugin/exitintent/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/new-clerk/src/plugin/powerstep/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-rma/src/plugin/router/Router.component.plugin.js'),require('/drone/src/build/code/scandipwa/packages/magefan-blog/src/plugin/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-store-credit/src/plugin/Router/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/store-finder/src/plugin/Router.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-gift-cards/src/plugin/Router/AddGiftCardsTabRoute.plugin.js')]);
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import {
    cloneElement,
    lazy,
    Suspense
} from 'react';
import { Route, Switch } from 'react-router-dom';

import CartIcon from 'Component/CartIcon';
import EnvelopeIcon from 'Component/EnvelopeIcon';
import GlobeIcon from 'Component/GlobeIcon';
import HeartIcon from 'Component/HeartIcon';
import LoaderComponent from 'Component/Loader/Loader.component';
import LoaderPopup from 'Component/LoaderPopup';
import LocationIcon from 'Component/LocationIcon';
import LockIcon from 'Component/LockIcon';
import Logo from 'Component/Logo';
import MenuIcon from 'Component/MenuIcon';
import MenuSidebar from 'Component/MenuSidebar';
import {
    PRINT_ALL_INVOICES,
    PRINT_ALL_REFUNDS,
    PRINT_ALL_SHIPMENT,
    PRINT_ORDER as PRINT_ORDER_REQUEST,
    PRINT_REFUND,
    PRINT_SHIPMENT
} from 'Component/MyAccountOrderPrint/MyAccountOrderPrint.config';
import PhoneIcon from 'Component/PhoneIcon';
import { REMOVE_CART_ITEM_POPUP } from 'Component/RemoveCartItemPopup/RemoveCartItemPopup.config';
import { STOCK_NOTIFICATION_POPUP_NAME } from 'Component/StockNotificationPopup/StockNotificationPopup.config';
import { TAMARA_POPUP_NAME } from 'Component/TamaraPopup/TamaraPopup.config';
import UserIcon from 'Component/UserIcon';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { CHECKOUT_LOGIN_PATH } from 'Route/CheckoutLoginPage/CheckoutLoginPage.config';
import {
    DESIGN_ROOM_STORES,
    DESIGN_ROOM_URL
} from 'Route/DesignRoomPage/DesignRoomPage.config';
import { IMAGE_SEARCH_PATH } from 'Route/ImageSearchPage/ImageSearchPage.config';
import UrlRewrites from 'Route/UrlRewrites';
import {
    PRINT_INVOICE
} from 'SourceComponent/MyAccountOrderPrint/MyAccountOrderPrint.config';
import { PRINT_ORDER } from 'SourceComponent/Router/Router.config';
import CloseIcon from 'Src/component/CloseIcon';
import { appendWithStoreCode, isHomePageUrl } from 'Src/util/Url';
import {
    ADDRESS_BOOK, MY_DOWNLOADABLE, MY_ORDERS,
    MY_WISHLIST, NEWSLETTER_SUBSCRIPTION
} from 'Type/Account.type';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { MY_RETURNS, URL_PREFIX } from '../../../packages/amasty-rma/src/component/MyReturnsTab/MyReturnsTab.config';
import {
    AMASTY_DASHBOARD,
    AMASTY_DASHBOARD_POSITION,
    AMASTY_DASHBOARD_ROUTE,
    STORE_CREDIT_REFUNDS
} from '../../../packages/amasty-store-credit/src/component/AmastyDashboard/AmastyDashboard.config';
import { PayfortPages } from '../../../packages/amazon-payfort/src/Payfort.config';
import { LOYALTY } from '../../type/Account.type';
import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BACK_TO_TOP_BUTTON,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHATBOT,
    CHECKOUT,
    CHECKOUT_LOGIN,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    CUSTOMER_LOYALTY,
    DEMO_NOTICE,
    DESIGN_ROOM,
    FOOTER,
    FORGET_PASSWORD_POPUP_ID,
    HEADER,
    HOME,
    IMAGE_SEARCH,
    INSTALLMENT_POPUP,
    LOADER_POPUP,
    LOGIN,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_ERP_ORDER,
    MY_ACCOUNT_KEY,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    PROMOTION_TC,
    PUSH_NOTIFICATIONS,
    QUICK_VIEW_POPUP,
    REDIRECT_POPUP,
    SEARCH,
    SHARED_WISHLIST,
    SHOP_ALL,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    TABBY_PAYMENT_POPUP,
    THANK_YOU,
    URL_REWRITES,
    VIDEO_POPUP,
    VIEWMODEL_POPUP
} from './Router.config';
import {
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CreateAccountPage,
    ForgotPasswordPage,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    OrderPrintPage,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from './Router.source.component';

import './Router.override.style';

export const ForgetPasswordPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "forget-password-popup" */
    'Component/ForgotPasswordPopup'
));
export const VideoPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "video" */
    'Component/VideoPopup'
));
export const QuickViewPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "quick-view" */
    'Component/QuickViewPopup'
));
export const BackToTopButton = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "back-to-top-button" */
    'Component/BackToTopButton'
));
export const RedirectPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "redirect-popup" */
    'Component/RedirectPopup'
));
export const PushNotifications = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "push-notifications" */
    'Component/PushNotifications'
));
export const InstallmentPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "installment-popup" */
    'Component/InstallmentPopup'
));
export const ViewModelPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "view-model-popup" */
    'Component/ViewModelPopup'
));
export const Header = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "header" */
    'Component/Header'
));
export const Footer = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "footer" */
    'Component/Footer'
));
export const NavigationTabs = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "navigation-tabs" */
    'Component/NavigationTabs'
));
export const Breadcrumbs = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "breadcrumbs" */
    'Component/Breadcrumbs'
));
export const CookiePopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */
    'Component/CookiePopup'
));
export const DemoNotice = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */
    'Component/DemoNotice'
));
export const NewVersionPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */
    'Component/NewVersionPopup'
));
export const NotificationList = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */
    'Component/NotificationList'
));
export const OfflineNotice = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */
    'Component/OfflineNotice'
));
export const TabbyPaymentInfoPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "tabby-popup" */
    'Component/TabbyPaymentInfoPopup'
));
export const Chatbot = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "chatbot" */
    'Component/Chatbot'
));

export const ThankYou = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "thankyou" */
    'Route/ThankYouPage'
));
export const PromotionTC = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "promotion-t-c" */
    'Route/PromotionTCPage'
));
export const ShopAll = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "shop-all" */
    'Route/ShopAllPage'
));
export const CheckoutLogin = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-login" */
    'Route/CheckoutLoginPage'
));
export const DesignRoom = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "design-room" */
    'Route/DesignRoomPage'
));
export const ImageSearchPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "imageSearch" */
    'Route/ImageSearchPage'
));
export const NoMatch = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "misc" */
    'Route/NoMatch'
));
export const SendConfirmationPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "send-confirmation" */
    'Route/SendConfirmationPage'
));
export const TamaraPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "tamara-popup" */
    'Component/TamaraPopup'
));
export const StockNotificationPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "stock-notification-popup" */
    'Component/StockNotificationPopup'
));

export const RemoveCartItemPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "remove-cart-item-popup" */
    'Component/RemoveCartItemPopup'
));

export {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    WishlistShared,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
};

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    /**
     * Overridden to remove navigation tabs on the bottom
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);
        const navigationIndex = this[BEFORE_ITEMS_TYPE].findIndex((item) => item.name === NAVIGATION_TABS);
        this[BEFORE_ITEMS_TYPE].splice(navigationIndex, 1);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getDesignRoomPage(props) {
        const { currentStoreCode } = this.props;

        return (
            DESIGN_ROOM_STORES.includes(currentStoreCode)
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                ? <DesignRoom { ...props } />
                : <NoMatch />
        );
    }

    /**
     * Overridden to:
     * - render VideoPopup
     * - render QuickViewPopup
     * - render BackToTopButton
     * - render ForgetPasswordPopup
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER,
            fallback: this.renderHeaderFallback()
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NAVIGATION_TABS
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS,
            fallback: this.renderBreadcrumbsFallback()
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        },
        {
            component: <VideoPopup />,
            position: 40,
            name: VIDEO_POPUP
        },
        {
            component: <QuickViewPopup />,
            position: 45,
            name: QUICK_VIEW_POPUP
        },
        {
            component: <BackToTopButton />,
            position: 50,
            name: BACK_TO_TOP_BUTTON
        },
        {
            component: <ForgetPasswordPopup />,
            position: 55,
            name: FORGET_PASSWORD_POPUP_ID
        },
        {
            component: <PushNotifications />,
            position: 200,
            name: PUSH_NOTIFICATIONS
        },
        {
            component: <LoaderPopup />,
            position: 60,
            name: LOADER_POPUP
        },
        {
            component: <Chatbot />,
            position: 61,
            name: CHATBOT
        },
        {
            component: <InstallmentPopup />,
            position: 88,
            name: INSTALLMENT_POPUP
        },
        {
            component: <ViewModelPopup />,
            position: 89,
            name: VIEWMODEL_POPUP
        },
        {
            component: <TabbyPaymentInfoPopup />,
            position: 90,
            name: TABBY_PAYMENT_POPUP
        },
        {
            component: <TamaraPopup />,
            position: 90,
            name: TAMARA_POPUP_NAME
        },
        {
            component: <MenuSidebar />,
            position: 91,
            name: 'MENU_SIDEBAR_NAME'
        },
        {
            component: <StockNotificationPopup />,
            position: 92,
            name: STOCK_NOTIFICATION_POPUP_NAME
        }
    ];

    /**
     * Overridden to:
     * - Moved from original parent, because needed to add keys to certain routes to avoid unmount
     */
    OVERRIDE_ORIGINAL_SWITCH_ITEMS_TYPE = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50,
            name: CART
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ (props) => <SendConfirmationPage { ...props } /> } />,
            position: 64,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 70,
            name: MY_ACCOUNT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 71,
            name: MY_ACCOUNT_ORDERS,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_WISHLIST } /> } />,
            position: 73,
            name: MY_ACCOUNT_WISHLIST,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ (props) => <MyAccount { ...props } selectedTab={ ADDRESS_BOOK } /> } />,
            position: 74,
            name: MY_ACCOUNT_ADDRESS,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ (props) => <MyAccount { ...props } selectedTab={ NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 76,
            name: MY_ACCOUNT,
            key: MY_ACCOUNT_KEY
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80,
            name: MENU
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81,
            name: SHARED_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82,
            name: CONTACT_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83,
            name: COMPARE
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 84,
            name: STYLE_GUIDE
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ORDER_REQUEST } /> } />,
            position: 90,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_INVOICE } /> } />,
            position: 94,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_REFUND } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        },
        {
            component: <Route path={ withStoreRegex('/vault/cards/listaction') } render={ (props) => <MyAccount { ...props } selectedTab={ PayfortPages.STORED_PAYMENT } /> } />,
            position: 69,
            name: PayfortPages.STORED_PAYMENT,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                 <Route
                   path={ withStoreRegex(AMASTY_DASHBOARD_ROUTE) }
                   render={ (props) => <MyAccount { ...props } selectedTab={ STORE_CREDIT_REFUNDS } /> }
                 />
            ),
            position: AMASTY_DASHBOARD_POSITION,
            name: AMASTY_DASHBOARD,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                <Route
                  path={ withStoreRegex(`/${ URL_PREFIX }/account/history`) }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> }
                />
            ),
            position: 150,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                <Route
                  path={ withStoreRegex(`/${ URL_PREFIX }/account/view/request/:requestId`) }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> }
                />
            ),
            position: 150,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                <Route
                  path={ withStoreRegex(`/${ URL_PREFIX }/account/newreturn/order`) }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> }
                />
            ),
            position: 150,
            key: MY_ACCOUNT_KEY
        }
    ];

    /**
     * Overridden to add the route "Thank you" for the "Thank you" page
     */
    [SWITCH_ITEMS_TYPE] = [
        {
            component: (
                <Route
                  path={ withStoreRegex('/promotion-t-c') }
                  exact
                  render={ (props) => <PromotionTC { ...props } /> }
                />
            ),
            position: 37,
            name: PROMOTION_TC
        },
        {
            component: (
                <Route
                  path={ withStoreRegex('/thankyou') }
                  exact
                  render={ (props) => <ThankYou { ...props } /> }
                />
            ),
            position: 10,
            name: THANK_YOU
        },
        {
            component: (
                <Route
                  path={ withStoreRegex('/shop-all') }
                  exact
                  render={ (props) => <ShopAll { ...props } /> }
                />
            ),
            position: 85,
            name: SHOP_ALL
        },
        {
            component: (
                <Route
                  path={ withStoreRegex('/customer/loyalty') }
                  exact
                  render={ (props) => <MyAccount { ...props } selectedTab={ LOYALTY } /> }
                />
            ),
            position: 77,
            name: CUSTOMER_LOYALTY,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                <Route
                  path={ withStoreRegex('/sales/erp-order/view/order_id/:orderId?') }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
                />
            ),
            position: 70,
            name: MY_ACCOUNT_ERP_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                <Route
                  path={ withStoreRegex('/sales/order/print/order_id/:orderId?') }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
                />
            ),
            position: 89,
            name: PRINT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
            <Route
              path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
            />
            ),
            position: 90,
            name: PRINT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
            <Route
              path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
            />
            ),
            position: 91,
            name: PRINT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
            <Route
              path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
            />
            ),
            position: 92,
            name: PRINT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
            <Route
              path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') }
              render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_INVOICE } /> }
            />
            ),
            position: 93,
            name: PRINT_ORDER
        },
        {
            component: (
            <Route
              path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
            />
            ),
            position: 94,
            name: PRINT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
            <Route
              path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
            />
            ),
            position: 94,
            name: PRINT_ORDER,
            key: MY_ACCOUNT_KEY
        },
        {
            component: (
                <Route
                  path={ withStoreRegex(`/${DESIGN_ROOM_URL}`) }
                  render={ (props) => this.getDesignRoomPage(props) }
                />
            ),
            position: 95,
            name: DESIGN_ROOM
        },
        {
            component: (
                <Route
                  path={ withStoreRegex(CHECKOUT_LOGIN_PATH) }
                  exact
                  render={ (props) => <CheckoutLogin { ...props } /> }
                />
            ),
            position: 86,
            name: CHECKOUT_LOGIN
        },
        {
            component: (
                <Route
                  path={ withStoreRegex(IMAGE_SEARCH_PATH) }
                  exact
                  render={ (props) => <ImageSearchPage { ...props } /> }
                />
            ),
            position: 88,
            name: IMAGE_SEARCH
        },

        // MAY CAUSE PROBLEMS ON OTHER PAGES, PLEASE DON'T MOVE BACK TO THE TOP WITHOUT COMMUNICATING
        ...this.getOriginalSwitchItems(this[SWITCH_ITEMS_TYPE])
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getOriginalSwitchItems(original_switch_items_type) {
        // Removes items if they're overwritten here (Rest of items are left, needed for extensions and parent items)
        const removeOriginalItems = original_switch_items_type.filter(
            ({ name }) => !name || !this.OVERRIDE_ORIGINAL_SWITCH_ITEMS_TYPE.some(({ name: findName }) => name === findName)
        );

        return [
            ...removeOriginalItems,
            ...this.OVERRIDE_ORIGINAL_SWITCH_ITEMS_TYPE
        ];
    }

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP
        },
        {
            component: <RedirectPopup />,
            position: 30,
            name: REDIRECT_POPUP
        },
        {
            component: <RemoveCartItemPopup />,
            position: 40,
            name: REMOVE_CART_ITEM_POPUP
        }
    ];

    /**
     * Overridden to sort copy of the array
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getSortedItems(type) {
        return Array.from(this[type]).sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');

                    return false;
                }

                return true;
            }
        );
    }

    // Overridden to add Suspense to wrap main items components
    renderMainItems() {
        const { isBigOffline } = this.props;

        if (!navigator.onLine && isBigOffline) {
            return <OfflineNotice isPage />;
        }

        return (
            <Suspense fallback={ this.renderMainItemsFallback() }>
                <Switch>
                    { this.renderComponentsOfType(SWITCH_ITEMS_TYPE) }
                </Switch>
            </Suspense>
        );
    }

    renderMainItemsFallback() {
        return (
            <div block="MainItems" elem="Fallback">
                <LoaderComponent />
            </div>
        );
    }

    /**
     * Overridden to:
     * - Add optional key to routes to avoid unmount
     * - Add Suspense to wrap each section component
     */
    renderComponentsOfType(type) {
        if (type === SWITCH_ITEMS_TYPE) {
            return this.getSortedItems(type).map(
                ({ position, component, key }) => cloneElement(component, { key: key || position })
            );
        }

        return this.getSortedItems(type)
            .map(({
                position,
                component,
                key,
                fallback
            }) => (
                <Suspense fallback={ fallback || null }>
                    { cloneElement(component, { key: key || position }) }
                </Suspense>
            ));
    }

    renderCheckoutHeaderFallback() {
        const {
            header_logo_src
        } = window.storeConfig || {};

        return (
            <div block="CheckoutHeaderFallbackWrapper">
                <div block="ContentWrapper">
                    <div block="CheckoutHeaderFallback">
                        <div block="CheckoutHeaderFallback" elem="Title" mods={ { MobileHidden: true } }>
                            <LockIcon />
                            <h2 block="CheckoutHeaderFallback" elem="TitleText Ltr">SECURE CHECKOUT</h2>
                            <h2 block="CheckoutHeaderFallback" elem="TitleText Rtl">عملية دفع وخروج آمنة</h2>
                        </div>

                        <div block="CheckoutHeaderFallback" elem="Logo">
                            <Logo
                              src={ header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null }
                            />
                        </div>

                        <ul block="CheckoutHeaderFallback" elem="Menu">
                            <li block="CheckoutHeaderFallback" elem="MenuHelp Ltr">Need help?</li>
                            <li block="CheckoutHeaderFallback" elem="MenuHelp Rtl">هل تحتاج إلى مساعدة؟</li>
                            <li block="CheckoutHeaderFallback" elem="MenuEmail">
                                <EnvelopeIcon />
                                <span block="MobileHidden">care@panhomestores.com</span>
                            </li>
                            <li block="CheckoutHeaderFallback" elem="MenuPhone">
                                <PhoneIcon />
                                <span block="MobileHidden">(800 726)</span>
                            </li>
                        </ul>

                        <div block="CheckoutHeaderFallback" elem="Title" mods={ { DesktopHidden: true } }>
                            <LockIcon />
                            <h2 block="CheckoutHeaderFallback" elem="TitleText Ltr">SECURE CHECKOUT</h2>
                            <h2 block="CheckoutHeaderFallback" elem="TitleText Rtl">عملية دفع وخروج آمنة</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHeaderFallback() {
        const {
            header_logo_src,
            logo_height,
            logo_width
        } = window.storeConfig || {};

        if (location.pathname === appendWithStoreCode('/checkout')) {
            return this.renderCheckoutHeaderFallback();
        }

        return (
            <div block="HeaderFallback" mods={ { isMenu: location.pathname === (appendWithStoreCode('/menu')) } }>
                <div block="HeaderFallback" elem="PromoBanner" />
                <div block="HeaderFallback" elem="Links">
                    <div block="HeaderFallback" elem="Logo">
                        <Logo
                          height={ logo_height }
                          width={ logo_width }
                          src={ header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null }
                        />
                    </div>
                    <div block="HeaderFallback" elem="LinksLeft">
                        <div block="HeaderFallback" elem="Link Menu">
                            <MenuIcon />
                            <CloseIcon />
                        </div>
                        <div block="HeaderFallback" elem="Link Location">
                            <LocationIcon />
                            <span block="HeaderFallback" elem="LinkText">{ __('Store Locator') }</span>
                        </div>
                        <div block="HeaderFallback" elem="Link Phone">
                            <PhoneIcon />
                            <span block="HeaderFallback" elem="PhonePlaceholder" />
                        </div>
                        <div block="HeaderFallback" elem="Link StoreSwitcher">
                            <GlobeIcon />
                            <span block="HeaderFallback" elem="LinkText">العربية</span>
                            <span block="HeaderFallback" elem="LinkText Rtl">English</span>
                        </div>
                    </div>

                    <div block="HeaderFallback" elem="SearchField" />

                    <div block="HeaderFallback" elem="Logo Mobile">
                        <Logo src={ header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null } />
                    </div>

                    <div block="HeaderFallback" elem="LinksRight">
                        <div block="HeaderFallback" elem="Link StoreSwitcher">
                            <GlobeIcon />
                            <span block="HeaderFallback" elem="LinkText">العربية</span>
                            <span block="HeaderFallback" elem="LinkText Rtl">English</span>
                        </div>
                        <div block="HeaderFallback" elem="Link User">
                            <UserIcon />
                            <span block="HeaderFallback" elem="LinkText">{ __('Login') }</span>
                        </div>
                        <div block="HeaderFallback" elem="Link Heart">
                            <HeartIcon />
                            <span block="HeaderFallback" elem="LinkText">{ __('Wishlist') }</span>
                        </div>
                        <div block="HeaderFallback" elem="Link Cart">
                            <CartIcon />
                            <span block="HeaderFallback" elem="LinkText">{ __('Cart') }</span>
                        </div>
                    </div>
                </div>
                 <div block="HeaderFallback" elem="SearchField Mobile" />
                <div block="HeaderFallback" elem="PromotionBanner Mobile" />
                <div block="HeaderFallback" elem="Menu" />
            </div>
        );
    }

    renderBreadcrumbsFallback() {
        const { pathname = appendWithStoreCode('/') } = location;

        if (
            pathname.match(appendWithStoreCode(CHECKOUT_URL))
            || pathname.match(appendWithStoreCode('/menu'))
            || isHomePageUrl(pathname.endsWith('/') ? pathname : `${pathname }/`)
            || pathname.match(appendWithStoreCode('/affordable'))
            || pathname.match(appendWithStoreCode('/premium'))
        ) {
            return null;
        }

        return (
            <div block="BredcrumbsFallback">
                <div block="BredcrumbsFallback" elem="Placeholder" />
            </div>
        );
    }
}

export default RouterComponent;
