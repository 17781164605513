import PRODUCT_TYPE from 'Component/Product/Product.config';

import { ADD_TO_CART } from './Product';

export * from 'SourceUtil/Product/Transform.js';

/**
 * Overidden to process selectedSpotProducts
 * @param product
 * @param quantity
 * @param enteredOptions
 * @param selectedOptions
 * @param selectedSpotProducts
 * @returns {*[]}
 * @namespace Scandipwa/Util/Product/Transform/magentoProductTransform */
export const magentoProductTransform = (
    action = ADD_TO_CART,
    product,
    quantity = 1,
    enteredOptions = [],
    selectedOptions = [],
    selectedSpotProducts = {}
) => {
    const { sku, type_id: typeId } = product;

    const productData = [];

    if (typeId === PRODUCT_TYPE.grouped && action === ADD_TO_CART) {
        if (Object.keys(quantity).length === 0) {
            return productData;
        }

        const { items } = product;
        const groupedProducts = [];

        items.forEach(({ product: { id } }) => {
            const { [id]: groupedQuantity = 0 } = quantity;

            groupedProducts.push(btoa(`grouped/${id}/${groupedQuantity}`));
        });

        productData.push({
            sku,
            quantity: 1,
            selected_options: [...selectedOptions, ...groupedProducts],
            entered_options: enteredOptions
        });
    } else {
        const baseProductToAdd = {
            sku,
            quantity,
            selected_options: selectedOptions,
            entered_options: enteredOptions
        };

        productData.push(baseProductToAdd);
    }

    if (Object.keys(selectedSpotProducts).length !== 0) {
        Object.values(selectedSpotProducts).forEach((spotProduct) => {
            const { product: { sku } = {}, qty = 1 } = spotProduct;
            productData.push({
                sku, quantity: qty, selected_options: [], entered_options: []
            });
        });
    }

    return productData;
};
