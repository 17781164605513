/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { deleteGiftCardsCartId } from '../../util/Cart';

const deleteGiftCardsCartOnCustomerDataUpdate = (args, callback) => {
    const state = callback(...args);

    if (!state?.isSignedIn) {
        deleteGiftCardsCartId();
    }

    return state;
};

export default {
    'Store/MyAccount/Reducer/MyAccountReducer': {
        function: deleteGiftCardsCartOnCustomerDataUpdate
    }
};
