import { PureComponent } from 'react';

import './GlobeIcon.style';

/** @namespace Scandipwa/Component/GlobeIcon/Component */
export class GlobeIconComponent extends PureComponent {
    /**
     * New method to render globe icon
     */
    render() {
        return (
            <svg block="GlobeIcon" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.86 14C16.94 13.34 17 12.68 17 12C17 11.32 16.94 10.66 16.86 10H20.24C20.4 10.64
                    20.5 11.31 20.5 12C20.5 12.69 20.4 13.36 20.24 14M15.09 19.56C15.69 18.45 16.15 17.25 16.47
                    16H19.42C18.46 17.65 16.93 18.93 15.09 19.56ZM14.84 14H10.16C10.06 13.34 10 12.68 10 12C10
                    11.32 10.06 10.65 10.16 10H14.84C14.93 10.65 15 11.32 15 12C15 12.68 14.93 13.34 14.84
                    14ZM12.5 19.96C11.67 18.76 11 17.43 10.59 16H14.41C14 17.43 13.33 18.76 12.5 19.96ZM8.5
                    8H5.58C6.53 6.34 8.07 5.06 9.9 4.44C9.3 5.55 8.85 6.75 8.5 8ZM5.58 16H8.5C8.85 17.25
                    9.3 18.45 9.9 19.56C8.07 18.93 6.53 17.65 5.58 16ZM4.76 14C4.6 13.36 4.5 12.69 4.5
                    12C4.5 11.31 4.6 10.64 4.76 10H8.14C8.06 10.66 8 11.32 8 12C8 12.68 8.06 13.34 8.14
                    14M12.5 4.03C13.33 5.23 14 6.57 14.41 8H10.59C11 6.57 11.67 5.23 12.5 4.03ZM19.42
                    8H16.47C16.15 6.75 15.69 5.55 15.09 4.44C16.93 5.07 18.46 6.34 19.42 8ZM12.5 2C6.97
                    2 2.5 6.5 2.5 12C2.5 14.6522 3.55357 17.1957 5.42893 19.0711C6.35752 19.9997 7.45991
                    20.7362 8.67317 21.2388C9.88642 21.7413 11.1868 22 12.5 22C15.1522 22 17.6957 20.9464
                    19.5711 19.0711C21.4464 17.1957 22.5 14.6522 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388
                    8.17317C21.2362 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375
                    16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2Z"
                />
            </svg>
        );
    }
}

export default GlobeIconComponent;
