/**
 * Complaints compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/*
 * disabled multiple eslint rules to match coding style of ScandiPWA Core
*/
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

import { MY_COMPLAINTS, URL_PREFIX } from '../../component/MyComplaintTab/MyComplaintTab.config';

export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    'Route/MyAccount'
));

export const PanMyComplaintsPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "complaint" */
    '../../route/PanMyComplaintsPage'
));

export const PanViewComplaint = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "complaint" */
    '../../route/PanViewComplaint'
));

export const PanNewComplaint = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "complaint" */
    '../../route/PanNewComplaint'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
            <Route
              path={ withStoreRegex(`/${ URL_PREFIX }/account/history`) }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_COMPLAINTS } /> }
            />
        ),
        position: 150
    },
    {
        component: (
            <Route
              path={ withStoreRegex(`/${ URL_PREFIX }/account/view/request/:requestId`) }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_COMPLAINTS } /> }
            />
        ),
        position: 150
    },
    {
        component: (
            <Route
              path={ withStoreRegex(`/${ URL_PREFIX }/account/newcomplaint/order`) }
              render={ (props) => <MyAccount { ...props } selectedTab={ MY_COMPLAINTS } /> }
            />
        ),
        position: 150
    }
];

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
