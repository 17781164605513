/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import ArrowIcon from 'Component/ArrowIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import Link from 'Component/Link';

import { PagebuilderElementType } from '../../type/Elements.type';

import './CategoryTabs.style';

/** @namespace Scandiweb/AdobePageBuilder/Component/CategoryTabs/Component */
export class CategoryTabsComponent extends PureComponent {
    static propTypes = {
        elements: PagebuilderElementType.isRequired,
        activeTab: PropTypes.number.isRequired,
        setActiveTab: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    leftScrollHandler = this.leftScrollHandler.bind(this);

    rightScrollHandler = this.rightScrollHandler.bind(this);

    handleScroll = this.handleScroll.bind(this);

    leftScrollHandler() {
        this.ref.current.scrollLeft -= 100;
    }

    rightScrollHandler() {
        this.ref.current.scrollLeft += 100;
    }

    handleScroll() {
        const { scrollLeft, scrollWidth } = this.ref.current;
        const { width } = this.ref.current.getBoundingClientRect();
        const { leftArrowIsVisible, rightArrowIsVisible } = this.state;

        if (!leftArrowIsVisible && scrollLeft > 0) {
            this.setState({ leftArrowIsVisible: true });
        } else if (leftArrowIsVisible && scrollLeft === 0) {
            this.setState({ leftArrowIsVisible: false });
        }

        if (!rightArrowIsVisible && scrollWidth - scrollLeft > width) {
            this.setState({ rightArrowIsVisible: true });
        } else if (rightArrowIsVisible && scrollWidth - scrollLeft === width) {
            this.setState({ rightArrowIsVisible: false });
        }
    }

    __construct(props) {
        super.__construct(props);
        this.ref = createRef();

        this.state = {
            leftArrowIsVisible: false,
            rightArrowIsVisible: true
        };
    }

    renderTab(tab, index) {
        const { setActiveTab, activeTab, isMobile } = this.props;

        const title = tab?.props?.children?.props?.children;

        const isSelected = activeTab === index;

        if (!title) {
            return null;
        }

        return (
            <>
                <button
                  block="CategoryTabs"
                  elem="Tab"
                  mods={ { isSelected } }
                  key={ index }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => {
                      setActiveTab(index);
                  } }
                >
                    <span>
                        { __('%s %s', isMobile ? '' : 'Shop', title) }
                        <ArrowIcon isShort />
                    </span>
                </button>
                <hr />
            </>
        );
    }

    renderActiveTab() {
        const { elements: { CategoryTabs: { childEleBag } }, activeTab } = this.props;
        const categories = childEleBag[0][1]?.props?.children[activeTab]?.props?.children || [];

        return (
            <div block="CategoryTabs" elem="Content">
                { categories?.map(this.renderCategory.bind(this)) }
            </div>
        );
    }

    renderCategory(category) {
        const image = category?.props.children[0];
        const name = category?.props.children[1]?.props.children;

        if (!name) {
            return null;
        }

        return (
            <Link to={ `/${name.toLowerCase().split(' ').join('-')}.html` } block="CategoryTabs" elem="Category">
                <div block="CategoryTabs" elem="ImageContainer">{ image }</div>
                <span>
                    { name }
                </span>
            </Link>
        );
    }

    renderControls() {
        const { isMobile } = this.props;
        const { leftArrowIsVisible, rightArrowIsVisible } = this.state;

        const mods = {
            both: leftArrowIsVisible && rightArrowIsVisible,
            left: leftArrowIsVisible,
            right: rightArrowIsVisible
        };

        if (!isMobile) {
            return null;
        }

        return (
            <div block="CategoryTabs" elem="Controls" mods={ mods }>
               { leftArrowIsVisible && (
                    <button onClick={ this.leftScrollHandler }>
                        <ChevronIcon direction={ LEFT } />
                    </button>
               ) }
               { rightArrowIsVisible && (
                    <button onClick={ this.rightScrollHandler }>
                        <ChevronIcon />
                    </button>
               ) }
            </div>
        );
    }

    render() {
        const { elements: { CategoryTabs } } = this.props;

        return (
            <div block="CategoryTabs">
                { this.renderControls() }
                <div block="CategoryTabs" elem="Tabs" ref={ this.ref } onScroll={ this.handleScroll }>
                    { CategoryTabs.childEleBag[0][0]?.props.children?.map(this.renderTab.bind(this)) }
                </div>
                <hr block="CategoryTabs" elem="MobileDivider" />
                { this.renderActiveTab() }
            </div>
        );
    }
}

export default CategoryTabsComponent;
