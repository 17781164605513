import { getStore } from 'Util/Store';

export const PAGEBUILDER_EXCLUDE_TAGS = '{{media url=';

/** @namespace Scandipwa/Util/PageBuilder/Index/fixPageBuilderStyleImage */
export const fixPageBuilderStyleImage = (attributes) => {
    if (attributes.src.indexOf(PAGEBUILDER_EXCLUDE_TAGS) === -1) {
        return attributes;
    }

    const fix = attributes.src.replace(PAGEBUILDER_EXCLUDE_TAGS, '').replace('}}', '');
    const baseImageUrl = getStore().getState().ConfigReducer.base_url;
    const newSrc = `${baseImageUrl}media/${fix}`;

    return { ...attributes, src: newSrc };
};
