/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { POWERSTEP_POPUP } from '../../component/PowerStep/PowerStep.config';
import { renderPowerStepPopup } from './Product.plugin';

const BEFORE_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: renderPowerStepPopup(),
        position: 65,
        name: POWERSTEP_POPUP
    }
];

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};
