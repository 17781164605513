/**
 * Tamara Payment compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const TAMARAPRICE_ACTION_TYPE = 'TAMARAPRICE_ACTION_TYPE';

/** @namespace Scandiweb/TamaraPayment/Store/TamaraPrice/Action/updateTamaraPrice */
export const updateTamaraPrice = (payload) => ({
    type: TAMARAPRICE_ACTION_TYPE,
    payload
});
