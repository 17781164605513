import { PropTypes } from 'prop-types';

import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';
import { noopFn } from 'Util/Common';

/** @namespace Scandipwa/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        isLazyLoaded: PropTypes.bool,
        onError: PropTypes.func
    };

    static defaultProps = {
        ...super.defaultProps,
        isRemoveSizesInStyles: false,
        isLazyLoaded: true,
        onError: noopFn
    };

    containerProps() {
        const {
            isLazyLoaded,
            placeholderSrc,
            currentSlide,
            onError,
            width,
            height
        } = this.props;

        return {
            ...super.containerProps(),
            isLazyLoaded,
            placeholderSrc,
            currentSlide,
            onError,
            width,
            height
        };
    }

    _isCached() {
        return false;
    }

    _getStyle() {
        const { style, isRemoveSizesInStyles } = this.props;

        if (isRemoveSizesInStyles) {
            return { ...style };
        }

        return { ...this._getCorrectSize(), ...style };
    }
}

export default ImageContainer;
