import { PureComponent } from 'react';

import './LocationIcon.style';

/** @namespace Scandipwa/Component/LocationIcon/Component */
export class LocationIconComponent extends PureComponent {
    /**
     * New Method to render Location icon
     */
    render() {
        return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              block="LocationIcon"
              viewBox="0 0 25 24"
            >
                <path
                  d="M12.5 20.8999L17.45 15.9499C18.4289 14.9709 19.0955 13.7236 19.3656
                    12.3658C19.6356 11.0079 19.4969 9.60052 18.9671 8.32148C18.4373 7.04244
                    17.54 5.94923 16.3889 5.18009C15.2378 4.41095 13.8844 4.00043 12.5
                    4.00043C11.1156 4.00043 9.76222 4.41095 8.61109 5.18009C7.45996 5.94923
                    6.56275 7.04244 6.03292 8.32148C5.50308 9.60052 5.36442 11.0079 5.63445
                    12.3658C5.90449 13.7236 6.57111 14.9709 7.55 15.9499L12.5 20.8999ZM12.5
                    23.7279L6.136 17.3639C4.87734 16.1052 4.02019 14.5016 3.67293 12.7558C3.32567
                    11.0099 3.50391 9.20035 4.1851 7.55582C4.86629 5.91129 6.01984 4.50569
                    7.49988 3.51677C8.97992 2.52784 10.72 2 12.5 2C14.28 2 16.0201 2.52784
                    17.5001 3.51677C18.9802 4.50569 20.1337 5.91129 20.8149 7.55582C21.4961
                    9.20035 21.6743 11.0099 21.3271 12.7558C20.9798 14.5016 20.1227 16.1052
                    18.864 17.3639L12.5 23.7279ZM12.5 12.9999C13.0304 12.9999 13.5391 12.7892
                    13.9142 12.4141C14.2893 12.0391 14.5 11.5304 14.5 10.9999C14.5 10.4695
                    14.2893 9.96078 13.9142 9.58571C13.5391 9.21064 13.0304 8.99992 12.5
                    8.99992C11.9696 8.99992 11.4609 9.21064 11.0858 9.58571C10.7107 9.96078
                    10.5 10.4695 10.5 10.9999C10.5 11.5304 10.7107 12.0391 11.0858
                    12.4141C11.4609 12.7892 11.9696 12.9999 12.5 12.9999ZM12.5 14.9999C11.4391
                    14.9999 10.4217 14.5785 9.67158 13.8283C8.92143 13.0782 8.5 12.0608 8.5
                    10.9999C8.5 9.93906 8.92143 8.92164 9.67158 8.17149C10.4217 7.42135
                    11.4391 6.99992 12.5 6.99992C13.5609 6.99992 14.5783 7.42135 15.3284
                    8.17149C16.0786 8.92164 16.5 9.93906 16.5 10.9999C16.5 12.0608 16.0786
                    13.0782 15.3284 13.8283C14.5783 14.5785 13.5609 14.9999 12.5 14.9999Z"
                />
            </svg>
        );
    }
}

export default LocationIconComponent;
