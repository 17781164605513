/**
 * Tamara Payment compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { TAMARAPRICE_ACTION_TYPE } from './TamaraPrice.action';

/** @namespace Scandiweb/TamaraPayment/Store/TamaraPrice/Reducer/getInitialState */
export const getInitialState = () => ({
    payload: {}
});

/** @namespace Scandiweb/TamaraPayment/Store/TamaraPrice/Reducer/TamaraPriceReducer */
export const TamaraPriceReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case TAMARAPRICE_ACTION_TYPE:
        const { payload } = action;

        return {
            ...state,
            payload
        };

    default:
        return state;
    }
};

export default TamaraPriceReducer;
