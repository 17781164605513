import {
    getSortedItems,
    Menu as SourceMenuUtil,
    TYPE_CATEGORY,
    TYPE_CMS_PAGE
} from 'SourceUtil/Menu/Menu';

export * from 'SourceUtil/Menu/Menu';

/** @namespace Scandipwa/Util/Menu */
export class Menu extends SourceMenuUtil {
    getMenuUrl({
        url,
        url_type,
        categoryDescription,
        categoryChildrenCount,
        categoryImage,
        categoryTitle,
        category_id,
        item_category_id
    }) {
        switch (url_type) {
        case TYPE_CATEGORY:
            return {
                pathname: url,
                search: '',
                state: {
                    category: category_id,
                    categoryId: item_category_id,
                    categoryDescription,
                    categoryChildrenCount,
                    categoryImage,
                    categoryTitle
                }
            };
        case TYPE_CMS_PAGE:
            return {
                pathname: url,
                search: '',
                state: { page: true }
            };
        default:
            return url;
        }
    }

    getMenuData({
        cms_page_identifier: _,
        url,
        url_type,
        category_id,
        categoryDescription,
        categoryChildrenCount,
        categoryImage,
        categoryTitle,
        item_category_id,
        ...item
    }) {
        return {
            ...item,
            url: this.getMenuUrl({
                url,
                url_type,
                category_id,
                categoryDescription,
                categoryChildrenCount,
                categoryImage,
                categoryTitle,
                item_category_id
            }),
            children: {}
        };
    }

    getRelativeUrl(url) {
        try {
            const {
                location: {
                    origin: siteOrigin
                }
            } = window;
            const { origin, pathname } = new URL(url);

            if (siteOrigin === origin) {
                return pathname;
            }

            return url;
        } catch (err) {
            return url;
        }
    }

    reduce({ items: unsortedItems }) {
        this.menu = {};
        this.menuPositionReference = {};

        getSortedItems(unsortedItems).forEach((realMenuItem) => {
            this.createItem(realMenuItem);
        });

        return this.menu;
    }
}

export default new Menu();
