/**
 * Microsoft Advertising Tag compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

async function addInitScript(args, callback) {
    callback(...args);

    waitForPriorityLoad().then(
        async () => {
            const { waitForCallback } = await import(
                /* webpackMode: "lazy", webpackChunkName: "mat-init-script" */
                '../util/wait'
            );
            const { mat } = await import(
                /* webpackMode: "lazy", webpackChunkName: "mat-init-script" */
                '../util/script'
            );

            await waitForCallback(() => getStore().getState().ConfigReducer?.mat?.enabled);

            const {
                ConfigReducer: {
                    mat: {
                        enabled
                    } = {}
                } = {}
            } = getStore().getState();

            if (enabled) {
                mat(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
            }
        }
    );
}

export default {
    'Component/App/Component': {
        'member-function': {
            __construct: addInitScript
        }
    }
};
