/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

export const PROMO_CLICK_EVENT = 'promotionClick';
export const PROMO_VIEW_EVENT = 'promoView';

/** @namespace Scandiweb/Gtm/Event/Banner/firePromoViewEventForCategorySlider */
export const firePromoViewEventForCategorySlider = async (props) => {
    const {
        sliderTitle,
        category
    } = props;
    const { pathname } = window.location;

    if (
        !sliderTitle
        || (window.gtmBanner && window.gtmBanner?.[pathname]?.includes(sliderTitle))
    ) {
        return;
    }

    const data = category.map(({ id, name }, index) => ({
        id,
        name,
        creative: sliderTitle,
        position: index
    })).filter((item) => item);

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Banner/firePromoViewEventForCategorySlider/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getBannerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "banner-util" */
                '../data/banner'
            );

            pushToDataLayer({
                event: PROMO_VIEW_EVENT,
                ...await getBannerData(data)
            });
        }
    );

    if (!window.gtmBanner) {
        window.gtmBanner = { [pathname]: [sliderTitle] };
    }

    if (!window.gtmBanner[pathname]) {
        window.gtmBanner = { [pathname]: [sliderTitle] };
    }

    window.gtmBanner[pathname].push(sliderTitle);
};

/** @namespace Scandiweb/Gtm/Event/Banner/firePromoViewEvent */
export const firePromoViewEvent = async (props) => {
    const {
        slider: {
            title,
            slides
        }
    } = props;
    const { pathname } = window.location;

    if (
        !title
        || (window.gtmBanner && window.gtmBanner?.[pathname]?.includes(title))
    ) {
        return;
    }

    const data = slides.map(({ slide_id, is_active, title: slideTitle }, index) => {
        if (!is_active) {
            return null;
        }

        return {
            id: slide_id,
            name: slideTitle,
            creative: title,
            position: index
        };
    }).filter((item) => item);

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Banner/firePromoViewEvent/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getBannerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "banner-util" */
                '../data/banner'
            );

            pushToDataLayer({
                event: PROMO_VIEW_EVENT,
                ...await getBannerData(data)
            });
        }
    );

    if (!window.gtmBanner) {
        window.gtmBanner = { [pathname]: [title] };
    }

    if (!window.gtmBanner[pathname]) {
        window.gtmBanner = { [pathname]: [title] };
    }

    window.gtmBanner[pathname].push(title);
};

/** @namespace Scandiweb/Gtm/Event/Banner/firePromoClickEventForCategorySlider */
export const firePromoClickEventForCategorySlider = async (sliderTitle, slide, index) => {
    const {
        id,
        name
    } = slide;

    const data = {
        id,
        name,
        creative: sliderTitle,
        position: index
    };

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Banner/firePromoClickEventForCategorySlider/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getBannerClickData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "banner-util" */
                '../data/banner'
            );

            pushToDataLayer({
                event: PROMO_CLICK_EVENT,
                ...await getBannerClickData(data)
            });
        }
    );
};

/** @namespace Scandiweb/Gtm/Event/Banner/firePromoClickEvent */
export const firePromoClickEvent = async (props, slide, index) => {
    const {
        slider: {
            title
        }
    } = props;

    const {
        slide_id,
        title: slideTitle
    } = slide;

    const data = {
        id: slide_id,
        name: slideTitle,
        creative: title,
        position: index
    };

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/Banner/firePromoClickEvent/waitForPriorityLoad/then */
        async () => {
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { getBannerClickData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "banner-util" */
                '../data/banner'
            );

            pushToDataLayer({
                event: PROMO_CLICK_EVENT,
                ...await getBannerClickData(data)
            });
        }
    );
};
