import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import NotificationList from 'Component/NotificationList';

/** @namespace Scandipwa/Component/PopupStandard/Component */
export class PopupStandardComponent extends PureComponent {
    static propTypes = {
        renderTitle: PropTypes.func.isRequired,
        renderHeader: PropTypes.func.isRequired,
        hidePopupAndGoBack: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        children: PropTypes.element.isRequired,
        isWithCloseIcon: PropTypes.bool.isRequired
    };

    renderCloseButton() {
        const {
            hidePopupAndGoBack
        } = this.props;

        return (
            <button
              block="Popup"
              elem="CloseBtn"
              title={ __('Close') }
              aria-label={ __('Close') }
              onClick={ hidePopupAndGoBack }
            >
                <CloseIcon />
            </button>
        );
    }

    renderNotifications() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return <NotificationList />;
    }

    render() {
        const {
            renderTitle,
            children,
            renderHeader,
            isWithCloseIcon
        } = this.props;

        return (
            <>
                <header block="Popup" elem="Header">
                    { !renderHeader && renderTitle?.() }
                    { renderHeader && renderHeader() }
                    { isWithCloseIcon && this.renderCloseButton() }
                </header>
                { this.renderNotifications() }
                { children }
            </>
        );
    }
}

export default PopupStandardComponent;
