import {
    ChevronIcon as SourceChevronIcon
} from 'SourceComponent/ChevronIcon/ChevronIcon.component';

/** @namespace Scandipwa/Component/ChevronIcon/Component */
export class ChevronIconComponent extends SourceChevronIcon {
    /**
     * Overridden to render ChevronIcon matching the mockup
     * Disabled the line length linting rule to accomodate for inline svg
     */
    /* eslint-disable max-len */
    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5.12359 12L10.1236 6.99999L5.12359 2L3.88477 3.23883L7.64593 6.99999L3.88477 10.7612L5.12359 12Z" />
            </svg>
        );
    }
    /* eslint-enable max-len */
}

export default ChevronIconComponent;
