/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Query/AmUrlResolver/Query */
export class AmUrlResolverQuery {
    getQuery({ urlParam }) {
        return new Field('amARPUrlResolver')
            .addArgument('path', 'String!', urlParam)
            .addFieldList(this.getAmUrlResolverFields());
    }

    getAmUrlResolverFields() {
        return [
            'type'
        ];
    }
}

export default new AmUrlResolverQuery();
