/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const VIDEO_CONTENT_TYPE = 'video';

export const VIDEO_SKELETON = [{
    name: 'BaseVideo',
    type: 'div'
}];
