/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

export const BlogWidget = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "blog-widget" */
    '../component/BlogWidget'
));

const BLOG_WIDGET = 'MagefanBlog_Featured';

const addBlogWidgetToRenderMap = (originalMember) => ({
    ...originalMember,
    [BLOG_WIDGET]: {
        component: BlogWidget
    }
});

const addRenderBlogWidget = (args, callback, instance) => {
    const {
        type,
        title,
        postsIds
    } = instance.props;
    const {
        component: Widget
    } = instance.renderMap[type] || {};

    if (type !== BLOG_WIDGET) {
        return callback(...args);
    }

    if (Widget !== undefined) {
        return (
            <Widget
              title={ title }
              featuredPostIDs={ postsIds }
            />
        );
    }

    return null;
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addBlogWidgetToRenderMap
        },
        'member-function': {
            renderContent: addRenderBlogWidget
        }
    }
};
