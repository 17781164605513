export * from 'SourceComponent/Router/Router.config';

export const VIDEO_POPUP = 'VIDEO_POPUP';
export const QUICK_VIEW_POPUP = 'QUICK_VIEW_POPUP';
export const LOADER_POPUP = 'LOADER_POPUP';
export const BACK_TO_TOP_BUTTON = 'BACK_TO_TOP_BUTTON';
export const THANK_YOU = 'THANK_YOU';
export const SHOP_ALL = 'SHOP_ALL';
export const CUSTOMER_LOYALTY = 'CUSTOMER_LOYALTY';
export const DESIGN_ROOM = 'DESIGN_ROOM';
export const MY_ACCOUNT_ERP_ORDER = 'MY_ACCOUNT_ERP_ORDER';
export const PROMOTION_TC = 'PROMOTION_TC';
export const FORGET_PASSWORD_POPUP_ID = 'FORGET_PASSWORD_POPUP_ID';
export const CHECKOUT_LOGIN = 'CHECKOUT_LOGIN';
export const PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS';
export const IMAGE_SEARCH = 'IMAGE_SEARCH';
export const INSTALLMENT_PAGE_URL = '/faqs/faq-installment';
export const INSTALLMENT_ARABIC_PAGE_URL = '/faqs/ar-installment';
export const REDIRECT_POPUP = 'REDIRECT_POPUP';
export const CHATBOT = 'CHATBOT';
export const INSTALLMENT_POPUP = 'INSTALLMENT_POPUP';
export const VIEWMODEL_POPUP = 'VIEWMODEL_POPUP';
export const TABBY_PAYMENT_POPUP = 'TABBY_PAYMENT_POPUP';

/**
 * Used for not unmounting component in router
 */
export const MY_ACCOUNT_KEY = 'myAccount';
