/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { BACK_TO_PRODUCT_CMS_TYPE } from '../component/BackToProduct/BackToProduct.config';
import { CATEGORIES_CMS_TYPE } from '../component/Categories/Categories.config';
import { QUESTIONS_CMS_TYPE } from '../component/Questions/Questions.config';
import { SEARCHBOX_CMS_TYPE } from '../component/SearchBox/SearchBox.config';
import { TAGS_CMS_TYPE } from '../component/Tags/Tags.config';

export const SearchBox = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "search-box" */
    '../component/SearchBox'
));
export const BackToProduct = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "back-to-product" */
    '../component/BackToProduct'
));
export const Categories = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "categories" */
    '../component/Categories'
));
export const Questions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "questions" */
    '../component/Questions'
));
export const Tags = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "tags" */
    '../component/Tags'
));

export const addAmastyFAQToWidgets = (originalMembers) => ({
    ...originalMembers,
    [SEARCHBOX_CMS_TYPE]: {
        component: SearchBox
    },
    [BACK_TO_PRODUCT_CMS_TYPE]: {
        component: BackToProduct
    },
    [CATEGORIES_CMS_TYPE]: {
        component: Categories
    },
    [QUESTIONS_CMS_TYPE]: {
        component: Questions
    },
    [TAGS_CMS_TYPE]: {
        component: Tags
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addAmastyFAQToWidgets
        }
    }
};
