/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/*
 * disabled multiple eslint rules to match coding style of ScandiPWA Core
 * vvv
 */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import {
    GIFT_CARDS,
    GIFTCARDS_ACCOUNT_ORDER_URL,
    GIFTCARDS_BASE_PATH,
    GIFTCARDS_CHECKOUT_PATH
} from '../../config/index';

export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    'Route/MyAccount'
));
export const GiftCards = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-gift-cards" */
    '../../route/GiftCards'
));
export const GiftCardsCheckout = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-gift-cards" */
    '../../route/GiftCardsCheckout'
));

const SWITCH_ITEMS_TYPE = (originalMember) => {
    const { generalActive } = getStore().getState()?.ConfigReducer?.AmastyGiftCardsConfig || {};

    if (!generalActive) {
        return originalMember;
    }

    return [
        ...originalMember,
        {
            component: (
            <Route
              path={ withStoreRegex(GIFTCARDS_BASE_PATH) }
              exact
              render={ (props) => (
                    <MyAccount { ...props } selectedTab={ GIFT_CARDS } />
              ) }
            />
            ),
            position: 150
        },
        {
            component: (
            <Route
              path={ withStoreRegex(GIFTCARDS_CHECKOUT_PATH) }
              exact
              render={ (props) => <GiftCardsCheckout { ...props } /> }
            />
            ),
            position: 160
        },
        {
            component: (
            <Route
              path={ withStoreRegex(`${GIFTCARDS_ACCOUNT_ORDER_URL}/:orderId`) }
              render={ (props) => <GiftCards { ...props } isOrderDetailsPage /> }
            />
            ),
            position: 170
        },
        {
            component: (
            <Route
              path={ withStoreRegex(`${GIFTCARDS_BASE_PATH}/:selectedTab`) }
              render={ (props) => <GiftCards { ...props } /> }
            />
            ),
            position: 180
        }
    ];
};

const initGiftCardsCartDataOnRouter = (args, callback) => {
    const sourceMapDispatchToProps = callback(...args) || {};
    const [dispatch] = args || [];
    const { init: sourceInit } = sourceMapDispatchToProps;
    const { generalActive } = getStore().getState()?.ConfigReducer?.AmastyGiftCardsConfig || {};

    if (!generalActive) {
        return sourceMapDispatchToProps;
    }

    const init = async () => {
        sourceInit();
        waitForPriorityLoad().then(
            async () => {
                const { GiftCardsCartDispatcher } = await import(
                    /* webpackMode: "lazy", webpackChunkName: "gift-cards-cart-dispatcher" */
                    '../../store/GiftCardsCart/GiftCardsCart.dispatcher'
                );

                const dispatcher = new GiftCardsCartDispatcher();
                dispatcher.updateInitialCartData(dispatch);
            }
        );
    };

    return {
        ...sourceMapDispatchToProps,
        init
    };
};

export default {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    },
    'Scandipwa/Component/Router/Container/mapDispatchToProps': {
        function: initGiftCardsCartDataOnRouter
    }
};
