/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const CATEGORY_SLIDE_CONTENT_TYPE = 'scandiweb_categoryslideritem';

export const CATEGORY_SLIDE_SKELETON = [{
    name: 'CategorySlide',
    type: 'div',
    children: [
        {
            name: 'Link',
            type: 'a'
        },
        {
            name: 'Image',
            type: 'img'
        },
        {
            name: 'Title',
            type: 'div'
        }
    ]
}];

export const PLACEHOLDER_SRC = '/media/wysiwyg/placeholder.png';
