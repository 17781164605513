/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

async function addInitScript(args, callback) {
    callback(...args);

    waitForPriorityLoad().then(
        async () => {
            const { hullaScript } = await import(
                /* webpackMode: "lazy", webpackChunkName: "hulla-event" */
                '../../event/hulla'
            );

            hullaScript();
        }
    );
}

export default {
    'Component/App/Component': {
        'member-function': {
            __construct: addInitScript
        }
    }
};
