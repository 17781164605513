/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import {
    Children,
    Fragment
} from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import ContentWrapper from 'Component/ContentWrapper';
import Draggable from 'Component/Draggable';
import {
    DEBOUNCE_RESETTING_ACTIVE_IMAGE_TIMEOUT_DELAY,
    SET_STYLE_VARIABLES_ON_MOUNT_DELAY
} from 'Component/Slider/Slider.config';
import { promoBannerImageHeight, promoBannerImageWidth } from 'Component/WidgetFactory/WidgetFactory.config';
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';
import { RefType } from 'Type/Common.type';
import { debounce } from 'Util/Request';

// import CSS from 'Util/CSS';
import './Slider.override.style';

/**
 * Some of the sliders can not be done by FE team
 * @namespace Scandipwa/Component/Slider/Component
 */
export class SliderComponent extends SourceSlider {
    static propTypes = {
        ...this.propTypes,
        isArrowSmall: PropTypes.bool.isRequired,
        shouldRenderArrowOnMobile: PropTypes.bool.isRequired,
        hasArrowBorder: PropTypes.bool.isRequired,
        hasArrowBackground: PropTypes.bool.isRequired,
        customTranslate: PropTypes.number.isRequired,
        areCrumbsLinear: PropTypes.bool.isRequired,
        hasContentWrapper: PropTypes.bool.isRequired,
        hasArrowContentWrapper: PropTypes.bool.isRequired,
        draggableTabIndex: PropTypes.string.isRequired,
        isInfinite: PropTypes.bool.isRequired,
        autoSlide: PropTypes.bool.isRequired,
        slideSpeed: PropTypes.number.isRequired,
        animationDuration: PropTypes.number.isRequired,
        isScrollable: PropTypes.bool.isRequired,
        isSingleSlide: PropTypes.bool.isRequired,
        slidesPerSlide: PropTypes.number.isRequired,
        draggableRef: RefType.isRequired,
        getSlideWidth: PropTypes.func.isRequired,
        handleCrumbClick: PropTypes.func.isRequired,
        changeActiveImage: PropTypes.func.isRequired,
        goPrev: PropTypes.func.isRequired,
        goNext: PropTypes.func.isRequired,
        handlePrevArrowClick: PropTypes.func.isRequired,
        handleNextArrowClick: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func.isRequired,
        onMouseLeave: PropTypes.func.isRequired,
        swapClonedSlides: PropTypes.func.isRequired,
        enableAutoPlay: PropTypes.func.isRequired,
        disableAutoPlay: PropTypes.func.isRequired,
        realActiveImage: PropTypes.number.isRequired,
        handleInteraction: PropTypes.func.isRequired,
        handleDragEnd: PropTypes.func.isRequired,
        handleDrag: PropTypes.func.isRequired,
        onClickChangeSlide: PropTypes.func.isRequired,
        getClonedChildren: PropTypes.func.isRequired,
        isHideArrowInSingleSlide: PropTypes.bool.isRequired,
        isUseDraggable: PropTypes.bool.isRequired
    };

    handleWindowResize = this.handleWindowResize.bind(this);

    handleDebounceResettingActiveImage = this.handleDebounceResettingActiveImage.bind(this);

    debounceResettingActiveImage = debounce(
        this.handleDebounceResettingActiveImage,
        DEBOUNCE_RESETTING_ACTIVE_IMAGE_TIMEOUT_DELAY
    );

    /**
     * Disabled ESLint to override this parent class method
     * because this parent class method also uses non-rendering method
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);

        const {
            draggableRef,
            getSlideWidth,
            getDir,
            setTranlateXStyle,
            setAnimationSpeedStyle,
            changeActiveImage,
            goPrev,
            goNext,
            handleDragEnd,
            handleInteraction,
            handleDragStart,
            handleDrag,
            calculateNextSlide,
            onClickChangeSlide,
            sliderRef
        } = this.props;

        this.draggableRef = draggableRef;
        this.sliderRef = sliderRef;
        this.getSlideWidth = getSlideWidth;
        this.getDir = getDir;
        this.setTranlateXStyle = setTranlateXStyle;
        this.setAnimationSpeedStyle = setAnimationSpeedStyle;
        this.changeActiveImage = changeActiveImage;
        this.goPrev = goPrev;
        this.goNext = goNext;
        this.handleDrag = handleInteraction.bind(this, handleDrag);
        this.handleDragEnd = handleInteraction.bind(this, handleDragEnd);
        this.handleDragStart = handleInteraction.bind(this, handleDragStart);
        this.calculateNextSlide = calculateNextSlide;
        this.onClickChangeSlide = onClickChangeSlide;
    }

    /**
     * Overridden to:
     * - Change the delay for this.setStyleVariablesOnMount() method
     * - Init autoplay
     * - Use draggableRef from prop.
     * Disabled ESLint error to use non-rendering logic
     * because this parent class method also uses non-rendering method
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const {
            isScrollable,
            enableAutoPlay
            // draggableRef
        } = this.props;

        if (!isScrollable) {
            this.addWindowResizeWatcher();

            if (!this.getIsSlider()) {
                return;
            }

            // const { animationDuration } = this.props;
            // const {
            //     current: {
            //         children: sliderChildren,
            //         offsetWidth: sliderWidth
            //     }
            // } = draggableRef;

            // this.sliderWidth = sliderWidth;

            // if (!sliderChildren || !sliderChildren[0]) {
            //     return;
            // }

            // const sliderRef = this.getSliderRef();

            // CSS.setVariable(sliderRef, 'sliderOpacity', '0');

            // // delay setting carousel translate to avoid wrong calculations be made during transition
            setTimeout(() => {
                this.setStyleVariablesOnMount();
            }, SET_STYLE_VARIABLES_ON_MOUNT_DELAY);

            // const target = sliderChildren[0].querySelector('img') || sliderChildren[0];

            // target.onload = () => {
            //     const height = target.offsetHeight;
            //     const sliderHeight = `${ height }px`;

            //     CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
            // };

            // setTimeout(() => {
            //     const height = target.offsetHeight;
            //     const sliderHeight = `${ height }px`;

            //     if (height !== 0) {
            //         CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
            //     }
            // }, animationDuration);

            // setTimeout(() => CSS.setVariable(sliderRef, 'sliderOpacity', '1'), 0);

            enableAutoPlay();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    /**
     * Overridden, moved to container...
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    static getDerivedStateFromProps(_props, _state) { }

    /**
     * Overridden to:
     * - Reset active slide when device.isMobile changes
     * - Call swapClonedSlides method to perform endless loop
     * - Toggle autoplay
     * Disabled ESLint error to use non-rendering logic
     * because this parent class method also uses non-rendering method
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const {
            device: { isMobile: prevIsMobile },
            autoSlide: prevAutoSlide,
            activeImage: prevActiveImage
        } = prevProps;

        const {
            device: { isMobile },
            activeImage,
            children,
            isInfinite,
            autoSlide,
            animationDuration,
            isScrollable,
            crumbsCount,
            swapClonedSlides,
            enableAutoPlay,
            disableAutoPlay,
            autoplayIsPaused
        } = this.props;

        if (!isScrollable) {
            if (activeImage !== prevActiveImage && this.getIsSlider()) {
                const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();

                this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * animationDuration));
                this.setTranlateXStyle(newTranslate);
            }

            if (prevIsMobile !== isMobile) {
                this.setAnimationSpeedStyle(0);
                this.setTranlateXStyle(0);
                this.changeActiveImage(0);
            }

            if ((
                (crumbsCount && activeImage >= crumbsCount)
                || activeImage >= children.length
                || activeImage < 0)
                && isInfinite) {
                swapClonedSlides();
            }

            if (!prevAutoSlide && autoSlide && !autoplayIsPaused) {
                enableAutoPlay();
            }

            if (prevAutoSlide && !autoSlide) {
                disableAutoPlay();
            }
        }
    }

    /**
     * Moved from parent class addWindowResizeWatcher method.
     * Overridden to add debounceResettingActiveImage usage
     */
    handleWindowResize() {
        const { activeImage } = this.props;
        const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();

        this.setTranlateXStyle(newTranslate);

        // Removed animation to avoid image movement while changing window width.
        this.setAnimationSpeedStyle(0);

        const delay = 500;

        setTimeout(() => {
            this.setAnimationSpeedStyle();
        }, delay);

        this.debounceResettingActiveImage();
    }

    /**
     * Overridden to move event listener handler to new method
     * to be able to remove the event listener on componentWillUnmount method
     */
    addWindowResizeWatcher() {
        window.addEventListener('resize', this.handleWindowResize, { passive: true });
    }

    handleDebounceResettingActiveImage() {
        this.changeActiveImage(0);
    }

    /**
     * Overridden to:
     * - Add tabIndex to <Draggable /> component
     * - Render clonedChildren to create an endless loop
     * - Use draggableRef from prop
     */
    renderSliderContent() {
        const {
            activeImage,
            children,
            isVertical,
            draggableTabIndex,
            draggableRef,
            isInfinite,
            isSingleSlide,
            getClonedChildren,
            isUseDraggable
        } = this.props;
        const dir = this.getDir();

        if (!this.getIsSlider()) {
            return children;
        }

        const clonedChildren = isInfinite && !isSingleSlide ? getClonedChildren() : children;

        return (
            <Draggable
              mix={ { block: 'Slider', elem: 'Wrapper', mods: { isVertical } } }
              draggableRef={ draggableRef }
              onDragStart={ isUseDraggable && this.handleDragStart }
              onDragEnd={ isUseDraggable && this.handleDragEnd }
              onDrag={ isUseDraggable && this.handleDrag }
              onClick={ this.handleClick }
              shiftX={ -activeImage * this.getSlideWidth() * dir }
              shiftY={ -activeImage * this.getSlideWidth() }
              tabIndex={ draggableTabIndex }
            >
                { clonedChildren }
            </Draggable>
        );
    }

    /**
     * Overridden to add mods to the arrows,
     * Change rendering condition,
     * Use suitable wrapper element based on hasArrowContentWrapper prop
     * Update click event handler method
     * Disabled ESLint error to use prop spreading and bind method
     */
    renderArrows() {
        const {
            showArrows,
            activeImage,
            children,
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            hasArrowContentWrapper,
            isInfinite,
            device: {
                isMobile
            },
            handlePrevArrowClick,
            handleNextArrowClick,
            isHideArrowInSingleSlide
        } = this.props;

        const nextIsDisabled = !isInfinite ? activeImage + 1 === children.length : false;
        const prevIsDisabled = !isInfinite ? activeImage === 0 : false;
        const mods = {
            isArrowSmall,
            hasArrowBorder,
            hasArrowBackground
        };

        if (
            !showArrows
            || (!shouldRenderArrowOnMobile && isMobile)
            || (nextIsDisabled && prevIsDisabled && isHideArrowInSingleSlide)
        ) {
            return null;
        }

        const Wrapper = hasArrowContentWrapper ? ContentWrapper : Fragment;

        const wrapperProps = hasArrowContentWrapper
            ? {
                isNotSection: true,
                wrapperMix: { block: 'Slider', elem: 'ArrowsContentWrapper' }
            }
            : {};

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <Wrapper { ...wrapperProps }>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled, ...mods } }
                  aria-label={ __('Previous') }
                  title={ __('Previous') }
                  onClick={ handlePrevArrowClick }
                >
                    <ChevronIcon direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled, ...mods } }
                  aria-label={ __('Next') }
                  title={ __('Next') }
                  onClick={ handleNextArrowClick }
                >
                    <ChevronIcon direction={ RIGHT } />
                </button>
            </Wrapper>
        );
    }

    /**
     * Overridden to change button's onClick attribute
     * Use realActiveImage instead of activeImage
     */
    renderCrumb(_, crumbIndex) {
        const {
            handleCrumbClick,
            realActiveImage
        } = this.props;
        const isActive = crumbIndex === realActiveImage;

        return (
            <button
              block="Slider"
              elem="Image"
              mods={ { type: 'single' } }
              /**
               * Disabled ESLint error to use arrow function for button's onClick
               */
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ (e) => handleCrumbClick(e, crumbIndex) }
              aria-label={ __('Slide crumb') }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            </button>
        );
    }

    /**
     * Overridden to add isLinear mod
     */
    renderCrumbs() {
        const {
            children,
            showCrumbs,
            areCrumbsLinear,
            crumbsCount
        } = this.props;

        if (!showCrumbs || children.length <= 1) {
            return null;
        }

        const renderChildren = crumbsCount ? new Array(crumbsCount).fill(0) : children;

        return (
            <div
              block="Slider"
              elem="Crumbs"
              mods={ { isLinear: areCrumbsLinear } }
            >
                { Children.map(renderChildren, this.renderCrumb) }
            </div>
        );
    }

    /**
     * New method to render Scrollable content
     */
    renderScrollableContent() {
        const { children, sliderRef } = this.props;

        return (
            <div
              ref={ sliderRef }
              block="Slider"
              elem="Scroll"
            >
               { children.map(((children) => children)) }
            </div>
        );
    }

    /**
     * Overridden to add content wrapper,
     * Change arrows position,
     * Add hover functionality (disable auto play on hover, and enable it later)
     */
    render() {
        const {
            mix,
            hasContentWrapper,
            isScrollable,
            onMouseEnter,
            onMouseLeave,
            device: {
                isMobile = false
            } = {}
        } = this.props;

        const { mix: mixObj = {} } = mix || {};
        const { block: blockIdentifier = '' } = mixObj || {};

        if (isScrollable) {
            return this.renderScrollableContent();
        }

        const Wrapper = hasContentWrapper ? ContentWrapper : Fragment;

        const wrapperProps = hasContentWrapper
            ? {
                isNotSection: true,
                wrapperMix: { block: 'Slider', elem: 'ContentWrapper' }
            }
            : {};

        // Need to set a height for homepage-promo-banner-slider to avoid shifting during page load, improves CLS
        if (blockIdentifier === 'homepage-promo-banner-slider' && isMobile) {
            const width = window.innerWidth;
            const height = Math.round((width * promoBannerImageHeight) / promoBannerImageWidth);

            return (
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                <Wrapper { ...wrapperProps }>
                    <div
                      block="Slider"
                      mix={ mix }
                      ref={ this.getSliderRef() }
                      onMouseEnter={ onMouseEnter }
                      onMouseLeave={ onMouseLeave }
                      style={ { height } }
                    >
                        { this.renderSliderContent() }
                        { this.renderCrumbs() }
                        { this.renderCounter() }
                    </div>
                    { this.renderArrows() }
                </Wrapper>
            );
        }

        return (
            /**
             * Disabled ESLint error to use prop spreading
             */
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <Wrapper { ...wrapperProps }>
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ this.getSliderRef() }
                  onMouseEnter={ onMouseEnter }
                  onMouseLeave={ onMouseLeave }
                >
                    { this.renderSliderContent() }
                    { this.renderCrumbs() }
                    { this.renderCounter() }
                </div>
                { this.renderArrows() }
            </Wrapper>
        );
    }
}

export default SliderComponent;
