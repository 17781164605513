import PropTypes from 'prop-types';

import {
    Draggable as SourceDraggable
} from 'SourceComponent/Draggable/Draggable.component';

/** @namespace Scandipwa/Component/Draggable/Component */
export class DraggableComponent extends SourceDraggable {
    static propTypes = {
        ...this.propTypes,
        tabIndex: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        tabIndex: '0'
    };

    /**
     * Overridden to call stopPropagation to prevent the event from bubbling to parent elements
     * Disabled ESLint error to use non-rendering logic in the component
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    handleTouchStart(e) {
        const { touches } = e;

        e.stopPropagation();

        window.addEventListener('touchmove', this.handleTouchMove, { passive: true });
        window.addEventListener('touchend', this.handleTouchEnd, { passive: true });

        if (touches.length === 1) {
            this.handleDragStart(touches[0]);
        }
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    /**
     * Overridden to get tabIndex from props
     */
    render() {
        const {
            children,
            handleFocus,
            handleKey,
            draggableRef,
            mix,
            tabIndex
        } = this.props;

        return (
            <div
              block="Draggable"
              mix={ mix }
              ref={ draggableRef }
              onMouseDown={ this.handleMouseDown }
              onTouchStart={ this.handleTouchStart }
              onClick={ this.handleClick }
              onContextMenu={ this.handleClick }
              onKeyDown={ handleKey }
              onFocus={ handleFocus }
              tabIndex={ tabIndex }
              role="button"
              aria-label="Draggable area"
            >
                { children }
            </div>
        );
    }
}

export default DraggableComponent;
