/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const AMASTY_DASHBOARD_ROUTE = '/amstorecredit';
export const AMASTY_DASHBOARD = 'dashboard';
export const STORE_CREDIT_REFUNDS = 'StoreCreditsHistory';
export const PAGE_SIZE = 10;
export const STORE_CREDIT_PAGE_SIZE_MOBILE = 5;
export const STORE_CREDIT_PAGE_SIZE_DESKTOP = 10;
export const AMASTY_DASHBOARD_POSITION = 100;
export const AMASTY_DASHBOARD_VALUES = {
    order_number: 'Order Number',
    date: 'Date',
    change: 'Change',
    newBalance: 'New Balance',
    action: 'Action'
};
export const AMASTY_DASHBOARD_ORDER_NO_LABEL = __('Order No.');
export const AMASTY_DASHBOARD_ORDER_LABEL = __('Order');
export const AMASTY_DASHBOARD_DATE_LABEL = __('Date');
export const AMASTY_DASHBOARD_CHANGE_LABEL = __('Change');
export const AMASTY_DASHBOARD_NEW_BALANCE_LABEL = __('New Balance');
export const AMASTY_DASHBOARD_ACTION_LABEL = __('Action');
export const AMASTY_DASHBOARD_ORDER_NO_KEY = 'orderNumber';
export const AMASTY_DASHBOARD_DATE_KEY = 'date';
export const AMASTY_DASHBOARD_CHANGE_KEY = 'change';
export const AMASTY_DASHBOARD_NEW_BALANCE_KEY = 'NewBalance';
export const AMASTY_DASHBOARD_ACTION_KEY = 'action';

// ! vvv this is semi-hard code, Can be taken from BE and it's better to do so.
// ! vvv ORDER MATTERS in this array.
export const AVAILABLE_PAGE_FILTERS = [
    '1month',
    '3month',
    '6month',
    '1year',
    'previous_year'
];
