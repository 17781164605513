Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/amasty-faq/src/plugin/AddFaqRoutesToUrlRewrites.plugin.js')]);
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { updateScrollY } from 'Store/Category/Category.action';
import CategoryReducer from 'Store/Category/Category.reducer';
import { withReducers } from 'Util/DynamicReducer';

/** @namespace Scandipwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    scrollY: state.CategoryReducer.scrollY
});

/** @namespace Scandipwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateScrollY: (scrollY) => dispatch(updateScrollY(scrollY))
});

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    static propTypes = {
        ...this.propTypes,
        updateScrollY: PropTypes.func.isRequired
    };

    /**
     * New method to make setScrollPosition to bind this
     */
    __construct(props) {
        super.__construct(props);

        // this.setScrollPosition = this.setScrollPosition.bind(this);
    }

    componentDidMount() {
        if (!window.isPrefetchValueUsed) {
            this.requestUrlRewrite();
        }

        this.initialUrl = location.pathname;
    }

    getFallbackType() {
        const {
            actionName: {
                type = ''
            } = {},
            isPrefetchValueUsed
        } = window;

        if (isPrefetchValueUsed) {
            return type;
        }

        return '';
    }

    getTypeSpecificProps() {
        const {
            urlRewrite: {
                id,
                sku
            }
        } = this.props;

        const isLoading = !window.isPrefetchValueUsed && this.getIsLoading();

        switch (this.getType()) {
        case TYPE_PRODUCT:
            /**
             * In case we are not yet sure what product ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const product = history?.state?.state?.product;
                if (product) {
                    const { sku: historySKU, id } = product;
                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        case TYPE_CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case TYPE_CATEGORY:
            /**
             * In case we are not yet sure what category ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const category = history?.state?.state?.category;
                if (category && category !== true) {
                    return { categoryIds: category };
                }

                return {};
            }

            return { categoryIds: id };
        case TYPE_NOTFOUND:
        default:
            return {};
        }
    }

    /**
     * Overridden to add listenHistory usage
     */
    // componentDidMount() {
    //     if (super.componentDidMount) {
    //         super.componentDidMount();
    //     }

    //     this.listenHistory();
    // }

    /**
     * Overridden to:
     * - Add scroll event listener with setScrollPosition method when isCategory is true
     * - Add setScrollPosition usage when isPrevCategory and isPDP is true
     */
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (super.componentDidUpdate) {
    //         super.componentDidUpdate(prevProps, prevState, snapshot);
    //     }

    //     const { urlRewrite: { type } } = this.props;
    //     const {
    //         urlRewrite: { type: prevType }
    //     } = prevProps;
    //     const isTypeChanged = type !== prevType;
    //     const isPrevCategory = prevType === TYPE_CATEGORY;
    //     const isPDP = type === TYPE_PRODUCT;

    //     if (isTypeChanged && isPrevCategory && isPDP) {
    //         const { history: { location: { state: { product } = {} } } } = this.props;

    //         const { id } = product || {};

    //         if (id) {
    //             this.setScrollPosition(id);
    //         }
    //     }
    // }

    /**
     * New method to remove scroll event listener with setScrollPosition method when type isn't TYPE_CATEGORY
     */
    // listenHistory() {
    //     const { history } = this.props;

    //     history.listen(() => {
    //         const type = this.getType();

    //         if (type !== TYPE_CATEGORY) {
    //             this.setScrollPosition();
    //         }
    //     });
    // }

    /**
     * New method to set scroll total, categoryID, and productID on Redux scrollY state
     */
    // setScrollPosition(productID) {
    //     const { updateScrollY, urlRewrite: { type } } = this.props;
    //     const { scrollY } = window;
    //     const isPDP = type === TYPE_PRODUCT;
    //     const isCategory = type === TYPE_CATEGORY;

    //     if (isPDP) {
    //         const {
    //             scrollY: ReduxScrollY,
    //             history: {
    //                 location: {
    //                     state: {
    //                         prevCategoryId = null
    //                     } = {}
    //                 }
    //             }
    //         } = this.props;
    //         const toPassScrollY = { ...ReduxScrollY, categoryID: prevCategoryId, productID };

    //         updateScrollY(toPassScrollY);
    //     } else if (isCategory) {
    //         const { location: { state: { category = null } = {} } } = this.props;
    //         const toPassScrollY = { total: scrollY, categoryID: category };

    //         updateScrollY(toPassScrollY);
    //     }
    // }
}

export default withReducers({
    CategoryReducer
})(connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer));
