/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, Suspense } from 'react';

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import
{
    CATEGORY_SLIDE_CONTENT_TYPE,
    CATEGORY_SLIDE_SKELETON
} from '../../component/CategorySlide/CategorySlide.config';

export const CategorySlide = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "cms-misc" */
        '../../component/CategorySlide'
    ),
);

const addReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: CATEGORY_SLIDE_CONTENT_TYPE },
        replace: (domNode) => (
            <Suspense fallback={ <div block="CmsPage" elem="CategorySliderPlaceholder" /> }>
                { createElement(CategorySlide, {
                    elements: instance.toReactElements(
                        [domNode],
                        CATEGORY_SLIDE_SKELETON
                    )
                }) }
            </Suspense>
        )
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
