/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import {
    createRef,
    Fragment,
    PureComponent
} from 'react';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';

import Slider from 'Component/Slider/Slider.component';
import {
    ACTIVE_SLIDE_PERCENT,
    ANIMATION_DURATION,
    PAUSE_INTERACTION_INTERVAL,
    PAUSE_INTERACTION_INTERVAL_CRUMBS_DELAY_MULTIPLIER,
    SLIDE_BEFORE_CHANGE_DELAY
} from 'Component/Slider/Slider.config';
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';
import {
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Slider/Slider.container';
import { ChildrenType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import CSS from 'Util/CSS';
import { isRtl } from 'Util/CSS/CSS';

import { ANIMATION_DURATION_FAST } from './Slider.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Slider/Container */
export class SliderContainer extends PureComponent {
    static propTypes = {
        ...SourceSlider.propTypes,
        isArrowSmall: PropTypes.bool,
        shouldRenderArrowOnMobile: PropTypes.bool,
        hasArrowBorder: PropTypes.bool,
        hasArrowBackground: PropTypes.bool,
        customTranslate: PropTypes.number,
        sliderWidth: PropTypes.number,
        areCrumbsLinear: PropTypes.bool,
        hasContentWrapper: PropTypes.bool,
        hasArrowContentWrapper: PropTypes.bool,
        draggableTabIndex: PropTypes.string,
        isInfinite: PropTypes.bool,
        autoSlide: PropTypes.bool,
        slideSpeed: PropTypes.number,
        animationDuration: PropTypes.number,
        isScrollable: PropTypes.bool,
        isSingleSlide: PropTypes.bool,
        width: PropTypes.number,
        crumbsCount: PropTypes.number,
        slidesPerSlide: PropTypes.number,
        children: ChildrenType.isRequired,
        device: DeviceType.isRequired,
        isHideArrowInSingleSlide: PropTypes.bool.isRequired,
        slides: PropTypes.arrayOf(
            PropTypes.shape({
                slide_link: PropTypes.string
            })
        ),
        isPreventDragOnClick: PropTypes.bool,
        isUseDraggable: PropTypes.bool,
        isDefaultTranslate: PropTypes.bool
    };

    static defaultProps = {
        ...SourceSlider.defaultProps,
        isArrowSmall: false,
        shouldRenderArrowOnMobile: true,
        hasArrowBorder: false,
        hasArrowBackground: false,
        customTranslate: 0,
        areCrumbsLinear: false,
        hasContentWrapper: false,
        hasArrowContentWrapper: false,
        draggableTabIndex: '0',
        isInfinite: true,
        // autoSlide is used to keep the old behavior if false
        // or to change the children dynamically so that we have an infinite loop if true
        autoSlide: false,
        slideSpeed: SLIDE_BEFORE_CHANGE_DELAY,
        animationDuration: ANIMATION_DURATION,
        isScrollable: false,
        isSingleSlide: false,
        width: null,
        crumbsCount: null,
        sliderWidth: null,
        slidesPerSlide: 1,
        isHideArrowInSingleSlide: false,
        slides: [],
        isPreventDragOnClick: false,
        isUseDraggable: true,
        isDefaultTranslate: false
    };

    draggableRef = createRef();

    sliderRef = createRef();

    handleDraggableResize = this.handleDraggableResize.bind(this);

    containerFunctions = {
        getSlideWidth: this.getSlideWidth.bind(this),
        getDir: this.getDir.bind(this),
        setTranlateXStyle: this.setTranlateXStyle.bind(this),
        setAnimationSpeedStyle: this.setAnimationSpeedStyle.bind(this),
        handleCrumbClick: this.handleCrumbClick.bind(this),
        changeActiveImage: this.changeActiveImage.bind(this),
        goPrev: this.goPrev.bind(this),
        goNext: this.goNext.bind(this),
        handlePrevArrowClick: this.handlePrevArrowClick.bind(this),
        handleNextArrowClick: this.handleNextArrowClick.bind(this),
        onMouseEnter: this.onMouseEnter.bind(this),
        onMouseLeave: this.onMouseLeave.bind(this),
        swapClonedSlides: this.swapClonedSlides.bind(this),
        enableAutoPlay: this.enableAutoPlay.bind(this),
        disableAutoPlay: this.disableAutoPlay.bind(this),
        handleDragEnd: this.handleDragEnd.bind(this),
        calculateNextSlide: this.calculateNextSlide.bind(this),
        onClickChangeSlide: this.onClickChangeSlide.bind(this),
        handleDrag: this.handleDrag.bind(this),
        handleInteraction: this.handleInteraction.bind(this),
        getClonedChildren: this.getClonedChildren.bind(this)
    };

    static getDerivedStateFromProps(props, state) {
        const { activeImage, children } = props;
        const { prevActiveImage } = state;

        if (prevActiveImage !== activeImage && children.length !== 1) {
            return { prevActiveImage: activeImage };
        }

        return null;
    }

    /**
     * New method to add addDraggableResizeEventHandler usage.
     */
    componentDidUpdate() {
        const { isDraggableElementPresent } = this.state;
        const { current: draggableElement } = this.draggableRef;

        if (draggableElement && !isDraggableElementPresent) {
            this.addDraggableResizeEventHandler();

            this.setState({ isDraggableElementPresent: true });
        }
    }

    /**
     * New method to clear autoplay interval
     */
    componentWillUnmount() {
        const { isScrollable } = this.props;
        const { resizeObserver } = this.state;
        const { current: draggableElement } = this.draggableRef;

        if (!isScrollable) {
            clearInterval(this.carouselInterval);
        }

        if (draggableElement && resizeObserver) {
            resizeObserver.unobserve(draggableElement);
        }
    }

    __construct(props) {
        super.__construct(props);

        const { activeImage } = props;

        this.state = {
            autoplayIsPaused: false,
            isDraggableElementPresent: false,
            resizeObserver: null,
            prevActiveImage: activeImage
        };
    }

    containerProps() {
        const {
            showCrumbs,
            showArrows,
            showCounter,
            activeImage,
            onActiveImageChange,
            mix,
            isInteractionDisabled,
            onClick,
            isVertical,
            isHeightTransitionDisabledOnMount,
            sliderHeight,
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            customTranslate,
            areCrumbsLinear,
            hasContentWrapper,
            hasArrowContentWrapper,
            draggableTabIndex,
            isInfinite,
            autoSlide,
            slideSpeed,
            animationDuration,
            isScrollable,
            isSingleSlide,
            width,
            crumbsCount,
            slidesPerSlide,
            children,
            device,
            isHideArrowInSingleSlide,
            isPreventDragOnClick,
            isUseDraggable
        } = this.props;

        return {
            showCrumbs,
            showArrows,
            showCounter,
            activeImage,
            onActiveImageChange,
            mix,
            isInteractionDisabled,
            onClick,
            isVertical,
            isHeightTransitionDisabledOnMount,
            sliderHeight,
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            customTranslate,
            areCrumbsLinear,
            hasContentWrapper,
            hasArrowContentWrapper,
            draggableTabIndex,
            isInfinite,
            autoSlide,
            slideSpeed,
            animationDuration,
            isScrollable,
            isSingleSlide,
            width,
            crumbsCount,
            slidesPerSlide,
            children,
            device,
            draggableRef: this.draggableRef,
            sliderRef: this.getSliderRef(),
            realActiveImage: this.getRealActiveImage(),
            isHideArrowInSingleSlide,
            isPreventDragOnClick,
            isUseDraggable
        };
    }

    /**
     * New method to add draggable element resize event handler
     */
    addDraggableResizeEventHandler() {
        const { current: draggableElement } = this.draggableRef;

        this.setState(
            {
                resizeObserver: new ResizeObserver(this.handleDraggableResize)
            },
            () => {
                const { resizeObserver } = this.state;

                resizeObserver.observe(draggableElement);
            }
        );
    }

    /**
     * New method to handle draggable element resize event to avoid image movement
     * while the slider width change
     */
    handleDraggableResize() {
        const { activeImage } = this.props;
        const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();

        this.setTranlateXStyle(newTranslate);

        // Removed animation to avoid image movement while changing window width.
        this.setAnimationSpeedStyle(0);

        const delay = 500;

        setTimeout(() => {
            this.setAnimationSpeedStyle();
        }, delay);
    }

    /**
     * New method to use custom translate and calculate slide width more precisely
     */
    getSlideWidth() {
        const {
            customTranslate,
            isVertical,
            width: propsWidth
        } = this.props;

        if (propsWidth) {
            return propsWidth;
        }

        if (customTranslate) {
            return customTranslate;
        }

        const { current: draggable } = this.draggableRef;

        if (!draggable) {
            return 0;
        }

        const { width, height } = draggable.getBoundingClientRect();

        return isVertical ? height : width;
    }

    /**
     * Pasted from scandipwa/node_modules/@scandipwa/scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     */
    getDir() {
        const { isVertical } = this.props;

        if (!isVertical && isRtl()) {
            return -1;
        }

        return 1;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to:
     * - Update the method based on isInfinite
     * - Update the horizontal scroll behavior based on direction
     * - Always keep translate at zero for single slides
     */
    setTranlateXStyle(translate) {
        const {
            isInfinite,
            isVertical,
            isSingleSlide,
            sliderWidth,
            isDefaultTranslate
        } = this.props;
        const slideWidth = isDefaultTranslate ? 0 : sliderWidth || this.getSlideWidth();
        const clonedPosition = isInfinite ? slideWidth : 0;
        const dir = this.getDir();

        if (isSingleSlide) {
            CSS.setVariable(
                this.draggableRef,
                isVertical ? 'translateY' : 'translateX',
                '0px'
            );
        } else {
            CSS.setVariable(
                this.draggableRef,
                isVertical ? 'translateY' : 'translateX',
                isVertical ? `${ translate - clonedPosition }px` : `${ translate - clonedPosition * dir }px`
            );
        }
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Disabled ESLint to override this parent class method
     * because this parent class method also uses non-rendering method
     */
    setAnimationSpeedStyle(animationDuration = ANIMATION_DURATION) {
        const { isDefaultTranslate } = this.props;

        const duration = isDefaultTranslate ? ANIMATION_DURATION_FAST : animationDuration;
        CSS.setVariable(this.draggableRef, 'animation-speed', `${ duration }ms`);
    }

    /**
     * Pasted from scandipwa/node_modules/@scandipwa/scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     */
    getFullSliderWidth() {
        const { isVertical } = this.props;
        const {
            current: {
                scrollWidth: fullSliderWidth,
                scrollHeight
            }
        } = this.draggableRef;
        const width = isVertical ? scrollHeight : fullSliderWidth;

        return width - this.getSlideWidth();
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to handle crumb click while preventing click event
     * from having effect on bottom layer elements (like links)
     */
    handleCrumbClick(e, crumbIndex) {
        e.stopPropagation();
        e.preventDefault();

        const { activeImage } = this.props;

        this.pauseAutoplay(
            PAUSE_INTERACTION_INTERVAL + (activeImage > crumbIndex
                ? activeImage - crumbIndex : crumbIndex - activeImage)
                * PAUSE_INTERACTION_INTERVAL_CRUMBS_DELAY_MULTIPLIER
        );
        this.changeActiveImage(crumbIndex);
    }

    /**
     * Pasted from scandipwa/node_modules/@scandipwa/scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     */
    changeActiveImage(activeImage) {
        const { onActiveImageChange } = this.props;

        onActiveImageChange(activeImage);
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to update the logic.
     * It returns the final active slide index,
     * Made last child the previous of the first child.
     * Always go to previous slide when activeImage === 0 if isInfinite is true
     */
    goPrev(pause = true) {
        const {
            activeImage,
            isInfinite,
            autoSlide
        } = this.props;

        if (autoSlide && pause) {
            this.pauseAutoplay();
        }

        if (this.disableInteractionOnClonedSlides()) {
            return activeImage;
        }

        if (activeImage > 0 || (activeImage === 0 && isInfinite)) {
            this.changeActiveImage(activeImage - 1);

            return activeImage - 1;
        }

        return activeImage;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to return the final active slide index
     */
    goNext(pause = true) {
        const {
            activeImage,
            children,
            isInfinite,
            autoSlide,
            crumbsCount
        } = this.props;

        if (autoSlide && pause) {
            this.pauseAutoplay();
        }

        if (this.disableInteractionOnClonedSlides()) {
            return activeImage;
        }

        const nextImage = activeImage + 1;

        if (
            (!crumbsCount && nextImage < children.length)
            || nextImage < crumbsCount
            || isInfinite) {
            this.changeActiveImage(nextImage);

            return nextImage;
        }

        return activeImage;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to handle next arrow button click
     */
    handleNextArrowClick(e) {
        e.stopPropagation();
        e.preventDefault();

        this.goNext();
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to handle prev arrow button click
     */
    handlePrevArrowClick(e) {
        e.stopPropagation();
        e.preventDefault();

        this.goPrev();
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to call disableAutoPlay when hovering
     */
    onMouseEnter() {
        this.disableAutoPlay();
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to call enableAutoPlay after the mouse exits the slider area
     */
    onMouseLeave() {
        const { autoplayIsPaused } = this.state;

        if (!autoplayIsPaused) {
            this.enableAutoPlay();
        }
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to swap cloned slides
     */
    swapClonedSlides() {
        const {
            activeImage,
            children,
            animationDuration,
            crumbsCount,
            isDefaultTranslate
        } = this.props;
        const length = crumbsCount ? crumbsCount - 1 : children.length - 1;
        const newActiveImage = activeImage < 0 ? length : 0;

        setTimeout(() => {
            this.setAnimationSpeedStyle(0);

            const newTranslate = -newActiveImage * this.getSlideWidth() * this.getDir();

            this.setTranlateXStyle(newTranslate);
            this.changeActiveImage(newActiveImage);
        }, isDefaultTranslate && (newActiveImage === crumbsCount - 1 || newActiveImage === 0)
            ? 0
            : animationDuration);
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to change slide when autoplay is on
     */
    getImageToShow() {
        const {
            activeImage,
            children,
            isInfinite,
            crumbsCount
        } = this.props;

        if (isInfinite) {
            const nextImage = activeImage + 1;

            return this.changeActiveImage(nextImage);
        }

        if (activeImage === 0) {
            return this.goNext(false);
        }

        if (activeImage === children.length - 1 || activeImage === crumbsCount - 1) {
            return this.changeActiveImage(0);
        }

        if (this.getDir() === 1) {
            return this.goNext(false);
        }

        return this.goPrev(false);
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to set autoplay
     * Change slideSpeed to the sum of the animationDuration and the speed, so that the delay of the change takes into account the animationDuration
     */
    enableAutoPlay() {
        const {
            autoSlide,
            slideSpeed,
            children,
            animationDuration
        } = this.props;

        if (!autoSlide || children.length <= 1) {
            return;
        }

        this.disableAutoPlay();

        this.carouselInterval = setInterval(() => {
            this.getImageToShow();
        }, slideSpeed + animationDuration);
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to disable autoplay
     */
    disableAutoPlay() {
        if (this.carouselInterval) {
            clearInterval(this.carouselInterval);
        }
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to pause autoplay
     */
    pauseAutoplay(interval = null) {
        this.disableAutoPlay();
        this.setState({ autoplayIsPaused: true });

        if (!this.autoPlayTimer) {
            this.autoPlayTimer = setTimeout(() => {
                this.enableAutoPlay();
                this.setState({ autoplayIsPaused: false });
                this.autoPlayTimer = null;
            }, interval || PAUSE_INTERACTION_INTERVAL);
        }
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to check if interaction is disabled or not on cloned slides
     */
    disableInteractionOnClonedSlides() {
        const {
            children,
            activeImage,
            isInfinite,
            crumbsCount
        } = this.props;

        if ((activeImage < 0
            || activeImage >= children.length
            || (crumbsCount && activeImage >= crumbsCount))
            && isInfinite) {
            return true;
        }

        return false;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * New method to get realActiveImage
     * realActiveImage doesn't accept 0 and gallery.length (which are used for endless loop)
     * It only shows index of the real active image
     */
    getRealActiveImage() {
        const {
            children,
            activeImage,
            crumbsCount
        } = this.props;

        if (activeImage < 0) {
            const length = crumbsCount ? crumbsCount - 1 : children.length - 1;

            return length;
        }

        if (activeImage >= children.length || (crumbsCount && activeImage >= crumbsCount)) {
            return 0;
        }

        return activeImage;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to pause autoplay
     */
    handleDragEnd(state, callback) {
        const {
            isVertical,
            isPreventDragOnClick
        } = this.props;

        const activeSlide = this.calculateNextSlide(state);
        const slideSize = this.getSlideWidth();
        const newTranslate = activeSlide * slideSize;

        this.setAnimationSpeedStyle();
        this.pauseAutoplay();

        if (!isPreventDragOnClick) {
            this.setTranlateXStyle(newTranslate);
        }

        // Remove class to trigger lick when drag ended
        Array.from(document.querySelectorAll('.homepage-promo-banner-slider-block')).forEach(
            (el) => el.classList.remove('DragstartPreventLink')
        );

        if (isVertical) {
            callback({
                originalY: newTranslate,
                lastTranslateY: newTranslate
            });

            return;
        }

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to update the method based on isInfinite,
     * Added dir in onActiveImageChange to fix slider behavior on arabic store view
     */
    calculateNextSlide(state) {
        const { isVertical, onActiveImageChange, isInfinite } = this.props;
        const {
            translateX,
            translateY,
            lastTranslateX,
            lastTranslateY
        } = state;

        const lastTranslate = isVertical ? lastTranslateY : lastTranslateX;
        const translate = isVertical ? translateY : translateX;

        const slideSize = this.getSlideWidth();

        const fullSliderSize = this.getFullSliderWidth();

        const dir = this.getDir();
        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = dir === 1 ? translate > lastTranslate : translate < lastTranslate;

        if (!translate) {
            return this.onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize);
        }

        if ((dir === 1 && translate >= 0) || (dir === -1 && translate < 0)) {
            if (isInfinite) {
                onActiveImageChange(-1);

                return 1;
            }

            onActiveImageChange(0);

            return 0;
        }

        if ((dir === 1 && translate < -fullSliderSize) || (dir === -1 && translate > fullSliderSize)) {
            const activeSlide = Math.round(fullSliderSize / (-slideSize * dir));

            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math[dir === 1 ? 'ceil' : 'floor'](activeSlidePosition);

            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math[dir === 1 ? 'floor' : 'ceil'](activeSlidePosition);

            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);

        onActiveImageChange(-activeSlide * dir);

        return activeSlide;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to:
     * - Update the method based on isInfinite
     * - Add dir to onClick condition to fix on arabic store view
     */
    onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize) {
        const { originalX } = state;
        const { prevActiveImage: prevActiveSlider } = this.state;
        const {
            device: { isMobile },
            onClick,
            isInfinite,
            isPreventDragOnClick,
            activeImage
        } = this.props;
        const { current: draggableElement } = this.draggableRef;
        const { x: elementPositionInDOM } = draggableElement.getBoundingClientRect();
        const dir = this.getDir();

        if (isPreventDragOnClick) {
            return activeImage;
        }

        if (onClick) {
            onClick();

            return -prevActiveSlider * dir;
        }

        const fullSliderPoss = Math.round(fullSliderSize / slideSize);

        const sliderPosition = -prevActiveSlider;
        const realElementPositionInDOM = (
            isInfinite ? elementPositionInDOM + slideSize : elementPositionInDOM
        ) - lastTranslate;
        const mousePositionInElement = originalX - realElementPositionInDOM;

        if (isMobile) {
            return sliderPosition;
        }

        if (slideSize / 2 < mousePositionInElement && -fullSliderPoss < sliderPosition) {
            const activeSlide = this.goNext();

            return -activeSlide;
        }

        if (slideSize / 2 > mousePositionInElement) {
            const activeSlide = this.goPrev();

            return -activeSlide;
        }

        return sliderPosition;
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to update the method based on isInfinite
     */
    handleDrag(state) {
        const { isVertical, isInfinite } = this.props;
        const { translateX, translateY } = state;
        const translate = isVertical ? translateY : translateX;
        const fullSliderSize = this.getFullSliderWidth();
        const dir = this.getDir();

        this.pauseAutoplay();
        this.setAnimationSpeedStyle(0);

        // Prevent link trigger on drag
        Array.from(document.querySelectorAll('.homepage-promo-banner-slider-block')).forEach(
            (el) => el.classList.add('DragstartPreventLink')
        );

        const canDrag = isInfinite
          || (dir === 1
              ? translate < 0 && translate > -fullSliderSize
              : translate > 0 && translate < fullSliderSize);

        if (canDrag) {
            this.setTranlateXStyle(translate);
        }
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     * Overridden to update the condition statement
     */
    handleInteraction(callback, ...args) {
        const { isInteractionDisabled } = this.props;

        if (isInteractionDisabled || !callback || this.disableInteractionOnClonedSlides()) {
            return;
        }

        callback.call(this, ...args);
    }

    /**
     * Pasted from scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container.
     */
    getClonedChildren() {
        const {
            children,
            slidesPerSlide,
            isDefaultTranslate
        } = this.props;

        const before = [];
        const after = [];

        /* eslint-disable fp/no-let */
        let emptyIndex = 0;

        for (let i = 0, j = children.length - 1; i < slidesPerSlide; i++, j--) {
            if (j === children.length - 1 && isDefaultTranslate) {
                after.push(<Fragment key={ `${0}-after` }>{ children[0] }</Fragment>);
            }

            if (children[j]) {
                before.push(<Fragment key={ `${j}-before` }>{ children[j] }</Fragment>);
            }

            if (children[i]) {
                after.push(<Fragment key={ `${i}-after` }>{ children[i] }</Fragment>);
            }
        }
        /* eslint-enable fp/no-let */

        const emptySlidesCount = children.length % slidesPerSlide === 0
            ? 0 : slidesPerSlide - (children.length % slidesPerSlide);

        return [
            ...before.slice(0, slidesPerSlide - emptySlidesCount),
            ...new Array(emptySlidesCount)
                .fill(0)
                .map(() => <div key={ `empty-child-${emptyIndex++}` } />),
            <Fragment key="default-before">{ children }</Fragment>,
            // vvv in case slides don't fill entire row, fill it with the empty ones.
            ...new Array(emptySlidesCount)
                .fill(0)
                .map(() => <div key={ `empty-child-${emptyIndex++}` } />),
            ...after
        ].filter(Boolean);
    }

    /**
     * Pasted from scandipwa/node_modules/@scandipwa/scandipwa/src/component/Slider/Slider.component.js
     * to move non-rendering logic from component to container and to be used on this class
     */
    getSliderRef() {
        const { sliderRef } = this.props;

        return sliderRef || this.sliderRef;
    }

    onVisibilityChange = (inView) => {
        const { autoSlide } = this.props;

        if (!autoSlide) {
            return;
        }

        if (!inView) {
            this.pauseAutoplay();
        } else {
            this.enableAutoPlay();
        }
    };

    render() {
        const { isPdp } = this.props;

        if (isPdp) {
            return (
                <Slider
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
            );
        }

        return (
            <InView onChange={ this.onVisibilityChange }>
                <Slider
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
            </InView>
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(SliderContainer);
