import {
    CategoryReducer as sourceCategoryReducer,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Category/Category.reducer';

import { UPDATE_CURRENT_CATEGORY, UPDATE_SCROLL_Y } from './Category.action';

/**
 * New state scrollY to be used to set window scrollY after going out from PLP to PDP and going back to PLP.
 * The scrollY state contains below properties.
 * - total: the scrollY total
 * - categoryID: the category ID of PLP that user has just visited recently
 * - productID: the product ID of PDP that user has just visited recently
 */
/** @namespace Scandipwa/Store/Category/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    scrollY: {
        total: 0,
        categoryID: 0,
        productID: 0
    }
});

/**
 * New reducer case to set Redux scrollY state
 */
/** @namespace Scandipwa/Store/Category/Reducer/CategoryReducer */
export const CategoryReducer = (state = getInitialState(), action) => {
    const { type, scrollY } = action;

    switch (type) {
    case UPDATE_CURRENT_CATEGORY:
        return sourceCategoryReducer(state, action);

    case UPDATE_SCROLL_Y:
        return {
            ...state,
            scrollY
        };

    default:
        return state;
    }
};

export default CategoryReducer;
