export * from 'SourceStore/ProductListInfo/ProductListInfo.action';

export const SET_LAST_APPLIED_FILTER = 'SET_LAST_APPLIED_FILTER';
export const UPDATE_PRODUCT_LIST_INFO_FROM_CLERK = 'UPDATE_PRODUCT_LIST_INFO_FROM_CLERK';

/** @namespace Scandipwa/Store/ProductListInfo/Action/setLastAppliedFilter */
export const setLastAppliedFilter = (lastAppliedFilter) => ({
    type: SET_LAST_APPLIED_FILTER,
    lastAppliedFilter
});

/** @namespace Scandipwa/Store/ProductListInfo/Action/updateProductListInfoFromClerk */
export const updateProductListInfoFromClerk = (options, filters) => ({
    type: UPDATE_PRODUCT_LIST_INFO_FROM_CLERK,
    options,
    filters
});
