import PropTypes from 'prop-types';
import { Suspense } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import SliderPerSlide from 'Component/SliderPerSlide';
import {
    AfterPriority,
    lowPriorityLazy,
    setLoadedFlag
} from 'Util/Request/LowPriorityLoad';
import { getCloudflareOptimizationImage } from 'Util/Resize';
import {
    appendWithStoreCode,
    getIsAffordable,
    getIsHomePage,
    isHomePageUrl
} from 'Util/Url';

import SourceSliderWidget from './SliderWidget.component.source';
import { DO_NOT_DRAG_LANDING_MENU } from './SliderWidget.config';
import { SLIDE_TITLE_ANIMATION_DURATION_MAP } from './SliderWidget.override.config';

export const Slider = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "slider" */
        'Component/Slider'
    )
);

/** @namespace Scandipwa/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidget {
    static propTypes = {
        ...this.propTypes,
        slider: PropTypes.shape({
            title: PropTypes.string,
            slideSpeed: PropTypes.number,
            slides: PropTypes.arrayOf(
                PropTypes.shape({
                    desktop_image: PropTypes.string,
                    mobile_image: PropTypes.string,
                    slide_text: PropTypes.string,
                    isPlaceholder: PropTypes.bool
                })
            ),
            show_navigation: PropTypes.bool.isRequired,
            is_active: PropTypes.bool.isRequired,
            show_menu: PropTypes.bool.isRequired
        }),
        isArrowSmall: PropTypes.bool,
        shouldRenderArrowOnMobile: PropTypes.bool,
        hasArrowBorder: PropTypes.bool,
        hasArrowBackground: PropTypes.bool,
        hasArrowContentWrapper: PropTypes.bool,
        slidesPerSlide: PropTypes.number.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isArrowSmall: false,
        shouldRenderArrowOnMobile: true,
        hasArrowBorder: false,
        hasArrowBackground: false,
        hasArrowContentWrapper: false
    };

    state = {
        slideLoaded: false
    };

    renderSlidesContent(slide, i) {
        const {
            slide_text,
            isPlaceholder,
            title: block
        } = slide;
        const { screen: { width } } = window;
        const { slidesPerSlide } = this.props;

        const imageLoaded = () => {
            if (slidesPerSlide > 1 && i === slidesPerSlide - 1) {
                this.setState({ slideLoaded: true });

                return;
            }

            if (i === 0) {
                this.setState({ slideLoaded: true });
            }
        };

        return (
            <>
                <Image
                  mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                  ratio="custom"
                  src={ getCloudflareOptimizationImage(this.getSlideImage(slide), width) }
                  isPlaceholder={ isPlaceholder }
                  // eslint-disable-next-line react/jsx-no-bind
                  onImageLoad={ imageLoaded }
                  // eslint-disable-next-line react/jsx-no-bind
                  onError={ imageLoaded }
                  isLazyLoaded={ i !== 0 }
                />
                <figcaption
                  block="SliderWidget"
                  elem="Figcaption"
                  mix={ { block } }
                >
                    <Html content={ slide_text || '' } />
                </figcaption>
            </>
        );
    }

    renderSlide(slide, i) {
        const {
            slide_link,
            mobile_image,
            slide_text
        } = slide;

        if (!slide_text && mobile_image?.indexOf('null') !== -1) {
            return null;
        }

        if (slide_link) {
            return (
                <Link
                  block="SliderWidget"
                  elem="Figure"
                  key={ i }
                  to={ slide_link }
                >
                    { this.renderSlidesContent(slide, i) }
                </Link>
            );
        }

        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ i }
            >
                { this.renderSlidesContent(slide, i) }
            </figure>
        );
    }

    /**
     * Overridden to remove automatic slide change which is already present in the "Slider" component
     */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    componentDidUpdate() {
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    /**
     * Overridden to add extra props to <Slider /> component,
     * Update rendering condition based on slider.is_active from props
     * Pass the slideSpeed prop from the slider object so we can give each slider its own sliding speed
     */
    render() {
        const { activeImage, slideLoaded } = this.state;
        const {
            slider: {
                slides,
                title: block,
                show_navigation,
                is_active,
                show_menu,
                slideSpeed,
                slider_id
            },
            isArrowSmall,
            shouldRenderArrowOnMobile,
            hasArrowBorder,
            hasArrowBackground,
            hasArrowContentWrapper,
            showMenu,
            fallback,
            slidesPerSlide
        } = this.props;
        const {
            actionName: {
                slider: {
                    slider_id: preloadedSliderId,
                    slides: [slide] = []
                } = {}
            } = {},
            screen: { width }
        } = window;

        const blockName = block?.split(' ')[0];
        const isUseDraggable = !DO_NOT_DRAG_LANDING_MENU.includes(blockName);
        const isAffordable = getIsAffordable() || getIsHomePage();

        if (!is_active) {
            if (fallback) {
                return fallback();
            }

            return null;
        }

        if (slidesPerSlide > 1) {
            return (
                <AfterPriority fallback={ fallback() }>
                    <Suspense fallback={ fallback() }>
                        <SliderPerSlide
                          isPreventDragOnClick
                          mix={ { block: 'SliderWidget', mix: { block }, mods: { isAffordable } } }
                          showCrumbs={ showMenu || show_menu }
                          showArrows={ slides.length > slidesPerSlide && show_navigation }
                          activeImage={ activeImage }
                          onActiveImageChange={ this.onActiveImageChange }
                          isArrowSmall={ isArrowSmall }
                          shouldRenderArrowOnMobile={ shouldRenderArrowOnMobile }
                          hasArrowBorder
                          hasArrowBackground={ hasArrowBackground }
                          hasArrowContentWrapper={ hasArrowContentWrapper }
                          slideSpeed={ slideSpeed }
                          animationDuration={ SLIDE_TITLE_ANIMATION_DURATION_MAP[block] }
                          autoSlide
                          slides={ slides }
                          isUseDraggable={ isUseDraggable }
                          slidesPerSlide={ slidesPerSlide }
                          slideItemWidth={ { desktop: 411, mobile: 232 } }
                          sliderWidth={ 1280 }
                          isInfinite
                        >
                            { slides.map(this.renderSlide.bind(this)) }
                        </SliderPerSlide>
                    </Suspense>
                </AfterPriority>
            );
        }

        const { pathname = appendWithStoreCode('/') } = location;

        if (!isHomePageUrl(pathname)) {
            setLoadedFlag();
        }

        return (
            <>
                { slider_id === preloadedSliderId && (
                    <div
                      block="SliderWidget"
                      elem="SliderImage"
                    >
                        <Image
                          mix={ {
                              block: 'SliderWidget',
                              elem: 'FigureImage',
                              mods: {
                                  isPriority: true,
                                  isHidden: slideLoaded
                              }
                          } }
                          ratio="custom"
                          src={ getCloudflareOptimizationImage(this.getSlideImage(slide), width) }
                          onImageLoad={ setLoadedFlag }
                          isLazyLoaded={ false }
                        />
                    </div>
                ) }
                <AfterPriority fallback={ fallback() }>
                    <Suspense fallback={ fallback() }>
                        <Slider
                          isPreventDragOnClick
                          mix={ { block: 'SliderWidget', mix: { block }, mods: { isAffordable } } }
                          showCrumbs={ showMenu || show_menu }
                          showArrows={ show_navigation }
                          activeImage={ activeImage }
                          onActiveImageChange={ this.onActiveImageChange }
                          isArrowSmall={ isArrowSmall }
                          shouldRenderArrowOnMobile={ shouldRenderArrowOnMobile }
                          hasArrowBorder={ hasArrowBorder }
                          hasArrowBackground={ hasArrowBackground }
                          hasArrowContentWrapper={ hasArrowContentWrapper }
                          slideSpeed={ slideSpeed }
                          animationDuration={ SLIDE_TITLE_ANIMATION_DURATION_MAP[block] }
                          autoSlide
                          slides={ slides }
                          isUseDraggable={ isUseDraggable }
                          slidesPerSlide={ slidesPerSlide }
                        >
                            { slides.map(this.renderSlide.bind(this)) }
                        </Slider>
                    </Suspense>
                </AfterPriority>
            </>
        );
    }
}

export default SliderWidgetComponent;
