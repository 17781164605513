/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import { getProductClickData, getProductImpressionsData } from '../data/list';
import { debounceCallback } from '../util/debounce';

export const IMPRESSION_PUSH_TIMER = 2000;
export const MAX_IMPRESSION_BATCH = 24;
export const MIN_IMPRESSION_BATCH = 1;
export const DEFAULT_IMPRESSION_BATCH = 6;

/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
const products = [];
const positions = [];
// eslint-disable-next-line fp/no-let
let pushTimer;
/* eslint-enable @scandipwa/scandipwa-guidelines/export-level-one */

/** @namespace Scandiweb/Gtm/Event/List/fireImpressionEvent */
export const fireImpressionEvent = async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category,
    isSlide
) => {
    const { gtm_impressions_batch_limit } = getStore().getState().ConfigReducer.gtm.events || {};
    const impressionBatchLimit = (gtm_impressions_batch_limit >= MIN_IMPRESSION_BATCH
    && gtm_impressions_batch_limit <= MAX_IMPRESSION_BATCH)
        ? gtm_impressions_batch_limit : DEFAULT_IMPRESSION_BATCH;

    products.push(product);
    positions.push(position);

    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/List/fireImpressionEvent/waitForPriorityLoad/then */
        async () => {
            const { getCustomerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "customer-util" */
                '../data/customer'
            );
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_IMPRESSIONS } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            const { customerId } = await getCustomerData();

            clearTimeout(pushTimer);

            pushTimer = setTimeout(async () => {
                if (products.length === 0) {
                    return;
                }

                pushToDataLayer({
                    customerId,
                    event: GTM_EVENT_KEY_IMPRESSIONS,
                    ...await getProductImpressionsData(
                        products,
                        positions,
                        forcedList,
                        urlPathname,
                        urlRewriteData,
                        category,
                        isSlide
                    )
                });

                products.splice(0);
                positions.splice(0);
            }, IMPRESSION_PUSH_TIMER);

            if (products.length >= impressionBatchLimit) {
                clearTimeout(pushTimer);

                const productsToPush = products.splice(0, impressionBatchLimit);
                const positionsToPush = positions.splice(0, impressionBatchLimit);

                pushToDataLayer({
                    customerId,
                    event: GTM_EVENT_KEY_IMPRESSIONS,
                    ...await getProductImpressionsData(
                        productsToPush,
                        positionsToPush,
                        forcedList,
                        urlPathname,
                        urlRewriteData,
                        category,
                        isSlide
                    )
                });
            }
            // ^^^ not using debounce, as many impressions can come together
        }
    );
};

/** @namespace Scandiweb/Gtm/Event/List/fireProductClickEvent */
export const fireProductClickEvent = debounceCallback(async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category,
    isSlide
) => {
    waitForPriorityLoad().then(
        /** @namespace Scandiweb/Gtm/Event/List/fireProductClickEvent/debounceCallback/waitForPriorityLoad/then */
        async () => {
            const { getCustomerData } = await import(
                /* webpackMode: "lazy", webpackChunkName: "customer-util" */
                '../data/customer'
            );
            const { pushToDataLayer } = await import(
                /* webpackMode: "lazy", webpackChunkName: "push-util" */
                '../util/push'
            );
            const { GTM_EVENT_KEY_PRODUCT_CLICK } = await import(
                /* webpackMode: "lazy", webpackChunkName: "events-util" */
                '../util/events'
            );

            const { customerId } = await getCustomerData();

            pushToDataLayer({
                event: GTM_EVENT_KEY_PRODUCT_CLICK,
                customerId,
                ...await getProductClickData(
                    product,
                    position,
                    forcedList,
                    urlPathname,
                    urlRewriteData,
                    category,
                    isSlide
                )
            });
        }
    );
});
