import MenuHelper from 'Util/Menu';

import { UPDATE_MENU_ITEMS } from './LandingMenu.action';

/** @namespace Scandipwa/Store/LandingMenu/Reducer/updateMenuItems */
export const updateMenuItems = (action, state) => {
    const { menu } = action;
    const reducedMenu = MenuHelper.reduce(menu);

    return { ...state, menu: reducedMenu };
};

/** @namespace Scandipwa/Store/LandingMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    menu: {}
});

/** @namespace Scandipwa/Store/LandingMenu/Reducer/LandingMenuReducer */
export const LandingMenuReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_MENU_ITEMS:
        return updateMenuItems(action, state);
    default:
        return state;
    }
};

export default LandingMenuReducer;
